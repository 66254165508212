import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import {
	ChartComponent,
	ApexAxisChartSeries,
	ApexChart,
	ApexTitleSubtitle,
	ApexXAxis,
	ApexNonAxisChartSeries,
	ApexPlotOptions,
	ApexAnnotations,
	ApexStroke,
	ApexFill,
	ApexTooltip,
	ApexResponsive,
	ApexYAxis,
	ApexGrid,
	ApexStates,
	ApexTheme,
	ApexDataLabels,
	ApexLegend,
} from 'ng-apexcharts';

export type ChartOptions = {
	series: ApexAxisChartSeries | ApexNonAxisChartSeries;
	chart: ApexChart;
	xaxis: ApexXAxis;
	yaxis: ApexYAxis | ApexYAxis[];
	title: ApexTitleSubtitle;
	colors: string[];
	dataLabels: ApexDataLabels;
	plotOptions: ApexPlotOptions;
	annotations: ApexAnnotations;
	stroke: ApexStroke;
	labels: string[];
	fill: ApexFill;
	tooltip: ApexTooltip;
	responsive: ApexResponsive[];
	grid: ApexGrid;
	states: ApexStates;
	subtitle: ApexTitleSubtitle;
	theme: ApexTheme;
	legend: ApexLegend;
};

@Component({
	selector: 'app-trip-charts',
	templateUrl: './apx-trip-charts.component.html',
	styleUrls: ['./apx-trip-charts.component.scss'],
})

// 📌221223 리뉴얼 운전자 리포트 - 총 주행시간
export class ApxTripTimeChartComponent implements OnInit, AfterViewInit {
	@ViewChild('charts') charts: ChartComponent;
	public chartOptions: Partial<ChartOptions>;

	@Input() chart: ApexChart;
	@Input() annotations: ApexAnnotations;
	@Input() colors: string[];
	@Input() dataLabels: ApexDataLabels;
	@Input() series: ApexAxisChartSeries | ApexNonAxisChartSeries;
	@Input() stroke: ApexStroke;
	@Input() labels: string[];
	@Input() legend: ApexLegend;
	@Input() fill: ApexFill;
	@Input() tooltip: ApexTooltip;
	@Input() plotOptions: ApexPlotOptions;
	@Input() responsive: ApexResponsive[];
	@Input() xaxis: ApexXAxis;
	@Input() yaxis: ApexYAxis | ApexYAxis[];
	@Input() grid: ApexGrid;
	@Input() states: ApexStates;
	@Input() title: ApexTitleSubtitle;
	@Input() subtitle: ApexTitleSubtitle;
	@Input() theme: ApexTheme;

	constructor() {
		this.chartOptions = {
			series: [
				{
					data: [4726, 4810],
				},
			],

			chart: {
				height: 80,
				type: 'bar',
				offsetY: 0,
				offsetX: 0,
				// foreColor: '#373d3f',
				toolbar: {
					show: false,
				},
			},
			annotations: {},
			plotOptions: {
				bar: {
					horizontal: true,
					borderRadius: 0,
					barHeight: '100%',
					distributed: true,
					dataLabels: {
						position: 'bottom',
					},
				},
			},
			legend: {
				show: false,
				position: 'right',
				floating: false,
			},
			colors: ['#01b1ee', '#bfbfbf'],

			dataLabels: {
				enabled: true,
				textAnchor: 'start',
				// offsetX: 370,
				offsetX: 16,
				style: {
					fontSize: '12px',
					fontWeight: '400',
					// colors: ['#01b1ee', '#bfbfbf'],
					colors: ['#fff'],
				},
				// background: {
				// 	enabled: false,
				// },
				formatter: function (val: any, opt: any) {
					const format = new Intl.NumberFormat().format(val);
					return `${format} min`;
				},
			},
			grid: {
				position: 'back',
				borderColor: 'red',
				strokeDashArray: 0,
				show: false,
				// row: {
				// 	colors: ['red'],
				// 	opacity: 1,
				// },
				// column: {
				// 	colors: ['blue'],
				// 	opacity: 1,
				// },
				padding: {
					top: 0,
					right: 0,
					bottom: 0,
					left: 0,
				},

				xaxis: {
					lines: {
						show: false,
						offsetX: 0,
						offsetY: 0,
					},
				},
				yaxis: {
					lines: {
						show: false,
					},
				},
			},
			xaxis: {
				categories: ['총 주행시간'],
				labels: {
					show: false,
					style: {
						colors: ['#0000ff', '#bfbfbf'],
					},
				},
				// axisBorder: {
				// 	show: true,
				// 	color: '#0000ff',
				// 	strokeWidth: 10,
				// 	// height: 1,

				// 	offsetX: 0,
				// 	offsetY: 0,
				// },
				// axisTicks: {
				// 	show: true,
				// 	borderType: 'solid',
				// 	color: '#0000ff',
				// 	offsetX: 0,
				// 	offsetY: 0,
				// 	height: 6,
				// },
			},
			yaxis: {
				show: true,
				tooltip: {
					enabled: true,
				},
				labels: {
					show: true,
					style: {
						colors: [],
						fontSize: '12px',
						fontWeight: 'bold',
					},
				},
			},
		};
	}

	ngOnInit() {}

	ngAfterViewInit() {}
}
