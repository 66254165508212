import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';

import * as Chart from 'chart.js';

@Component({
	selector: 'app-risk-comparison-charts',
	templateUrl: './risk-comparison-charts.component.html',
	styleUrls: ['./risk-comparison-charts.component.scss'],
})

// 📌230102 위험요소 항목별 비교
export class RiskComparisonChartComponent implements OnInit, AfterViewInit {
	public chart: any;

	constructor() {}

	ngOnInit() {
		this.riskComparisonBarChart();
	}

	riskComparisonBarChart() {
		const chart = new Chart('riskComparisonBar', {
			type: 'bar',
			data: {
				// labels: [
				// 	'차선변경',
				// 	'무단차선이탈',
				// 	'차로치우침',
				// 	'전방충돌위험',
				// 	'위험이벤트',
				// 	`전방충돌
				// 	행동개선`,
				// 	'제한속도위반',
				// 	'제한속도위반범위',
				// 	'급속속도변화',
				// ],
				labels: [
					'차선변경',
					'무단차선이탈',
					'차로치우침',
					'전방충돌위험',
					'위험이벤트',
					['전방충돌', '행동개선'],
					'제한속도위반',
					['제한속도', '위반범위'],
					'급속속도변화',
				],
				datasets: [
					{
						// label: '',
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(231,249,255)',
						stack: 'bef',
						data: [5, 5, 5, 5, 5, 5, 5, 5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(209,243,255)',
						stack: 'bef',
						data: [5, 5, 5, 5, 5, 5, 5, 5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(160,229,254)',
						stack: 'bef',
						data: [5, 5, 5, 5, 5, 5, 5, 5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(88,211,254)',
						stack: 'bef',
						data: [5, 5, 5, 5, 5, 5, 5, 5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(22,193,254)',
						stack: 'bef',
						data: [5, 5, 5, 5, 5, 5, 5, 5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(1,177,238)',
						stack: 'bef',
						data: [5, 5, 5, 5, 5, 5, 5, 5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(1,155,209)',
						stack: 'bef',
						data: [5, 5, 5, 5, 5, 5, 5, 5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
					},
					// {
					// 	// 박스 크기 조절용 (데이터 적용x)
					// 	yAxisID: 'bar-stack',
					// 	backgroundColor: 'rgba(255,255,255, 0)',
					// 	stack: 'bef',
					// 	data: [10, 10, 10, 10, 10, 10, 10, 10, 10],
					// 	borderWidth: 2,
					// 	borderColor: 'rgba(255,255,255, 0)',
					// },

					// ---------
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(217,217,217)',
						stack: 'now',
						data: [5, 5, 5, 5, 5, 5, 5, 5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(217,217,217)',
						stack: 'now',
						data: [5, 5, 5, 5, 5, 5, 5, 5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(217,217,217)',
						stack: 'now',
						data: [5, 5, 5, 5, 5, 5, 5, 5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(217,217,217)',
						stack: 'now',
						data: [5, 5, 5, 5, 5, 5, 5, 5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(217,217,217)',
						stack: 'now',
						data: [5, 5, 5, 5, 5, 5, 5, 5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(217,217,217)',
						stack: 'now',
						data: [5, 5, 5, 5, 5, 5, 5, 5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(217,217,217)',
						stack: 'now',
						data: [5, 5, 5, 5, 5, 5, 5, 5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
					},
				],
			},

			options: {
				responsive: false,
				animation: {
					duration: 0,
				},

				layout: {
					padding: 20,
				},

				plugins: {
					labels: {
						render: '',
						fontColor: ['#fff'],
						fontSize: 0,
					},
				},

				tooltips: {
					enabled: false,
				},

				legend: {
					display: false,
				},
				scales: {
					yAxes: [
						{
							id: 'bar-stack',
							// position: 'left',
							stacked: true,
							ticks: {
								beginAtZero: true,
								display: false,
								// mirror: true,
								// padding: 40,
								// callback: function (value: any, index: number, values: any) {
								// 	console.log(typeof value);
								// 	return value;
								// },
							},
							gridLines: {
								display: false,
								drawBorder: false,
							},
						},
					],
					xAxes: [
						{
							ticks: {
								beginAtZero: true,
								padding: 20,
								fontColor: '#000',
								fontSize: 16,
							},
							gridLines: {
								display: true,
								drawOnChartArea: false,
								// zeroLineColor: '#000',
								// zeroLineWidth: 4,
								tickMarkLength: 0,
								lineWidth: 3,
								color: '#d9d9d9',
							},
						},
					],
				},
			},
		});
	}

	ngAfterViewInit() {}
}
