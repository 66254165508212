import {
	AfterViewInit,
	Component,
	Input,
	OnDestroy,
	OnInit,
	ViewChild,
	ChangeDetectorRef,
} from '@angular/core';

import * as Chart from 'chart.js';
import chartDataLabels from 'chartjs-plugin-datalabels';

@Component({
	selector: 'app-idle-round-line-charts',
	templateUrl: './idle-round-line-charts.component.html',
	styleUrls: ['./idle-round-line-charts.component.scss'],
})

// 📌230224 기점/종점 회차지 공회전
export class IdleRoundLineChartComponent implements OnInit {
	public chart: any;

	constructor() {}

	ngOnInit() {
		this.createChart();
	}

	createChart() {
		const date = new Date();
		const year = date.getFullYear();
		const month = date.getMonth() + 1;
		const day = new Date(year, month, 0).getDate();

		const days = Array.from({ length: day }, (_, key: number) => {
			return `${key + 1}`;
		});

		const chart = new Chart('idleRoundLineChart', {
			plugins: [chartDataLabels],
			data: {
				labels: days,
				datasets: [
					{
						type: 'line',
						data: [
							20, 70, 40, 0, 50, 10, 40, 30, 60, 90, 70, 40, 50, 80, 90, 40, 70, 90, 30, 50, 60, 20,
							40, 10, 50, 30, 80, 50, 60, 20, 50,
						],
						borderColor: 'rgba(0, 0, 0, 0)',
						backgroundColor: 'rgb(191,191,191)',
						borderWidth: 0,

						lineTension: 0,
						pointBorderWidth: 0,
						pointRadius: 0,
						fill: true,
						order: 2,
						datalabels: {
							display: false,
						},
					},
					{
						type: 'line',
						data: [
							50, 20, 70, 20, 60, 30, 60, 10, 80, 70, 50, 30, 20, 50, 70, 40, 80, 50, 10, 70, 90,
							40, 60, 30, 70, 20, 50, 80, 30, 50, 20,
						],
						borderColor: 'rgba(0, 0, 0, 0)',
						backgroundColor: 'rgb(247,156,156)',
						borderWidth: 0,
						lineTension: 0,
						pointBorderWidth: 0,
						pointRadius: 0,
						fill: true,
						order: 1,
						datalabels: {
							display: false,
						},
					},
				],
			},

			options: {
				plugins: {
					datalabels: {
						display: false,
					},
				},
				hover: {
					mode: null,
				},
				responsive: false,
				animation: {
					duration: 0,
				},
				tooltips: {
					enabled: false,
				},
				layout: {
					padding: 10,
				},
				legend: {
					display: false,
				},

				scales: {
					yAxes: [
						{
							display: true,
							gridLines: {
								display: false,
								drawBorder: false,
								lineWidth: 2,
								color: 'rgb(217,217,217)',
								tickMarkLength: 0,
							},
							ticks: {
								display: true,
								beginAtZero: true,
								fontColor: '#000',
								fontStyle: '500',
								fontSize: 20,
								min: 0,
								// max: 100,
								padding: 10,
								maxTicksLimit: 9,
							},
						},
					],
					xAxes: [
						{
							display: true,
							gridLines: {
								display: true,
								drawOnChartArea: false,
								lineWidth: 2,
								drawBorder: false,
								tickMarkLength: 10,
								zeroLineWidth: 2,
								zeroLineColor: 'rgb(224,224,224)',
							},
							ticks: {
								display: true,
								beginAtZero: true,
								fontColor: '#000',
								fontStyle: '500',
								fontSize: 16,
								padding: 10,
								autoSkip: false,
								maxRotation: 0,
								minRotation: 0,
							},
						},
					],
				},
			},
		});
	}
}
