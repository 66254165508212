import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AnyService } from '../../service/any.service';
export interface VerticalBarChartItem {
    reportDate: string;
    value: number;
    value1?: number;
    value2?: number;
}

@Component({
    selector: 'app-vertical-bar-chart',
    templateUrl: './vertical-bar-chart.component.html',
    styleUrls: ['./vertical-bar-chart.component.scss']
})
export class VerticalBarChartComponent implements OnInit, OnChanges {

    isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    @Input() svgTitle = '';
    @Input() svgHeight = 500;
    @Input() svgWidth = 960;
    @Input() data: VerticalBarChartItem[] = [];
    @Input() barWidth = 20;
    @Input() maxValue = 1;
    @Input() flag: boolean;
    @Input() percentFlag = false;
    @Input() unit = false;
    @Input() countTicks = 5;
    tooltipYPosition = 0;

    private origMaxValue = 1;
    public currentUnit: string;

    yArr = [];
    yLength = 0;
    xlenght = 0;

    constructor(private service: AnyService) {
        this.tooltipYPosition = this.isSafari ? -15 : 20;
    }

    ngOnInit() {
        const unit = this.service.getCurrentUnit();
        if (unit !== this.currentUnit) {
            this.currentUnit = unit;
        }
        this.service.currentUnitEmitter.subscribe(u => {
            this.currentUnit = u;
            this.updateGraph();
        });

        this.updateGraph();
    }

    public updateGraph () {
        this.origMaxValue = this.maxValue;
        this.maxValue = this.getMaxValue(this.data);
        this.yArr = this.getYAxis(this.maxValue);
        this.yLength = (this.svgHeight - 50) / this.yArr.length;
        if (this.data.length > 0) {
            this.xlenght = this.svgWidth / this.data.length;
        }
    }

    unitConversion(unit, value) {
        return this.service.unitConversion(unit, value);
    }

    ngOnChanges(chnage: SimpleChanges) {
        this.updateGraph();
    }


    getMaxValue(data: VerticalBarChartItem[]) {
        const arr = [];
        if (this.unit) {
            data.map(r => {
                arr.push(this.unitConversion(this.currentUnit, r.value));
            });
        } else {
            data.map(r => {
                arr.push(r.value);
            });
        }

        if (arr.length < 1) {
            return this.maxValue;
        }
        const max = arr.reduce((a, b) => (a > b) ? a : b);
        // console.log(Math.max(max, this.maxValue), 'max', this.maxValue);
        if (this.unit) {
            return max;
        } else {
            return Math.max(max, this.maxValue);
        }

    }

    getYAxis(value: number) {
        const arr = [];
        // const countTicks = 10;
        const step = value / this.countTicks;
        for (let i = 0; i <= this.countTicks; i++) {
            arr.push(Math.round(step * i));
        }
        // while (value >= 0) {
        //     arr.push(value);
        //     value = value - 10;
        // }
        return arr.reverse();
    }

    getHeightAndYPositionRect(value: number) {
        const svgHeight_ex = this.svgHeight - 82;
        const svgHeight_in = svgHeight_ex - 45;
        const height = svgHeight_ex * value / this.maxValue;
        const yPosition = svgHeight_ex - height + 33;
        return [height, yPosition];
    }

    getYFromValue (value: number) {
        const svgHeight_ex = this.svgHeight - 82;
        const height = svgHeight_ex * value / (this.maxValue);
        return svgHeight_ex - height + 33;
    }
}
