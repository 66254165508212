export enum TableViewStyle {
	Table = 0,
	Grid = 1,
}

export enum DateFormats {
	mmddyyyy = 'MM.dd.yyyy',
	mmddyyyy_v2 = 'MM/dd/yyyy',
	mmddyyyy_HHmmss = 'MM/dd/yyyy, HH:mm:ss',
	yyyymmdd_kr = 'yyyy년 M월 d일',
	HHmmss = 'HH:mm:ss',
	mmddEEEE_yyyyHHmmss = 'M월 d일 EEEE, yyyy / HH:mm:ss',
}
