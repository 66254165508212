import { Component, OnInit, Input, ViewChild, ElementRef, OnChanges } from '@angular/core';

export interface ChartDonutItem {
  percent: number;
  color?: string;
  value?: number;
}

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnInit, OnChanges {

  @Input() height = 100;
  @Input() width = 100;
  item: ChartDonutItem
  @Input() set itemSet(value) {
    this.item = value;
    if (this.percentFlag) {
      // if (this.item.percent == Infinity)
      //   this.item.percent = 0;

      this.centerText = this.item.percent ? this.item.percent : 0.00;

    } else {
      this.centerText = this.item.value ? this.item.value : 0.00;

    }
    // this.centerText = !this.item.value ? this.item.percent : this.item.value;
    if (this.centerText == Infinity)
      this.centerText = 0;
  }
  @Input() centerText: number;
  @Input() percentFlag = false;

  circleLength = 257.611;
  circleLength2 = 194.778744523;
  center = 55;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    
  }

  _strokeDashArray(item: ChartDonutItem) {
    let x, y;
    item.color = '#A9A9A9';
    
    const len = this.circleLength;
    if (this.percentFlag) {
      if (!isNaN(item.percent)) {
        if (!item.percent || item.percent == 0 || Math.round(item.percent) == 0) {
          item.color = '#393739'
        } else {
          x = len * Math.min(100, item.percent) / 100;
          y = len - x;
        }

      } else {
        item.color = '#393739'
      }
      // this.centerText = this.item.percent;

    } else {
      if (!isNaN(item.value)) {
        if (!item.value || item.value == 0 || Math.round(item.value) == 0) {
          item.color = '#393739';
        } else {
          x = len * Math.min(100, item.value) / 100;
          y = len - x;
        }

      } else {
        item.color = '#393739';
      }

    }

    return [x, y];
  }

  strokeDashArray(item: ChartDonutItem) {
    return this._strokeDashArray(item).join(' ');
  }

  strokeDashOffSet(item: ChartDonutItem): number {
    return this.circleLength / 4 - this.circleLength;
  }
}
