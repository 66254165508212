import { Component, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import { GeoPoint, LatLon, LatLng } from '../../types/geo-data.types';
import { TripDetails, Driver2 } from '../../types/data-types';
import { DateFormats } from '../../uicommon';
import { AppContextService } from '../../app-context.service';
import { TripHistoryItem } from '../trip-history-table/trip-history-table.component';

declare const BMap: any;
declare const BMAP_SATELLITE_MAP: any;

@Component({
    selector: 'app-trip-map',
    templateUrl: './trip-map.component.html',
    styleUrls: ['./trip-map.component.scss']
})
export class TripMapComponent implements OnInit {
    
    DateFormats = DateFormats;

    public mapElement;

    // 드라이버 선택 패널
    @Input() driverView = false;
    @Input() drivers: Driver2[] = [];
    showDriverSelectPanel = false;
    showDriverDetailView = false;

    // 드라이버 현재 상태 패널
    @Input() driverStatusView = false;
    @Input() driver: Driver2;
    showDriverStatusPanel = false;

    // Trip 뷰 패널
    @Input() tripView = false;
    showTripViewPanel = false;
    @Input() trip: TripHistoryItem;

    
    constructor(public appContext: AppContextService) {
        
    }

    ngOnInit() {
    }
    private _map: any;
    mapOptions: any = {};
    status = '';
    arrFeatures = [];
    
    onReady(map: any) {
        this._map = map;
    
        map.centerAndZoom(new BMap.Point(116.404, 39.915), 11);
        map.enableScrollWheelZoom(true);
        //map.addControl(new BMap.ZoomControl());
        map.setCurrentCity('北京');
        
        //var zoomCtrl = ;
        //map.addControl(new BMap.MapTypeControl());
        
        this.status = '상태?';
        // 添加监听事件
        map.addEventListener('tilesloaded', () => {
        this.status = 'load complete';
        });
        var bounds = map.getBounds();
        // 获取西南角的经纬度信息
        var sw = bounds.getSouthWest();
        // 获取东北角的经纬度信息 东边的经度>西边的经度 北边的纬度>南边的纬度
        var ne = bounds.getNorthEast();
        var lngSpan = ne.lng - sw.lng;
        var latSpan = ne.lat - sw.lat;
        var arrFeatures = [];
        // for (var i = 0; i < 20; i ++) {
        //     // 初始化位置
        //     var mpt = new BMap.Point(sw.lng + lngSpan * (Math.random() * 0.7 + 0.15),
        //             sw.lat + latSpan * (Math.random() * 0.7 + 0.15));
                    
        //     // 变换icon
        //     var iconOffsetX = 42;
        //     var iconOffsetY = 66;
        //     var marker = new BMap.Marker(mpt);
        //     map.addOverlay(marker);
        //     //var massFeature =  new BMap.MassFeature(mpt, {data: 'MassFeature' + i});
        //     //arrFeatures.push(massFeature);
            
        // }
        this.arrFeatures = arrFeatures
       
        //map.addEventListener('click', this._click.bind(this));
    }
    
  

    applyVehicleFilter() {
    }
}
