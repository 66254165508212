import { Injectable } from '@angular/core';
import { AWSAppSyncClient, AUTH_TYPE } from 'aws-appsync';
import * as AWS from 'aws-sdk';
import { environment } from '../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class RealtimeAppsyncService {
    client: AWSAppSyncClient<any>;

    constructor() {
        const url = environment.REAL_TIME_APPSYNC_ENDPOINT;
        const region = environment.REAL_TIME_APPSYNC_REGION;
        const type = AUTH_TYPE.AWS_IAM;

        // If you want to use AWS...

        AWS.config.update({
            region: environment.REAL_TIME_APPSYNC_REGION,
            credentials: new AWS.Credentials({
                accessKeyId: environment.REAL_TIME_APPSYNC_AWS_ACCESS_KEY_ID,
                secretAccessKey: environment.REAL_TIME_APPSYNC_AWS_SECRET_ACCESS_KEY
            })
        });
        const credentials = AWS.config.credentials;

        // Set up Apollo client
        if(!this.client){
            this.client = new AWSAppSyncClient({
                url, region, auth: { type, credentials, }
                //disableOffline: true      //Uncomment for AWS Lambda
            });
        }
    }
    disconnectAppSync(){
        console.log("disconnectAppSync",this.client)
        
        this.client = null;
    }
}
