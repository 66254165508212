import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MenuItem } from '../menu-strip/menu-strip.component';
// import { AppContextService } from '../service/app-context.service'; 데이터 호출부분 변경
import { Skor, Driver2 } from '../../types/data-types';
import { driver2Skor } from '../../types/common';

@Component({
    selector: 'app-skor-view',
    templateUrl: './skor-view.component.html',
    styleUrls: ['./skor-view.component.scss']
})
export class SkorViewComponent implements OnInit {

    @Input() picture = false;

    // smallCard가 참인 경우: Overview에서 드라이버 목록
    // smallcard가 참이 아닌 경우: Overview에서 상단 회사 카드, Driver에서 상단 드라이버 상세 카드.
    @Input() smallCard = false;

    // !smallCard인 경우 companyCard가 참인 경우엔 Overview에 들어가는 카드.
    // companyCard가 참이 아닌 경우, Driver에서 상단 드라이버 상세 카드.
    @Input() companyCard = false;

    @Input('short')
    set setShort(short: boolean) {
        this.short = short;
        if (short) {
            this.showFooter = true;
        }
    }

    short = false;

    @Input() showGrade = true;
    @Input() showTitle = true;
    @Input() showFooter = false;

    @Output() clickDetail = new EventEmitter();

    periods: MenuItem[] = [
        {
            label: 'Today',
            action: () => {
                //this.appContext.setDateRangeByDays(1);
            },
        },
        {
            label: 'Last 7 Days',
            action: () => {
                //this.appContext.setDateRangeByDays(7);
            },
        },
        {
            label: 'Last 30 Days',
            action: () => {
                //this.appContext.setDateRangeByDays(30);
            },
        },
        {
            label: 'Custom',
            action: () => {
                
            },
        },
    ];

    @Input('skor')
    set setSkor(skor: Skor) {
        if (!skor) { return; }
        this.skor = skor;
        this.skorUri = encodeURIComponent(JSON.stringify(skor));
    }

    // skor로 변환
    @Input('driver')
    set setDriver(driver: Driver2) {
        this.skor = driver2Skor(driver);
    }

    skor: Skor = {
        rank: 1,
        title: 'TITLE',
        grade: 4,
        overall: 85.55,
        focus: 64.44,
        guard: 83.33,
        speed: 92.22,
        photo: '',
        // photo: '/assets/images/cow.jpg',
    };
    skorUri = '';

    // constructor(private appContext: AppContextService) {
    // }

    getSelectedIndex() {
        // return this.appContext.dateLabelSelected$.value == 1 ? 0 :
        //     this.appContext.dateLabelSelected$.value == 7 ? 1 :
        //     this.appContext.dateLabelSelected$.value == 30 ? 2 : 3;
    }

    ngOnInit() {
    }

}
