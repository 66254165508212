import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class IndexedDBService {
	private dbName = 'CarviIndexedDB';
	private storeName = 'basicSkorListStore';

	constructor() {
		this.initializeDB();
	}

	// private async initializeDB(): Promise<IDBDatabase> {
	// 	return new Promise<IDBDatabase>((resolve, reject) => {
	// 		const request = indexedDB.open(this.dbName, 1);

	// 		request.onerror = (event: any) => {
	// 			reject('IndexedDB fail');
	// 		};

	// 		request.onsuccess = (event: any) => {
	// 			const db = (event.target as any).result;
	// 			resolve(db);
	// 		};

	// 		request.onupgradeneeded = (event: any) => {
	// 			const db = (event.target as any).result;
	// 			db.createObjectStore(this.storeName, { keyPath: 'cacheKey', autoIncrement: true });
	// 		};
	// 	});
	// }

	async initializeDB(): Promise<IDBDatabase> {
		return new Promise<IDBDatabase>((resolve, reject) => {
			const req = indexedDB.open(this.dbName, 1);

			req.onerror = () => reject('IndexedDB fail');
			req.onsuccess = (event) => resolve((event.target as any).result);

			req.onupgradeneeded = (event) => {
				const db = (event.target as any).result;
				db.createObjectStore(this.storeName, { keyPath: 'cacheKey', autoIncrement: true });
			};
		});
	}

	async get(cacheKey: string): Promise<any> {
		const db = await this.initializeDB();
		return new Promise<any>((resolve, reject) => {
			const transaction = db.transaction([this.storeName]);
			const objectStore = transaction.objectStore(this.storeName);
			const request = objectStore.get(cacheKey);

			request.onsuccess = (event: any) => {
				const result = (event.target as any).result;
				resolve(result ? result.data : null);
			};

			request.onerror = () => reject('IndexedDB get fail');
		});
	}

	async set(cacheKey: string, data: any): Promise<void> {
		const db = await this.initializeDB();
		return new Promise<void>((resolve, reject) => {
			const transaction = db.transaction([this.storeName], 'readwrite');
			const objectStore = transaction.objectStore(this.storeName);
			const request = objectStore.put({ cacheKey, data });

			request.onsuccess = () => resolve();
			request.onerror = () => reject('IndexedDB set fail');
		});
	}

	async setExpirationTimeOfStore(): Promise<void> {
		if (!window.indexedDB) {
			return;
		}

		const initExpirationTime = Date.now() + 24 * 60 * 60 * 1000;

		try {
			const savedExpirationTime = await this.get('initExpirationTime');
			if (savedExpirationTime && savedExpirationTime > Date.now()) {
				const remainTime = savedExpirationTime - Date.now();

				const hours = Math.floor(remainTime / (1000 * 60 * 60));
				const minutes = Math.floor((remainTime % (1000 * 60 * 60)) / (1000 * 60));
				const seconds = Math.floor((remainTime % (1000 * 60)) / 1000);

				console.log('db  ', `${hours}:${minutes}:${seconds}`);
			} else {
				await this.clearStore();
				await this.set('initExpirationTime', initExpirationTime);
			}
		} catch (err) {
			console.error(err);
		}
	}

	async clearStore(): Promise<void> {
		const db = await this.initializeDB();
		return new Promise<void>((resolve, reject) => {
			const transaction = db.transaction([this.storeName], 'readwrite');
			const objectStore = transaction.objectStore(this.storeName);
			const request = objectStore.clear();

			request.onsuccess = () => resolve();
			request.onerror = () => reject('indexedDB clear store fail');
		});
	}
}
