import { Injectable } from '@angular/core';
import {AuthService} from './auth.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

  constructor(private auth: AuthService, private http: HttpClient) { }

  public post (path: string, data: any): Promise<any> {
      return this.request(`${environment.apiEndPointURl}/${path}`, 'post', data);
  }

  public get (path: string, data: any): Promise<any> {
      return this.request(`${environment.apiEndPointURl}/${path}`, 'get', data);
  }

  private request (url: string, method: 'post' | 'get', data: any): Promise<any> {
      const httpOptions = {
          headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.auth.getToken(),
          })
      };
      if ( ! data) {
          data = {};
      }
      return new Promise((resolve, reject) => {
          if (method === 'get') {
              let uriParams = '?';
              let isFirst = true;
              for (const key in data) {
                  if ( ! data.hasOwnProperty(key)) {
                      continue;
                  }
                  if (isFirst) {
                      isFirst = false;
                  } else {
                      uriParams += '&';
                  }
                  uriParams += key + '=' + encodeURIComponent(data[key]);
              }
              if (uriParams !== '?') {
                  url += uriParams;
              }
              console.log(url);
              this.http.get(url, httpOptions)
                  .subscribe((res) => {
                      resolve(res);
                  }, (error) => {
                      console.log('error', error);
                      reject(error);
                  });
          } else {
              this.http.post(url, data, httpOptions)
                  .subscribe((res) => {
                      resolve(res);
                  }, (error) => {
                      console.log('error', error);
                      reject(error);
                  });
          }
      });
  }
}
