import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Trip, TripLogs } from '../../types/data-types';
import { driver2TempName, stringifyLocation, mileToKm, driver2TempVehicle } from '../../types/common';
import { DateFormats } from '../../uicommon';
import * as moment from 'moment';
import { DataService } from '../../service/data.service';
import { AppContextService } from '../../app-context.service';

export interface TripHistoryItem extends Trip {
    index?: number;
    name?: string;
    vehicle?: string;
    startDate?: string;
    endDate?: string;
    duration?: any;
    distance?: number; // km
    eventCount?: number;
    startLoc?: string;
    alert_count?:number;
    startAddress?: string;
    endLoc?: string;
    endAddress?: string;
}

@Component({
    selector: 'app-trip-history-table',
    templateUrl: './trip-history-table.component.html',
    styleUrls: ['./trip-history-table.component.scss']
})
export class TripHistoryTableComponent implements OnInit {

    DateFormats = DateFormats;
    stringifyLocation = stringifyLocation;

    @Input('trips')
    set setTripData(trips: Trip[]) {
        this.tripData = trips.map<TripHistoryItem>((t, i) => {
            const detailParsed = t;
            const s = t.trip_start;
            const e = t.trip_end;

            const ret: TripHistoryItem = {
                ...t,
                ...detailParsed,
                index: i + 1,
                // photo: '/assets/images/cow.jpg',
                // name: driver2TempName('' + t.camera_id),
                // vehicle: driver2TempVehicle(t.camera_id),
                startDate: s, 
                endDate: e, 
                duration: moment(e).diff(moment(s)),
                distance: t.stats.mileage,
                eventCount: t.alert_count,
                startLoc: stringifyLocation(t.location.source),
                endLoc: stringifyLocation(t.location.destination),
            };
            this.ds.getAddress(t.location.source)
                .then(address => ret.startAddress = address.address);
            this.ds.getAddress(t.location.destination)
                .then(address => ret.endAddress = address.address);
            return ret;
        });
        this.tripData.forEach(async t => {
        });
        this.tripData.forEach(async t => {
            t.endAddress = (await this.ds.getAddress(t.location.destination)).address;
        });
    }
    sorted: any = {};

    @Output()
    tripSelected = new EventEmitter<Trip>();

    tripData: TripHistoryItem[] = [];

    @Input()
    showDriver = true;

    constructor(private ds: DataService, public appContext: AppContextService) { }

    ngOnInit() {
    }

    sortColumn(col: string, order: number) {
        this.sorted[col] = order;
        this.tripData.sort((lhs, rhs) => {
            return (lhs[col] - rhs[col]) * order;
        });
    }
}
