import {
	AfterViewInit,
	Component,
	Input,
	OnDestroy,
	OnInit,
	ViewChild,
	ChangeDetectorRef,
	ChangeDetectionStrategy,
	OnChanges,
	SimpleChanges,
} from '@angular/core';

import * as Chart from 'chart.js';
import { PluginServiceRegistrationOptions } from 'chart.js';
import chartDataLabels from 'chartjs-plugin-datalabels';

@Component({
	selector: 'app-average-skor-trend-charts',
	templateUrl: './average-skor-trend-charts.component.html',
	styleUrls: ['./average-skor-trend-charts.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AverageSkorTrendChartComponent implements OnInit, OnDestroy, OnChanges {
	public chart: any;

	@Input() monthlyChartData: any;

	constructor(private changeRef: ChangeDetectorRef) {}

	ngOnInit() {
		setTimeout(() => {
			this.createChart();
		});
	}

	createChart() {
		if (this.monthlyChartData && this.monthlyChartData.length > 0) {
			const chart = new Chart('avgSkorTrendChart', {
				plugins: [chartDataLabels],
				data: {
					labels: this.monthlyChartData[4],
					datasets: [
						{
							data: this.monthlyChartData[0],
							label: 'overall',
							type: 'line',
							borderColor: '#000000',
							backgroundColor: 'rgba(255,255,255,0)',
							borderWidth: 2,
						},
						{
							data: this.monthlyChartData[1],
							label: 'focus',
							type: 'line',
							borderColor: '#ea533c',
							backgroundColor: 'rgba(255,255,255,0)',
							borderWidth: 2,
						},
						{
							data: this.monthlyChartData[2],
							label: 'guard',
							type: 'line',
							borderColor: '#9fa09c',
							backgroundColor: 'rgba(255,255,255,0)',
							borderWidth: 2,
						},
						{
							data: this.monthlyChartData[3],
							label: 'speed',
							type: 'line',
							borderColor: '#ffffff',
							backgroundColor: 'rgba(255,255,255,0)',
							borderWidth: 2,
						},
					],
				},
				options: {
					responsive: false,
					animation: {
						duration: 0,
					},
					layout: {
						padding: 0,
					},
					legend: {
						display: false,
					},

					plugins: {
						datalabels: {
							display: false,
						},
					},

					tooltips: {
						mode: 'nearest',
						displayColors: false,
						titleFontSize: 0,
						callbacks: {
							label: function (ctx: any) {
								const reg = /\[|]|NaN|,|\s/g;
								const label = ctx.value.replace(reg, '');

								return label;
							},
						},
					},
					events: ['click'],

					scales: {
						yAxes: [
							{
								// display: false,
								// stacked: false,
								// gridLines: {
								// 	display: false,
								// 	lineWidth: 0,
								// 	zeroLineWidth: 0,
								// 	drawBorder: false,
								// },
								position: 'left',
								ticks: {
									// beginAtZero: true,
									min: 0,
									max: 100,
									fontColor: '#000',
									fontSize: 12,
									padding: 0,
								},
							},
						],
						xAxes: [
							{
								ticks: {
									// beginAtZero: true,
									min: 0,
									max: 100,
									fontColor: '#000',
									fontSize: 12,
									padding: 0,
								},
							},
						],
					},
				},
			});

			chart.update();
		}
	}

	ngOnDestroy() {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes) {
			setTimeout(() => {
				this.createChart();
			});
		}
	}
}
