import {
	AfterViewInit,
	Component,
	Input,
	OnDestroy,
	OnInit,
	ViewChild,
	ChangeDetectorRef,
} from '@angular/core';

import * as Chart from 'chart.js';
import chartDataLabels from 'chartjs-plugin-datalabels';

@Component({
	selector: 'app-daily-change-speed-line-charts',
	templateUrl: './daily-change-speed-line-charts.component.html',
	styleUrls: ['./daily-change-speed-line-charts.component.scss'],
})

// 📌230224 일별 급속 속도 변화(점수)
export class DailyChangeSpeedLineChartComponent implements OnInit {
	public chart: any;

	constructor() {}

	ngOnInit() {
		this.createChart();
	}

	createChart() {
		const date = new Date();
		const year = date.getFullYear();
		const month = date.getMonth() + 1;
		const day = new Date(year, month, 0).getDate();

		const days = Array.from({ length: day }, (_, key: number) => {
			return `${key + 1}`;
		});

		const chart = new Chart('changeSpeedChart', {
			plugins: [chartDataLabels],
			type: 'line',
			data: {
				labels: days,
				datasets: [
					{
						data: [
							20, 70, 40, 0, 50, 10, -40, 30, -60, -90, 70, 40, -50, 80, 90, -40, 70, 90, 30, -50,
							60, -20, 40, 10, 50, 30, 80, 50, -30, 20, -80,
						],
						borderColor: 'rgb(118,113,113)',
						borderWidth: 5,
						lineTension: 0.1,
						pointBorderWidth: 0,
						pointRadius: 0,
						fill: false,
						datalabels: {
							display: false,
						},
					},
				],
			},

			options: {
				plugins: {
					datalabels: {
						display: false,
					},
				},
				hover: {
					mode: null,
				},
				responsive: false,
				animation: {
					duration: 0,
				},
				tooltips: {
					enabled: false,
				},
				layout: {
					padding: 10,
				},
				legend: {
					display: false,
				},

				scales: {
					yAxes: [
						{
							display: true,
							gridLines: {
								display: true,
								drawBorder: false,
								lineWidth: 2,
								color: 'rgb(217,217,217)',
								tickMarkLength: 0,
							},
							ticks: {
								display: false,
								beginAtZero: true,
								fontColor: '#000',
								fontStyle: '400',
								fontSize: 20,
								min: -100,
								max: 100,
							},
						},
					],
					xAxes: [
						{
							display: true,
							gridLines: {
								display: true,
								tickMarkLength: 0,
							},
							ticks: {
								display: true,
								beginAtZero: true,
								fontColor: '#000',
								fontStyle: '400',
								fontSize: 16,
								padding: 10,
								autoSkip: false,
								maxRotation: 0,
								minRotation: 0,
							},
						},
					],
				},
			},
		});
	}
}
