import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';

import * as Chart from 'chart.js';

@Component({
	selector: 'app-impact-bar-charts',
	templateUrl: './impact-bar-charts.component.html',
	styleUrls: ['./impact-bar-charts.component.scss'],
})

// 📌230103 기다(충격 발생 그룹 스택 차트)
export class ImpactBarComponent implements OnInit, AfterViewInit {
	public chart: any;

	constructor() {}

	ngOnInit() {
		this.impactBarChart();
	}

	impactBarChart() {
		const chart = new Chart('impactBar', {
			type: 'horizontalBar',
			data: {
				datasets: [
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(217,217,217)',
						hoverBackgroundColor: 'rgb(217,217,217)',
						borderColor: 'rgb(217,217,217)',
						stack: 'left',
						data: [94],
						borderWidth: 1,
						barThickness: 60,
						label: '저위험',
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(235,99,112)',
						hoverBackgroundColor: 'rgb(235,99,112)',
						borderColor: 'rgb(235,99,112)',
						stack: 'left',
						data: [8],
						borderWidth: 1,
						barThickness: 60,
						label: '고위험',
					},
				],
			},

			options: {
				responsive: false,
				animation: {
					duration: 0,
				},

				tooltips: {
					enabled: false,
				},

				plugins: {
					labels: {
						render: 'value',
						fontSize: '12',
						fontColor: '#000',
					},
				},

				legend: {
					display: false,
					labels: {
						usePointStyle: true,
						// padding: 30,
						boxWidth: 10,
					},
					position: 'bottom',
					align: 'center',
				},

				layout: {
					padding: 20,
				},

				scales: {
					yAxes: [
						{
							id: 'bar-stack',
							stacked: true,
							gridLines: {
								display: false,
							},
							ticks: {
								beginAtZero: true,
								display: false,
							},
						},
					],
					xAxes: [
						{
							offset: true,
							gridLines: {
								display: false,
							},
							ticks: {
								beginAtZero: true,
								display: false,
							},
						},
					],
				},
			},
		});
	}

	ngAfterViewInit() {}
}
