import { Component, OnInit, Input } from '@angular/core';
import { Label, Color } from 'ng2-charts';
import * as _ from 'lodash';
import { Factor } from '../../types/data-types';
import * as moment from 'moment';

@Component({
	selector: 'app-trend-line-bar-chart',
	templateUrl: './trend-line-bar-chart.component.html',
	styleUrls: ['./trend-line-bar-chart.component.scss'],
})
export class TrendLineBarChartComponent implements OnInit {
	Factor = Factor;
	@Input()
	div = '';
	@Input()
	factor: Factor = Factor.Focus;
	@Input('score')
	score = 0;
	//@ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

	@Input('aspectRatio')
	set setAspectRatio(ar: number) {
		this.lineChartOptions.aspectRatio = ar;
	}
	@Input('trend')
	set setTrend(trend) {
		if (!!trend && trend.length > 0) {
			let data = [],
				labels = [];
			let total = 0;
			trend.forEach((element, index) => {
				if (index > 0) {
					let diff;
					const current =
						String(element.date_key).substring(0, 4) +
						'-' +
						String(element.date_key).substring(4, 6) +
						'-' +
						String(element.date_key).substring(6, 8);
					const prev =
						String(trend[index - 1].date_key).substring(0, 4) +
						'-' +
						String(trend[index - 1].date_key).substring(4, 6) +
						'-' +
						String(trend[index - 1].date_key).substring(6, 8);
					diff = moment(new Date(current)).diff(new Date(prev));
					diff = Number(diff) / 86400000;
					total += diff;
				}
			});

			let firstData = moment(
				new Date(
					String(trend[0].date_key).substring(0, 4) +
						'-' +
						String(trend[0].date_key).substring(4, 6) +
						'-' +
						String(trend[0].date_key).substring(6, 8)
				)
			);
			let label = firstData.format('YYYYMMDD');
			const datas = [];
			for (let index = 0; index <= total; index++) {
				this.chartData.labels[index] = label;
				firstData = firstData.add(1, 'days');
				label = firstData.format('YYYYMMDD');

				const isData = trend.find((data) => {
					return data.date_key === this.chartData.labels[index];
				});
				if (!!isData) {
					if (isData.score !== null) {
						datas.push(Number(isData.score.toFixed(1)));
					} else {
						datas.push(null);
					}
				} else {
					datas.push(null);
				}
			}
			this.chartData.datasets[0].data = datas;
			// trend.forEach(function (element, index, array) {
			//     if(index > 0){
			//         const prev_date_key = trend[index-1].date_key;
			//         let diff = element.date_key - prev_date_key;
			//         // console.log("diff",diff)
			//         for(var i=1;i<Number(diff);i++){
			//             data.push(null);
			//             labels.push(element.date_key);
			//         }
			//     }

			//     if(element.score !== null)
			//         data.push(element.score.toFixed(1));
			//     else
			//         data.push(element.score);
			//     labels.push(element.date_key);
			// });
			//     this.chartData.datasets[0].data = data
			//     this.chartData.labels = labels
		} else {
			this.chartData.datasets[0].data = [];
			this.chartData.labels = [];
		}
	}
	chartData: any = {
		datasets: [{ data: {}, fill: false }],
		labels: [],
	};
	public lineChartData: any[];
	public lineChartLabels: Label[];
	public lineChartOptions: any =
		// (ChartOptions & { annotation: any })
		{
			responsive: true,
			scales: {
				xAxes: [{}],
				yAxes: [
					{
						id: 'y-axis-0',
						position: 'left',
						ticks: {
							min: 0,
							max: 100,
						},
					},
				],
			},
			// annotation: {
			// },
			maintainAspectRatio: false,
		};
	public lineChartColors: Color[] = [];

	constructor() {}

	ngOnInit() {
		// console.log("TrendLineBarChartComponent")
		// console.log(new Array(20).fill(0).map(_ => (80 + randomInt(0, 20))))
		// this.lineChartData = [
		//     {
		//         data: new Array(20).fill(0).map(_ => (80 + randomInt(0, 20))),
		//         label: 'Overall',
		//         fill: true,
		//         backgroundColor: (this.factor == Factor.Focus ? FactorColor.Focus :
		//             this.factor == Factor.Guard ? FactorColor.Guard : FactorColor.Speed) + '20',
		//         pointStyle: 'line'
		//     },
		//     {
		//         data: new Array(20).fill(70),
		//         label: 'min',
		//         fill: false,
		//         pointStyle: 'line'
		//     },
		//     {
		//         data: new Array(20).fill(90),
		//         label: 'max',
		//         fill: false,
		//         pointStyle: 'line'
		//     },
		// ];
		// console.log(this.div)
		let lineColor = '';
		if (this.div == 'overall') {
			lineColor = '#ea533c';
		} else if (this.div == 'focus') {
			lineColor = '#ea533c';
		} else if (this.div == 'guard') {
			lineColor = '#9fa09c';
		} else if (this.div == 'speed') {
			lineColor = '#000';
		}
		this.lineChartColors = [
			{
				//
				// borderColor: (this.infoCheck(this.score)),
				borderColor: lineColor,
			},
		];
	}

	infoCheck(number) {
		// A (Acceptable) '#fff'
		// B (Moderate) #ec5233'
		// C (Poor)'#9fa09c'
		// D (Dangerous)'#000'

		if (number >= 90) {
			return '#fff';
		} else if (number >= 80) {
			return '#ec5233';
		} else if (number >= 70) {
			return '#9fa09c';
		} else {
			return '#000';
		}
	}
}
