import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
	window.alert = function () {};
	console.log = function () {};
	enableProdMode();
}

// use the require method provided by webpack
// declare const require;
// we use the webpack raw-loader to return the content as a string
// console.log(localStorage.getItem('langID'), 'langid');

// const translations = require(`raw-loader!./i18n/messages.fr.xlf`);
// {
//   providers: [
//     {provide: TRANSLATIONS, useValue: translations},
//     {provide: TRANSLATIONS_FORMAT, useValue: 'xlf'}
//   ]
// }
platformBrowserDynamic().bootstrapModule(AppModule);
