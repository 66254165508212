import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { AppContextService } from '../../../app-context.service';
import { Driver2, Driver_info } from '../../../types/data-types';
import { DynamicDialogRef } from 'primeng/api';

enum PageType {
    // DrivingStatus = 1,
    RentalHistory = 2,
    DrivingPerformance = 3
    // TripHistory = 2,
    // RouteAnalysis = 3,
    // Geofencing = 4
}

@Component({
    selector: 'app-driver-detail-page',
    templateUrl: './driver-detail-page.component.html',
    styleUrls: ['./driver-detail-page.component.scss']
})
export class DriverDetailPageComponent implements OnInit, OnDestroy {
    PageType = PageType;
    faPhone = faPhone;

    currPage: PageType = PageType.DrivingPerformance;
    driver_info: Driver_info;
    driverSelectedObserver: Subscription;

    constructor(private appContext: AppContextService, public ref: DynamicDialogRef) {
        this.driverSelectedObserver = this.appContext.driverSelected$.subscribe(driver => {
            this.driver_info = driver;
            // this.driver_info.gender
            // this.driver_info.email
            // console.log(this.driver_info);
        });
    }

    ngOnInit() {
        // console.log('DriverDetailPageComponent');
        // console.log(PageType)
    }

    ngOnDestroy() {
        this.driverSelectedObserver.unsubscribe();
    }
}
