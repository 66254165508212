import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment'
import gql from 'graphql-tag';
import { Apollo, QueryRef } from 'apollo-angular';
import { AuthService } from './auth.service';
import { map, delay } from 'rxjs/operators';
import * as moment from 'moment';


// import {
//     GET_REPORTLIST_QUERY
// } from '../queries/queries';


@Injectable({
    providedIn: 'root'
})
export class ReportsService {
    reportsQuery: QueryRef<any>;
    loadReport = new Subject();
    reportTitleTriger$=new BehaviorSubject<{code:number,preTitle:{name1:string,name2:string}}>(null);
    // loadReport = this.readPDFReportList(query);
    constructor(
        private authService: AuthService,
        private http: HttpClient,
        private apollo: Apollo
    ) { }
    public getFile(url) {
        return this.http.get(url);
    }
    public downloadInnerHTML(filter_name, company_id, html_content) {
        const htmlJSON = {

            "html_content": html_content

        }

        return this.http.post(`${environment.reportDownloadUrl}/${filter_name}/${company_id}`, htmlJSON);

    }
    public setTrigger(value :{code:number,preTitle:{name1:string,name2:string,name3?:string}}){
        this.reportTitleTriger$.next(value);
    }
    public getTrigger(){
        return this.reportTitleTriger$
    }
    public readPDFReportList(query) {
        // const data= gql `query getAll{
        //     getReportsByFilter(filter:{report_id:{ne:" "}}){
        //       report_id
        //       report_title
        //       report_month_year
        //       created_date
        //       company_id
        //       url
        //     }
        //   }`
        // ;

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/graphql',
                'Authorization': this.authService.getToken(),
            })
        };
        // this.reportsQuery = this.apollo.watchgetdriverstatsQuery<any>({
        //     query: data
        // });

        return this.http.post(`${environment.apiCustomDateGraphqlURL}`, query);
    }
    // public getTripsDetails(camera_id: string, start_date: string, tripPage: number) {
    //     const getStartDate = moment(start_date).format('YYYY-MM-DD 00:00:00');
    //     this.tripsQuery = this.apollo.watchQuery<any>({
    //         query: GET_DRIVERS_TRIPS_DETAILS,
    //         variables: {
    //             camera_id: camera_id,
    //             start_date: getStartDate,
    //             trips_page: tripPage,
    //         },
    //         // forceFetch: true,
    //     });
    //     return this.tripsQuery.valueChanges.pipe(delay(100));
    // }


    

}
