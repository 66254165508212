import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-toggle-button',
    templateUrl: './toggle-button.component.html',
    styleUrls: ['./toggle-button.component.scss']
})
export class ToggleButtonComponent implements OnInit {


    @Input() value = false;
    @Output() valueChange = new EventEmitter<boolean>();

    @Input() small = false;
    @Input() big = false;

    constructor() { }

    ngOnInit() {
    }

}
