import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthService} from './auth.service';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class VideoService {
    constructor(
        private http: HttpClient,
        private auth: AuthService
    ) {}

    public get(path: string, options?: object) {
        // const httpOptions = {
        //     headers: new HttpHeaders({
        //         'Content-Type':  'application/json',
        //         'Access-Control-Allow-Origin': this.auth.getToken(),
        //     })
        // };
        return this.http.get(`${environment.dynamoDBUrl}/${path}`);
    }

    public post(path: string, body: object, options?: object) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': this.auth.getToken(),
            })
        };
        return this.http.post(`${environment.dynamoDBUrl}/${path}`, body, httpOptions);
    }

    public put(path: string, body?: object, options?: object) {
        return this.http.put(`${environment.dynamoDBUrl}/${path}`, body, options);
    }

    public delete(path: string, options?: object) {
        if (typeof options === 'undefined') {
            options = {};
        }
        options['headers'] = new HttpHeaders();
        options['headers'].set('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, DELETE');
        return this.http.delete(`${environment.dynamoDBUrl}/${path}`, options);
    }

    videoJobCreate(imei) {
        // 861107036108653
        return this.get(`vod/filelist/${imei}`);
    }

    alertVideoCheckFileList(imei) {
        // 861107036108653
        return this.get(`vod/check_filelist/${imei}`);
    }

    alertVideoUpload(imei, alertTime) {
        // 861107036108653
        return this.get(`vod/upload/${imei}/${alertTime}`);
    }

    alertVideoPlay(imei, alertType, alertTime) {
        // 861107036108653
        return this.get(`vod/play/${imei}/${alertType}/${alertTime}`);
    }
    checkAlertVideo(imei, filename){
        return this.get(`/vod/check_video/${imei}/${filename}`);
    }
    isCheckS3VideoFilePath(imei, filename){
        //return this.http.get(`https://s3.ap-northeast-2.amazonaws.com/korea-s3/861107036108653/20181118_153712_I1.mp4`);
        return this.http.get(`https://s3.ap-northeast-2.amazonaws.com/korea-s3/${imei}/${filename}`);
    }
 }
