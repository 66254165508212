import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnInit,
	ViewChild,
} from '@angular/core';

// apexcharts
import {
	ChartComponent,
	ApexAxisChartSeries,
	ApexChart,
	ApexTitleSubtitle,
	ApexXAxis,
	ApexNonAxisChartSeries,
	ApexPlotOptions,
	ApexAnnotations,
	ApexStroke,
	ApexFill,
	ApexTooltip,
	ApexResponsive,
	ApexYAxis,
	ApexGrid,
	ApexStates,
	ApexTheme,
	ApexDataLabels,
	ApexLegend,
} from 'ng-apexcharts';

export type ChartOptions = {
	series: ApexAxisChartSeries | ApexNonAxisChartSeries;
	chart: ApexChart;
	xaxis: ApexXAxis;
	yaxis: ApexYAxis | ApexYAxis[];
	title: ApexTitleSubtitle;
	colors: string[];
	dataLabels: ApexDataLabels;
	plotOptions: ApexPlotOptions;
	annotations: ApexAnnotations;
	stroke: ApexStroke;
	labels: string[];
	fill: ApexFill;
	tooltip: ApexTooltip;
	responsive: ApexResponsive[];
	grid: ApexGrid;
	states: ApexStates;
	subtitle: ApexTitleSubtitle;
	theme: ApexTheme;
	legend: ApexLegend;
};

@Component({
	selector: 'app-fei-overall-charts',
	templateUrl: './fei-overall-donut-charts.component.html',
	styleUrls: ['./fei-overall-donut-charts.component.scss'],
})

// 📌230221 연비 리포트 - 종합 도넛
export class FeiOverallDonutChartsComponent implements OnInit {
	@ViewChild('charts') charts: ChartComponent;
	public chartOptions: Partial<ChartOptions>;

	@Input() chart: ApexChart;
	@Input() annotations: ApexAnnotations;
	@Input() colors: string[];
	@Input() dataLabels: ApexDataLabels;
	@Input() series: ApexAxisChartSeries | ApexNonAxisChartSeries;
	@Input() stroke: ApexStroke;
	@Input() labels: string[];
	@Input() legend: ApexLegend;
	@Input() fill: ApexFill;
	@Input() tooltip: ApexTooltip;
	@Input() plotOptions: ApexPlotOptions;
	@Input() responsive: ApexResponsive[];
	@Input() xaxis: ApexXAxis;
	@Input() yaxis: ApexYAxis | ApexYAxis[];
	@Input() grid: ApexGrid;
	@Input() states: ApexStates;
	@Input() title: ApexTitleSubtitle;
	@Input() subtitle: ApexTitleSubtitle;
	@Input() theme: ApexTheme;

	constructor(private changeRef: ChangeDetectorRef) {}

	ngOnInit() {
		this.createChart();
	}

	createChart() {
		this.chartOptions = {
			series: [67.1, 71.4],
			colors: ['#d9d9d9', '#00b0f0'],
			chart: {
				height: 400,
				type: 'radialBar',
				animations: {
					enabled: false,
				},
			},

			states: {
				hover: {
					filter: {
						type: 'none',
					},
				},
			},

			plotOptions: {
				radialBar: {
					hollow: {
						size: '55%',
						background: 'transparent',
					},

					track: {
						show: true,
						background: '#f2f2f2',
						strokeWidth: '97%',
					},

					dataLabels: {
						show: true,
						name: {
							show: true,
							color: '#01b1ee',
							fontSize: '36px',
							fontFamily: undefined,
							fontWeight: 700,
						},

						value: {
							show: true,
							fontSize: '32px',
							color: '#87807f',
							fontWeight: 600,
							offsetY: 14,
						},

						total: {
							show: true,
							color: '#01b1ee',
							label: '71.4',
							fontSize: '42px',
							fontWeight: 700,
							formatter: function (w) {
								// return `${Math.round(w.config.series[0] * 10) / 10}`
								return `${w.config.series[0]}`;
							},
						},
					},
				},
			},
			legend: {
				show: false,
			},
			stroke: {
				lineCap: 'round',
			},
		};
	}
}
