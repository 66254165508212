import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { Subject,BehaviorSubject } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class CustomTranslateService {
    private lang$=new BehaviorSubject <string>(null);
    constructor(
        private http: HttpClient,
        private dialog: MatDialog
    ) { }
    setLang(lang:string){
        this.lang$.next(lang);
    }
    getLang(){
        return this.lang$.asObservable()
    }
    sendJson(lang:string){
        return this.http.get('../../assets/i18n/'+lang+'.json')
    }

}

