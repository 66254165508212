import {
	AfterViewInit,
	Component,
	Input,
	OnDestroy,
	OnInit,
	ViewChild,
	ChangeDetectorRef,
} from '@angular/core';

import * as Chart from 'chart.js';
import chartDataLabels from 'chartjs-plugin-datalabels';

@Component({
	selector: 'app-industry-distribution-charts',
	templateUrl: './industry-distribution-charts.component.html',
	styleUrls: ['./industry-distribution-charts.component.scss'],
})

// 📌230306 동종업계 급속 속도 변화 분포도 그래프
export class IndustryDistributionChartComponent implements OnInit {
	public chart: any;

	constructor() {}

	ngOnInit() {
		this.createChart();
	}

	createChart() {
		const chart = new Chart('industryDistributionChart', {
			plugins: [chartDataLabels],
			type: 'horizontalBar',
			data: {
				labels: ['급속 속도 변화 분포도'],
				datasets: [
					// ============ top
					{
						data: [20],
						backgroundColor: 'rgb(91,155,213)',
						borderColor: 'transparent',
						barPercentage: 0.6,
						stack: 'Stack 1',
						datalabels: {
							font: {
								size: 20,
								weight: 400,
							},
						},
					},
					{
						data: [30],
						backgroundColor: 'rgb(217,217,217)',
						borderColor: 'transparent',
						barPercentage: 0.6,
						stack: 'Stack 1',
						datalabels: {
							font: {
								size: 20,
								weight: 400,
							},
						},
					},
					{
						data: [50],
						backgroundColor: 'rgb(80,87,104)',
						borderColor: 'transparent',
						barPercentage: 0.6,
						stack: 'Stack 1',
						datalabels: {
							font: {
								size: 20,
								weight: 400,
							},
						},
					},
					// ============ bottom
					{
						data: [10],
						backgroundColor: 'rgb(91,155,213)',
						borderColor: 'transparent',
						barPercentage: 0.6,
						stack: 'Stack 2',
						datalabels: {
							font: {
								size: 20,
								weight: 400,
							},
						},
					},
					{
						data: [50],
						backgroundColor: 'rgb(217,217,217)',
						borderColor: 'transparent',
						barPercentage: 0.6,
						stack: 'Stack 2',
						datalabels: {
							color: '#000',
							font: {
								size: 20,
								weight: 500,
							},
						},
					},
					{
						data: [40],
						backgroundColor: 'rgb(80,87,104)',
						borderColor: 'transparent',
						barPercentage: 0.6,
						stack: 'Stack 2',
						datalabels: {
							font: {
								size: 20,
								weight: 400,
							},
						},
					},
				],
			},

			options: {
				hover: {
					mode: null,
				},
				responsive: false,
				animation: {
					duration: 0,
				},
				tooltips: {
					enabled: false,
				},
				layout: {
					padding: 10,
				},
				legend: {
					display: false,
				},
				plugins: {
					datalabels: {
						display: true,
						align: 'center',
						anchor: 'center',
						color: '#fff',
						formatter(value, context) {
							return `${value}%`;
						},
					},
				},

				scales: {
					yAxes: [
						{
							display: false,
							gridLines: {
								display: true,
								color: 'transparent',
								drawBorder: false,
							},
							ticks: {
								display: false,
								beginAtZero: true,
								min: 0,
							},
							stacked: true,
						},
					],
					xAxes: [
						{
							display: false,
							gridLines: {
								display: true,
							},
							ticks: {
								beginAtZero: true,
								min: 0,
							},
							stacked: true,
						},
					],
				},
			},
		});
	}
}
