import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class SharedService {
	private loadingTextSubject = new BehaviorSubject<string>('');
	loadingText$ = this.loadingTextSubject.asObservable();

	setLoadingText(text: string) {
		this.loadingTextSubject.next(text);
	}
}
