import { Component, OnInit, Input } from '@angular/core';

export interface MenuItem {
    label?: string;
    routerLink?: any[];
    action?: () => void;
}

@Component({
    selector: 'app-menu-strip',
    templateUrl: './menu-strip.component.html',
    styleUrls: ['./menu-strip.component.scss']
})
export class MenuStripComponent implements OnInit {

    @Input()
    items: MenuItem[];

    @Input()
    style = {};

    @Input()
    separator: string;

    // 선택된 인덱스. -1이면 선택안됨.
    selected: number;

    @Input('selected')
    set setSelected(i: number) {
        this.onClickItem(i);
    }

    constructor() { }

    ngOnInit() {
    }

    onClickItem(i: number) {
        this.selected = i;
        if (i != -1 && this.items[i].action) {
            this.items[i].action();
        }
    }
}
