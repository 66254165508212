import {
	AfterViewInit,
	Component,
	Input,
	OnDestroy,
	OnInit,
	ViewChild,
	ChangeDetectorRef,
} from '@angular/core';

import * as Chart from 'chart.js';
import chartDataLabels from 'chartjs-plugin-datalabels';

@Component({
	selector: 'app-fei-company-mileage-charts',
	templateUrl: './fei-company-mileage-charts.component.html',
	styleUrls: ['./fei-company-mileage-charts.component.scss'],
})

// 📌230303 연비 기관 주행거리
export class FeiCompanyMileageChartComponent implements OnInit {
	public chart: any;

	constructor() {}

	ngOnInit() {
		this.createChart();
	}

	createChart() {
		const chart = new Chart('feiMileageBarChart', {
			plugins: [chartDataLabels],
			type: 'horizontalBar',
			data: {
				labels: ['운행거리'],
				datasets: [
					{
						data: [111530.13],
						backgroundColor: 'rgb(106,142,208)',
						borderColor: 'rgb(106,142,208)',
						borderWidth: 1,
						barPercentage: 1,
						datalabels: {
							display: false,
						},
					},
					{
						data: [147985.72],
						backgroundColor: 'rgb(235,99,112)',
						borderColor: 'rgb(235,99,112)',
						borderWidth: 1,
						barPercentage: 1,
					},
				],
			},

			options: {
				responsive: false,
				animation: {
					duration: 0,
				},
				tooltips: {
					enabled: false,
				},
				layout: {
					padding: 0,
				},
				legend: {
					display: false,
				},
				plugins: {
					datalabels: {
						display: false,
					},
				},
				hover: {
					mode: null,
				},

				scales: {
					yAxes: [
						{
							display: false,
							gridLines: {
								display: false,
								color: 'transparent',
							},
							ticks: {
								display: false,
								beginAtZero: true,
								min: 0,
							},
						},
					],
					xAxes: [
						{
							display: false,
							gridLines: {
								display: false,
							},
							ticks: {
								beginAtZero: true,
								min: 0,
							},
						},
					],
				},
			},
		});
	}
}
