import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../service/auth.service';
import { AlertService } from '../service/alert.service';
import { CustomTranslateService } from './customTranslate.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

    constructor(private router: Router,
    private authService:AuthService,
    private alertService:AlertService) { }
    canActivate(): boolean | Observable<boolean> | Promise<boolean> {
        const islogin=this.authService.isAuthenticated().islogin;
        const message=this.authService.isAuthenticated().message;
            if(!localStorage.getItem('UserPoolId') && !localStorage.getItem('ClientId')){
                this.router.navigate(['/auth/sign-in']);
                localStorage.clear();
                return false;
     
            }else{
                return true;
            }
        
 
     
    }

}
