import { Component, OnInit, Input } from '@angular/core';
import { Label, Color } from 'ng2-charts';
import { randomInt } from '../../pages/lib/utils';
import { FactorColor } from '../../types/data-types';
import * as _ from 'lodash';

@Component({
	selector: 'app-skor-trend-line-chart',
	templateUrl: './skor-trend-line-chart.component.html',
	styleUrls: ['./skor-trend-line-chart.component.scss'],
})
export class SkorTrendLineChartComponent implements OnInit {
	//@ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

	@Input('aspectRatio')
	set setAspectRatio(ar: number) {
		this.lineChartOptions.aspectRatio = ar;
	}

	public lineChartData: any[];
	public lineChartLabels: Label[] = [
		'2020.01.01',
		'',
		'',
		'',
		'',
		'',
		'',
		'',
		'',
		'',
		'',
		'',
		'',
		'',
		'',
		'',
		'',
		'',
		'',
		'2020.02.29',
	];
	public lineChartOptions: any =
		// (ChartOptions & { annotation: any })
		{
			responsive: true,
			scales: {
				xAxes: [{}],
				yAxes: [
					{
						id: 'y-axis-0',
						position: 'left',
						ticks: {
							min: 0,
							max: 100,
						},
					},
				],
			},
			// annotation: {},
			maintainAspectRatio: false,
		};
	public lineChartColors: Color[] = [
		{
			borderColor: FactorColor.Overall,
		},
		{
			borderColor: FactorColor.Focus,
		},
		{
			borderColor: FactorColor.Guard,
		},
		{
			borderColor: FactorColor.Speed,
		},
	];

	constructor() {
		this.lineChartData = [
			{
				data: new Array(20).fill(0).map((_) => 80 + randomInt(0, 20)),
				label: 'Overall',
				fill: false,
				pointStyle: 'line',
			},
			{
				data: new Array(20).fill(0).map((_) => 80 + randomInt(0, 20)),
				label: 'Focus',
				fill: false,
				pointStyle: 'line',
			},
			{
				data: new Array(20).fill(0).map((_) => 80 + randomInt(0, 20)),
				label: 'Guard',
				fill: false,
				pointStyle: 'line',
			},
			{
				data: new Array(20).fill(0).map((_) => 80 + randomInt(0, 20)),
				label: 'Speed',
				fill: false,
				pointStyle: 'line',
			},
		];
	}

	ngOnInit() {}
}
