import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import {
	HttpClientModule,
	HttpClient,
	HttpInterceptor,
	HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Interceptor } from './interceptor';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppComponent } from './app.component';
import { AuthComponent } from './layout/auth/auth.component';
import { AdminComponent } from './layout/admin/admin.component';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';

import { AuthService } from './service/auth.service';
import { AnyService } from './service/any.service';
import { ApiService } from './service/api.service';
import { DataService } from './service/data.service';
import { FetchapiService } from './service/fetchapi.service';
import { MapService } from './service/map.service';
import { TripsService } from './service/trips.service';
import { DaterangeService } from './service/daterange.service';
import { GraphQLModule } from './graphql.module';
import { VideoService } from './service/video.service';
import { AlertService } from './service/alert.service';
import { ErrorInterceptor } from './error.interceptor';
import { AuthGuard } from './service/auth.guard';
import { LoginGuard } from './service/login.guard';
import { RealtimeAppsyncService } from './service/realtime-appsync.service';

import { MaterialModule } from './material.module';
import { ReportsService } from './service/reports.service';

import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { BlockUIModule } from 'primeng/blockui';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
// import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { CodeHighlighterModule } from 'primeng/codehighlighter';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
// import { EditorModule } from 'primeng/editor';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
// import { FullCalendarModule } from 'primeng/fullcalendar';
import { GalleriaModule } from 'primeng/galleria';
// import { GrowlModule } from 'primeng/growl';
import { InplaceModule } from 'primeng/inplace';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { LightboxModule } from 'primeng/lightbox';
import { ListboxModule } from 'primeng/listbox';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { OrderListModule } from 'primeng/orderlist';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PasswordModule } from 'primeng/password';
import { PickListModule } from 'primeng/picklist';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SlideMenuModule } from 'primeng/slidemenu';
import { SliderModule } from 'primeng/slider';
import { SpinnerModule } from 'primeng/spinner';
import { SplitButtonModule } from 'primeng/splitbutton';
import { StepsModule } from 'primeng/steps';
import { TabMenuModule } from 'primeng/tabmenu';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TerminalModule } from 'primeng/terminal';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { DialogService } from 'primeng/api';

import { AbmModule } from 'angular-baidu-maps';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// required for AOT compilation
import { Ng2OrderModule } from 'ng2-order-pipe'; //importing the module

import { HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';

import { DriverDetailPageComponent } from './pages/driver/driver-detail-page/driver-detail-page.component';
import { VehicleHistoryComponent } from './pages/vehicle/vehicle-history/vehicle-history.component';

import localeKo from '@angular/common/locales/ko';

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http);
}
import { DrivingStatusPageComponent } from './pages/driver/driving-status-page/driving-status-page.component';
import { DriverProfileComponent } from './pages/driver/driver-profile/driver-profile.component';
import { DrivingPerformanceComponent } from './pages/driver/driving-performance/driving-performance.component';
import { DrivingPerformanceSkorTabComponent } from './pages/driver/driving-performance-skor-tab/driving-performance-skor-tab.component';
import { DrivingPerformanceRiskFactorsTabComponent } from './pages/driver/driving-performance-risk-factors-tab/driving-performance-risk-factors-tab.component';
import { WideScoreHeaderComponent } from './pages/driver/wide-score-header/wide-score-header.component';
import { RentalHistoryPageComponent } from './pages/driver/rental-history-page/rental-history-page.component';
// import { NgxGaugeModule } from 'ngx-gauge';

import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';

export const MY_DATE_FORMATS: MatDateFormats = {
	parse: {
		dateInput: 'MM/DD/YYYY',
	},
	display: {
		dateInput: 'MM/DD/YYYY',
		monthYearLabel: 'MMMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};

const PrimengModules = [
	AccordionModule,
	AutoCompleteModule,
	BreadcrumbModule,
	BlockUIModule,
	ButtonModule,
	CalendarModule,
	CardModule,
	CarouselModule,
	// ChartModule,
	CheckboxModule,
	ChipsModule,
	CodeHighlighterModule,
	ConfirmDialogModule,
	ColorPickerModule,
	ContextMenuModule,
	DataViewModule,
	DialogModule,
	DropdownModule,
	DynamicDialogModule,
	// EditorModule,
	FieldsetModule,
	FileUploadModule,
	// FullCalendarModule,
	GalleriaModule,
	// GrowlModule,
	InplaceModule,
	InputMaskModule,
	InputSwitchModule,
	InputTextModule,
	InputTextareaModule,
	LightboxModule,
	ListboxModule,
	MegaMenuModule,
	MenuModule,
	MenubarModule,
	MessageModule,
	MessagesModule,
	MultiSelectModule,
	OrderListModule,
	OrganizationChartModule,
	OverlayPanelModule,
	PaginatorModule,
	PanelModule,
	PanelMenuModule,
	PasswordModule,
	PickListModule,
	ProgressBarModule,
	ProgressSpinnerModule,
	RadioButtonModule,
	RatingModule,
	ScrollPanelModule,
	SelectButtonModule,
	SlideMenuModule,
	SliderModule,
	SpinnerModule,
	SplitButtonModule,
	StepsModule,
	TableModule,
	TabMenuModule,
	TabViewModule,
	TerminalModule,
	TieredMenuModule,
	ToastModule,
	ToggleButtonModule,
	ToolbarModule,
	TooltipModule,
	TreeModule,
	TreeTableModule,
	VirtualScrollerModule,
];

registerLocaleData(localeKo);

@NgModule({
	declarations: [
		AppComponent,
		AuthComponent,

		AdminComponent,
		// SubComponent,
		// LoaderSpinnerComponent
		DriverDetailPageComponent,
		VehicleHistoryComponent,
	],
	entryComponents: [DriverDetailPageComponent, VehicleHistoryComponent],
	imports: [
		// NgxGaugeModule,
		MaterialModule,
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		SharedModule,
		Ng2OrderModule,
		Ng4LoadingSpinnerModule.forRoot(),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		GraphQLModule,
		HttpClientModule,
		FlexLayoutModule,
		FontAwesomeModule,
		...PrimengModules,
		AbmModule.forRoot({
			apiKey: 'uBb0dSOvF10tESRVlhvYjhUopYyFUXk2', // app key为必选项
		}),
	],
	providers: [
		RealtimeAppsyncService,
		AuthService,
		AnyService,
		ApiService,
		DataService,
		FetchapiService,
		MapService,
		TripsService,
		DaterangeService,
		VideoService,
		AlertService,
		AuthGuard,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ErrorInterceptor,
			multi: true,
		},
		{
			provide: LOCALE_ID,
			useValue: 'ko',
		},
		{ provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
		{ provide: MAT_DATE_LOCALE, useValue: 'ko-KR' },
		ReportsService,
		DialogService,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
