import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';

import * as Chart from 'chart.js';
import chartDataLabels from 'chartjs-plugin-datalabels';

@Component({
	selector: 'app-forward-collision-charts',
	templateUrl: './forward-collision-charts.component.html',
	styleUrls: ['./forward-collision-charts.component.scss'],
})

// 📌230104 리뉴얼 운전자 리포트 - 전방충돌 행동개선 pie chart
export class ForwardCollisionChartComponent implements OnInit, AfterViewInit {
	public chart: any;

	@Input() collisionPieChartData: any;
	@Input() collisionTotal: any;

	constructor() {}
	ngOnInit() {
		// setTimeout(() => {
		// }, 1000);
		this.forwardCollisionChart();
	}

	forwardCollisionChart() {
		if (!this.collisionTotal) {
			const data = [
				this.collisionPieChartData.collisionIgnore,
				this.collisionPieChartData.collisionRecover,
			];
			const chart = new Chart('forwardCollisionPie', {
				plugins: [chartDataLabels],
				type: 'pie',
				data: {
					// labels: ['경보무시', '행동개선'],
					datasets: [
						{
							data: data,
							borderWidth: 2,
							borderColor: 'rgb(255,255,255)',
							backgroundColor: ['rgb(235,99,112)', 'rgb(242,242,242)'],
							hoverBorderColor: 'rgb(255,255,255)',
							hoverBackgroundColor: ['rgb(235,99,112)', 'rgb(242,242,242)'],
							datalabels: {
								color: ['#fff', '#7f7f7f'],
								display: data.map((item: any, key: number) => {
									if (item < 10) {
										return false;
									} else {
										return true;
									}
								}),
							},
						},
					],
				},

				options: {
					animation: {
						duration: 0,
					},

					plugins: {
						datalabels: {
							anchor: 'center',
							align: 'center',
							// anchor: data[0] < 15 || data[1] < 15 ? 'end' : 'center',
							// align: data[0] < 15 || data[1] < 15 ? 'start' : 'center',

							font: {
								size: 20,
								weight: 700,
							},
							formatter: function (value, ctx) {
								return `${value}%`;
							},
						},
					},
					tooltips: {
						enabled: false,
					},

					layout: {
						padding: 20,
					},

					responsive: false,
					cutoutPercentage: 0,

					legend: {
						display: true,
						align: 'center',
						position: 'bottom',
						labels: {
							boxWidth: 20,
							usePointStyle: true,
							fontSize: 14,
							fontColor: '#000',
							fontStyle: 'normal',
							padding: 30,
						},
					},
					elements: {
						line: {
							fill: false,
						},
					},
				},
			});
		}
	}

	ngAfterViewInit() {}
}
