import {
	AfterViewInit,
	Component,
	Input,
	OnDestroy,
	OnInit,
	ViewChild,
	ChangeDetectorRef,
} from '@angular/core';

import * as Chart from 'chart.js';
import chartDataLabels from 'chartjs-plugin-datalabels';

@Component({
	selector: 'app-idle-ratio-multiple-charts',
	templateUrl: './idle-ratio-multiple-charts.component.html',
	styleUrls: ['./idle-ratio-multiple-charts.component.scss'],
})

// 📌230223 주행시간 대비 공회전 비율
export class IdleRatioMultipleChartComponent implements OnInit {
	public chart: any;

	constructor() {}

	ngOnInit() {
		this.createChart();
	}

	createChart() {
		const chart = new Chart('idleRatioChart', {
			plugins: [chartDataLabels],
			type: 'bar',
			data: {
				labels: ['전체노선', '동일노선', '운전자'],
				datasets: [
					{
						data: [13, 17, 23],
						backgroundColor: ['rgb(217,217,217)', 'rgb(191,191,191)', 'rgb(0,173,234)'],
						barPercentage: 0.5,
						order: 2,
						datalabels: {
							display: false,
						},
					},
					{
						type: 'line',
						data: [15, 18, 23],
						borderColor: 'rgb(255,192,0)',
						pointBorderWidth: 2,
						pointRadius: 8,
						pointBackgroundColor: 'rgb(255, 255, 255)',
						pointBorderColor: 'rgb(255,192,0)',
						fill: false,
						lineTension: 0,
						order: 1,
						datalabels: {
							display: false,
						},
						// yAxisID: 'line'
					},
				],
			},
			options: {
				plugins: {
					datalabels: {
						display: false,
					},
				},
				hover: {
					mode: null,
				},
				responsive: false,
				animation: {
					duration: 0,
				},
				tooltips: {
					enabled: false,
				},
				layout: {
					padding: 10,
				},
				legend: {
					display: false,
				},

				scales: {
					yAxes: [
						{
							display: true,
							gridLines: {
								display: true,
								drawBorder: false,
								lineWidth: 1,
								zeroLineWidth: 3,
								zeroLineColor: 'rgb(217,217,217)',
							},
							ticks: {
								display: true,
								beginAtZero: true,
								fontColor: '#000',
								fontFamily: 'Regular',
								fontStyle: '500',
								fontSize: 20,
								min: 0,
								padding: 20,
								stepSize: 5,
								callback(value, index, values) {
									return value + '%';
								},
							},
						},
						// {
						//   id: 'line',
						// }
					],
					xAxes: [
						{
							display: true,
							gridLines: {
								display: false,
							},
							ticks: {
								display: true,
								beginAtZero: true,
								fontColor: '#000',
								fontFamily: 'Regular',
								fontStyle: '500',
								fontSize: 20,
							},
						},
					],
				},
			},
		});
	}
}
