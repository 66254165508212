import {
	AfterViewInit,
	Component,
	Input,
	OnDestroy,
	OnInit,
	ViewChild,
	ChangeDetectorRef,
} from '@angular/core';

import * as Chart from 'chart.js';
import chartDataLabels from 'chartjs-plugin-datalabels';

@Component({
	selector: 'app-sudden-stop-multiple-charts',
	templateUrl: './sudden-stop-multiple-charts.component.html',
	styleUrls: ['./sudden-stop-multiple-charts.component.scss'],
})

// 📌230223 급정거
export class SuddenStopMultipleChartComponent implements OnInit {
	public chart: any;

	constructor() {}

	ngOnInit() {
		this.createChart();
	}

	createChart() {
		const chart = new Chart('suddenStopChart', {
			plugins: [chartDataLabels],
			type: 'bar',
			data: {
				labels: ['일반', '고위험'],
				datasets: [
					{
						data: [3, 2],
						backgroundColor: ['rgb(191,191,191)', 'rgb(235,99,112)'],
						barPercentage: 0.8,
						order: 2,
						datalabels: {
							display: false,
						},
					},
					{
						type: 'line',
						data: [2, 0],
						borderColor: 'rgb(118,113,113)',
						pointBorderWidth: 2,
						pointRadius: 8,
						pointBackgroundColor: 'rgb(255,255,255)',
						pointBorderColor: 'rgb(118,113,113)',
						fill: false,
						order: 1,
						datalabels: {
							display: false,
						},
						// yAxisID: 'line'
					},
				],
			},

			options: {
				plugins: {
					datalabels: {
						display: false,
					},
				},
				hover: {
					mode: null,
				},
				responsive: false,
				animation: {
					duration: 0,
				},
				tooltips: {
					enabled: false,
				},
				layout: {
					padding: {
						top: 14,
						right: 34,
						bottom: 0,
						left: 17,
					},
				},
				legend: {
					display: false,
				},

				scales: {
					yAxes: [
						{
							display: true,
							gridLines: {
								display: true,
								drawBorder: false,
								lineWidth: 1,
							},
							ticks: {
								display: false,
								beginAtZero: true,
								fontColor: '#000',
								fontFamily: 'Regular',
								fontStyle: '500',
								fontSize: 20,
								min: 0,
								max: 25, // 추후 수정
								padding: 10,
								stepSize: 5,
								maxTicksLimit: 6,
							},
						},
						// {
						//   id: 'line',
						// }
					],
					xAxes: [
						{
							display: true,
							gridLines: {
								display: false,
							},
							ticks: {
								display: true,
								beginAtZero: true,
								fontColor: '#000',
								fontFamily: 'Regular',
								fontStyle: '500',
								fontSize: 20,
							},
						},
					],
				},
			},
		});
	}
}
