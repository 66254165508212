import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { DaterangePickerComponent } from 'ng2-daterangepicker';
import { DaterangeService } from './../../service/daterange.service';
import * as moment from 'moment';

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss']
})
export class DateRangeComponent implements OnInit, AfterViewInit {
  public daterange: any = {
    start: Date.now(),
    end: Date.now(),
    label: ''
  };
  date = new Date( );
  startDate = moment(this.date.setUTCDate(this.date.getUTCDate() - 6)).format('YYYY-MM-DD HH:mm:ss.SSS');
  endDate = moment(this.date.setUTCDate(this.date.getUTCDate() + 6)).format('YYYY-MM-DD HH:mm:ss.SSS');
  @ViewChild(DaterangePickerComponent)
  private picker: DaterangePickerComponent;
  public options: any = {
    locale: { format: 'YYYY-MM-DD' },
    alwaysShowCalendars: false,
    'startDate': this.startDate,
    'endDate': this.endDate,
    ranges: {
      'Today': [moment(), moment()],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(30, 'days'), moment()],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    }
  };
  constructor(
    private drs: DaterangeService
  ) { }

  ngOnInit() {
    const date = new Date(  );
    const startDate = moment(date.setUTCDate(date.getUTCDate() - 6)).format('YYYY-MM-DD HH:mm:ss.SSS');
    const endDate = moment(date.setUTCDate(date.getUTCDate() + 6)).format('YYYY-MM-DD HH:mm:ss.SSS');
    this.daterange.start = startDate;
    this.daterange.end = endDate;
    // const timePeriodText = this.drs.getTimePeriod(this.daterange.start, this.daterange.end);
    // this.drs.timePeriodText.next(timePeriodText);
    const dataSelect = {startDate: this.daterange.start, endDate: this.daterange.end};
    this.drs.dateRangeSelectShared.subscribe((dateRange)=>{
      this.daterange.start = dateRange.startDate;
      this.daterange.end = dateRange.endDate;
    })
    
  }
  ngAfterViewInit() {
    this.picker.datePicker.setStartDate(this.daterange.start);
    this.picker.datePicker.setEndDate(this.daterange.end);
    // const timePeriodText = this.drs.getTimePeriod(this.daterange.start, this.daterange.end);
    // this.drs.timePeriodText.next(timePeriodText);
  }

  public selectedDate(value: any) {
    console.log('selectedDate value utc ',value);;
    // value.start = moment(value.start).add(moment(value.start).utcOffset(), 'm').utc().format()
    // value.end = moment(value.end).add(moment(value.end).utcOffset(), 'm').utc().format()
    this.daterange.start = value.start;
    this.daterange.end = value.end;
    const dataSelect = {startDate: value.start, endDate: value.end};
    this.drs.dateRangeSelectShared.next(dataSelect);
    this.drs.dateLabelSelect.next(value.label);
  }

  public applyDate(e: any) {
    // console.log('applyDate');
    // const startDate = moment(this.daterange.start).format('YYYY-MM-DD');
    // const endDate = moment(this.daterange.end).format('YYYY-MM-DD');
    // const dataSelect = {startDate: startDate, endDate: endDate};
    // this.drs.dateRangeSelectShared.next(dataSelect);
    //this.drs.dateLabelSelect.next(e.picker.chosenLabel);
    // const timePeriodText = this.drs.getTimePeriod(startDate, endDate);
    // this.drs.timePeriodText.next(timePeriodText);
  }
}
