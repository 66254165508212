// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	googleApiToken: 'AIzaSyBfhRvriBivOiyQHIBWVHaFXPSj1v0sfGo',
	POOL_ID: 'ap-northeast-2_jxVEGOoKM', // 'us-west-2_zWzup9KDd',
	APP_CLIENT_ID: '1r7irb0nc97h3c6hqn6qkq0ak2', // 'ct1qddl4er416pvic42rq4gvn',
	apiEndPointURl: 'https://m5td7wewm0.execute-api.us-west-2.amazonaws.com/dev/v1',
	signInuserpool: 'https://ky02s0je76.execute-api.ap-northeast-2.amazonaws.com/dev/v1/userpool',

	// graphqlUrl: 'https://urbf37qoad.execute-api.us-west-2.amazonaws.com/dev/graphql',
	// graphqlUrl: 'https://cpzis8pyaa.execute-api.us-west-2.amazonaws.com/dev/graphql',

	dynamoDBUrl: 'https://5qy4trxsrb.execute-api.ap-northeast-2.amazonaws.com/api',
	// realTimeUrl: 'https://5qy4trxsrb.execute-api.ap-northeast-2.amazonaws.com/api',
	getAddressUrl: 'https://jv17oiv64f.execute-api.us-west-2.amazonaws.com/api',

	getdriverstats: 'https://9ibms2pg7c.execute-api.us-west-2.amazonaws.com/testing/v1/driverstats',
	// getdriverlist:'https://dw5xf1qym4.execute-api.us-west-2.amazonaws.com/staging/v2',
	getdriverlist: 'https://rrt3bp73i2.execute-api.ap-northeast-2.amazonaws.com/dev/v2',

	//trips
	getTripsDetailsListNew: 'https://akii4x3c0k.execute-api.us-west-2.amazonaws.com/staging/v1',
	getTripsLocationList: 'https://akii4x3c0k.execute-api.us-west-2.amazonaws.com/staging/v1/trips',

	// env
	graphqlUrl: 'https://rrt3bp73i2.execute-api.ap-northeast-2.amazonaws.com/dev/v2/graphql',
	apiCustomDateGraphqlURL:
		'https://6adlwdvv35.execute-api.us-east-1.amazonaws.com/staging/v1/getreports',

	getCameraStatusByIDUrl:
		'https://0jc8i9gal9.execute-api.ap-northeast-2.amazonaws.com/prod/v1/vdo/status',

	// 위에것 대체 (getCameraStatusByIDUrl 대체)
	getVideoUrl:
		'https://0jc8i9gal9.execute-api.ap-northeast-2.amazonaws.com/prod/v1/vdo/getvideourl',

	getCameraAlertByIDUrl:
		'https://0jc8i9gal9.execute-api.ap-northeast-2.amazonaws.com/prod/v1/vdo/alert',
	// getCameraStatusByIDUrl:'https://api-dev-ap-northeast-2.aws.getcarvi.com/vod/v1/status',
	// getCameraAlertByIDUrl:'https://api-dev-ap-northeast-2.aws.getcarvi.com/vod/v1/vod/alert',
	reportDownloadUrl: 'https://38izv5yoll.execute-api.us-west-2.amazonaws.com/dev/pdfreports',

	//working for dev only
	apiAlertEventList:
		'https://4gfi9z3yd0.execute-api.ap-northeast-2.amazonaws.com/stage/v1/getevents',

	apiCreateDevice: 'https://znlqfqvpfc.execute-api.us-east-1.amazonaws.com/dev/services',

	heremapxmlapi: 'https://d9arywszm0.execute-api.us-west-2.amazonaws.com/staging/maps/routes',

	// REAL_TIME_APPSYNC_REGION: 'us-east-1',
	// REAL_TIME_APPSYNC_ENDPOINT: 'https://kuozmdn34nc5nmu72eytv4pzgm.appsync-api.us-east-1.amazonaws.com/graphql',
	REAL_TIME_APPSYNC_REGION: 'ap-northeast-2',
	REAL_TIME_APPSYNC_ENDPOINT:
		'https://f6ovbvasr5bt7ogpplo3mmchua.appsync-api.ap-northeast-2.amazonaws.com/graphql',
	REAL_TIME_APPSYNC_AWS_ACCESS_KEY_ID: 'AKIATAZCH7ENX5AZQFO6',
	REAL_TIME_APPSYNC_AWS_SECRET_ACCESS_KEY: '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',
	REAL_TIME_APPSYNC_ENDPOINT_DATA:
		'https://b9fcxuq7i3.execute-api.ap-northeast-2.amazonaws.com/prod/v1/realtime',

	getAllVideoApiUrl: 'https://0jc8i9gal9.execute-api.ap-northeast-2.amazonaws.com/prod/v1/vdo/list',
	getAllVideoApiUrl2:
		'https://0jc8i9gal9.execute-api.ap-northeast-2.amazonaws.com/prod/v1/vdo/requestlist',

	//vehicle
	getVehicleList: 'https://nxvw6jpec6.execute-api.ap-northeast-2.amazonaws.com/dev/list',
	getVehicleStatus: 'https://nxvw6jpec6.execute-api.ap-northeast-2.amazonaws.com/dev/status',
	getVehicleRentalList:
		'https://nxvw6jpec6.execute-api.ap-northeast-2.amazonaws.com/dev/list/rental',
	getVehicleSearch: 'https://nxvw6jpec6.execute-api.ap-northeast-2.amazonaws.com/dev/vehiclesearch',

	activeDeviceList:
		'https://231kbbpl9i.execute-api.ap-northeast-2.amazonaws.com/dev/activeDeviceList',
	driverAllList: 'https://231kbbpl9i.execute-api.ap-northeast-2.amazonaws.com/dev/driverlist',
	getDriverInfo: 'https://231kbbpl9i.execute-api.ap-northeast-2.amazonaws.com/dev/driverInfo',
	getdriverSearch: 'https://231kbbpl9i.execute-api.ap-northeast-2.amazonaws.com/dev/driverSearch',

	//이벤트 알러트 쿼리 url
	evnetQueryAPI: 'https://4gfi9z3yd0.execute-api.ap-northeast-2.amazonaws.com/stage/v1/getevents',

	// company recent event list for noti
	recentEventNoti:
		'https://4gfi9z3yd0.execute-api.ap-northeast-2.amazonaws.com/stage/v1/getrecentevents',

	// trips 경로 리스트
	tripList: 'https://5n3x9h9vx7.execute-api.ap-northeast-2.amazonaws.com/dev/v1/alltripslist',
	production: false,

	//report
	reportApi: 'https://p0ji7rve4h.execute-api.ap-northeast-2.amazonaws.com/dev/v1/report',
	trips: 'https://5n3x9h9vx7.execute-api.ap-northeast-2.amazonaws.com/dev',

	// aws s3 file upload
	S3_REGION: 'ap-northeast-2',
	S3_AWS_ACCESS_KEY_ID: 'AKIATAZCH7ENRSR3VL5H',
	S3_AWS_SECRET_ACCESS_KEY: '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',
	S3_AWS_BUCKET_NAME: 'carvi-master-apnortheast2-daily-dispatch',
	// S3_AWS_ACCESS_KEY_ID: 'AKIATAZCH7EN52RJWQWB',
	// S3_AWS_SECRET_ACCESS_KEY: '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',
	// S3_AWS_BUCKET_NAME: 'carvi-master-apnortheast2-daily-dispatch',
	// S3_AWS_BUCKET_KEY: '',

	// notice AWS s3 file upload
	NOTICE_S3_AWS_BUCKET_NAME: 'company-notice',

	// 운행관리
	getDispatchListUrl:
		'https://skqjyh891k.execute-api.ap-northeast-2.amazonaws.com/dev/get_dispatch_list',
	getDriverListUrl:
		'https://skqjyh891k.execute-api.ap-northeast-2.amazonaws.com/dev/get_driver_list',

	// 안전운전리포트
	getReportMonthlyDataUrl:
		'https://lqv57v7ls1.execute-api.ap-northeast-2.amazonaws.com/dev/driver/get-monthly-data',
	getCompanyReportMonthlyDataUrl:
		'https://lqv57v7ls1.execute-api.ap-northeast-2.amazonaws.com/dev/company/get-monthly-data',
	getCompanyDriversDataUrl:
		'https://lqv57v7ls1.execute-api.ap-northeast-2.amazonaws.com/dev/company/get-drivers-data',
	getReportInfoUrl:
		'https://lqv57v7ls1.execute-api.ap-northeast-2.amazonaws.com/dev/company/get-report-info',
	getReportDriverListUrl:
		'https://lqv57v7ls1.execute-api.ap-northeast-2.amazonaws.com/dev/company/get-drivers',
	getIndustryReportUrl:
		'https://lqv57v7ls1.execute-api.ap-northeast-2.amazonaws.com/dev/industry/get-monthly-data',
	getCrashDetectedDataUrl:
		'https://lqv57v7ls1.execute-api.ap-northeast-2.amazonaws.com/dev/company/get-monthly-event',
	downloadReportFileUrl:
		'https://lqv57v7ls1.execute-api.ap-northeast-2.amazonaws.com/dev/company/get-report-url',
	downloadReportListUrl:
		'https://lqv57v7ls1.execute-api.ap-northeast-2.amazonaws.com/dev/company/get-report-list',

	// 연비 리포트
	getCompanyMonthlyDataUrl:
		'https://lqv57v7ls1.execute-api.ap-northeast-2.amazonaws.com/dev/fei/company/get-monthly-data',
	getCompanyDailyDataUrl:
		'https://lqv57v7ls1.execute-api.ap-northeast-2.amazonaws.com/dev/fei/company/get-daily-data',
	getDriverMonthlyDataUrl:
		'https://lqv57v7ls1.execute-api.ap-northeast-2.amazonaws.com/dev/fei/driver/get-monthly-data',
	getDriverDailyDataUrl:
		'https://lqv57v7ls1.execute-api.ap-northeast-2.amazonaws.com/dev/fei/driver/get-daily-data',

	getCompanyDriverListUrl:
		'https://lqv57v7ls1.execute-api.ap-northeast-2.amazonaws.com/dev/dash/get-drivers',

	// 운전자 상세
	getDailyDriverDataUrl:
		'https://lqv57v7ls1.execute-api.ap-northeast-2.amazonaws.com/dev/dash/get-driver-data',
	getDriverRentalListUrl:
		'https://lqv57v7ls1.execute-api.ap-northeast-2.amazonaws.com/dev/dash/get-driver-data',

	// 주행 조회
	getRangeTripsUrl:
		'https://4gfi9z3yd0.execute-api.ap-northeast-2.amazonaws.com/stage/v1/getrangetrips',
	getRangeTripsOldUrl:
		'https://4gfi9z3yd0.execute-api.ap-northeast-2.amazonaws.com/stage/v1/getrangetrips_old',

	// 리포트 메뉴
	getMonthlyDriverDataUrl:
		'https://lqv57v7ls1.execute-api.ap-northeast-2.amazonaws.com/dev/dash/get-driver-data',
	getDriverCumulativDataUrl:
		'https://lqv57v7ls1.execute-api.ap-northeast-2.amazonaws.com/dev/dash/get-driver-data',
	getDriverMileageDataUrl:
		'https://lqv57v7ls1.execute-api.ap-northeast-2.amazonaws.com/dev/dash/get-driver-daily-data',
	getDriverRecentTripsUrl:
		'https://4gfi9z3yd0.execute-api.ap-northeast-2.amazonaws.com/stage/v1/getrecenttrips',
	getDriverRiskListUrl:
		'https://lqv57v7ls1.execute-api.ap-northeast-2.amazonaws.com/dev/dash/get-risk-list',
	getCumulRiskListUrl:
		'https://lqv57v7ls1.execute-api.ap-northeast-2.amazonaws.com/dev/dash/get-risk-list',
	getDriverEventListUrl:
		'https://lqv57v7ls1.execute-api.ap-northeast-2.amazonaws.com/dev/dash/get-event-list',
	getCumulEventListUrl:
		'https://lqv57v7ls1.execute-api.ap-northeast-2.amazonaws.com/dev/dash/get-event-list',
	getDriverStatUrl:
		'https://p0ji7rve4h.execute-api.ap-northeast-2.amazonaws.com/dev/v1/report/company/driverstat2',
	getCompanyCumulDataUrl:
		'https://lqv57v7ls1.execute-api.ap-northeast-2.amazonaws.com/dev/dash/get-company-cumul-data',

	// 안전운전리포트 3개월 데이터
	getPrev3MonthDriversDataUrl:
		'https://lqv57v7ls1.execute-api.ap-northeast-2.amazonaws.com/dev/company/get-drivers-pre-3month-data',
	getPrev3MonthCompanyDataUrl:
		'https://lqv57v7ls1.execute-api.ap-northeast-2.amazonaws.com/dev/company/get-industry-pre-3month-data',

	// 공회전
	getIdleListUrl:
		'https://ajrjv9jxi8.execute-api.ap-northeast-2.amazonaws.com/dev/company/get-idle-list',
	setNotiStatusUrl:
		'https://ajrjv9jxi8.execute-api.ap-northeast-2.amazonaws.com/dev/company/set_idle_status',
	sendSMSUrl: 'https://ajrjv9jxi8.execute-api.ap-northeast-2.amazonaws.com/dev/company/send-sms',
	downloadIdleReportsUrl:
		'https://ajrjv9jxi8.execute-api.ap-northeast-2.amazonaws.com/dev/company/get-idle-report-url',

	// 도로교통 데이터 분석
	getDongRoadUrl:
		'https://v0eo2lhan4.execute-api.ap-northeast-2.amazonaws.com/dev/get-dong-road-list',
	getDongBasicCodeUrl:
		'https://v0eo2lhan4.execute-api.ap-northeast-2.amazonaws.com/dev/get-dong-basic-codes',
	getRoadEventUrl: 'https://v0eo2lhan4.execute-api.ap-northeast-2.amazonaws.com/dev/get-road-event',
	getBasicRiskListUrl:
		'https://v0eo2lhan4.execute-api.ap-northeast-2.amazonaws.com/dev/get-basic-risk-list',
	updateBasicStatusUrl:
		'https://v0eo2lhan4.execute-api.ap-northeast-2.amazonaws.com/dev/update-basic-info',
	createMemoUrl:
		'https://v0eo2lhan4.execute-api.ap-northeast-2.amazonaws.com/dev/update-basic-info',
	updateMemoUrl:
		'https://v0eo2lhan4.execute-api.ap-northeast-2.amazonaws.com/dev/update-basic-info',
	deleteMemoUrl:
		'https://v0eo2lhan4.execute-api.ap-northeast-2.amazonaws.com/dev/update-basic-info',
	getBasicSkorListUrl:
		'https://v0eo2lhan4.execute-api.ap-northeast-2.amazonaws.com/dev/get-basic-skor-list',
	roadInfoCreateMemoUrl:
		'https://v0eo2lhan4.execute-api.ap-northeast-2.amazonaws.com/dev/update-road-info',
	roadInfoUpdateMemoUrl:
		'https://v0eo2lhan4.execute-api.ap-northeast-2.amazonaws.com/dev/update-road-info',
	roadInfoDeleteMemoUrl:
		'https://v0eo2lhan4.execute-api.ap-northeast-2.amazonaws.com/dev/update-road-info',
	getLimitBasicSkorListUrl:
		'https://v0eo2lhan4.execute-api.ap-northeast-2.amazonaws.com/dev/get-basic-skor-list',
	getLimitEmdListUrl:
		'https://v0eo2lhan4.execute-api.ap-northeast-2.amazonaws.com/dev/get-basic-skor-list',
	basicRoadCreateMemoUrl:
		'https://v0eo2lhan4.execute-api.ap-northeast-2.amazonaws.com/dev/update-basic-memo',
	basicRoadUpdateMemoUrl:
		'https://v0eo2lhan4.execute-api.ap-northeast-2.amazonaws.com/dev/update-basic-memo',
	basicRoadDeleteMemoUrl:
		'https://v0eo2lhan4.execute-api.ap-northeast-2.amazonaws.com/dev/update-basic-memo',
	getRoadDataVersionUrl:
		'https://v0eo2lhan4.execute-api.ap-northeast-2.amazonaws.com/dev/get-data-version',
	setAndongRoadDataUrl:
		'https://v0eo2lhan4.execute-api.ap-northeast-2.amazonaws.com/dev/get-basic-skor-list',

	// 내부 이슈 리스트
	getCarviIssueListUrl:
		'https://78pif797fj.execute-api.ap-northeast-2.amazonaws.com/dev/abnormal-mgmt/all',

	notiConfirmUrl:
		'https://231kbbpl9i.execute-api.ap-northeast-2.amazonaws.com/dev/eventnoticonfirm',

	getDistributionsUrl:
		'https://231kbbpl9i.execute-api.ap-northeast-2.amazonaws.com/dev/distributions',

	crashRemoveUrl: 'https://231kbbpl9i.execute-api.ap-northeast-2.amazonaws.com/dev/crashremove',

	searchReportUrl: 'https://78pif797fj.execute-api.ap-northeast-2.amazonaws.com/dev/search-reports',

	companySkorCumulativeStatUrl:
		'https://p0ji7rve4h.execute-api.ap-northeast-2.amazonaws.com/dev/v1/score/cumulative',

	companySkorStatUrl: 'https://p0ji7rve4h.execute-api.ap-northeast-2.amazonaws.com/dev/v1/score',

	companySkorUrl: 'https://p0ji7rve4h.execute-api.ap-northeast-2.amazonaws.com/dev/v1/score',

	overviewAvgUrl:
		'https://p0ji7rve4h.execute-api.ap-northeast-2.amazonaws.com/dev/v1/state/overview',

	getRecentTripsUrl:
		'https://4gfi9z3yd0.execute-api.ap-northeast-2.amazonaws.com/stage/v1/getrecenttrips/',

	getNotiListUrl:
		'https://4gfi9z3yd0.execute-api.ap-northeast-2.amazonaws.com/stage/v1/getrecentevents',

	getTripLatLonUrl: 'https://231kbbpl9i.execute-api.ap-northeast-2.amazonaws.com/dev/latlon',

	tripapiUrl: 'https://231kbbpl9i.execute-api.ap-northeast-2.amazonaws.com/dev/eventlist/',

	getAddressUrl2: 'https://231kbbpl9i.execute-api.ap-northeast-2.amazonaws.com/dev/location',

	vehicleReturnUrl:
		'https://bhc5p6b0d7.execute-api.ap-northeast-2.amazonaws.com/dev/dispatch/return',

	shutdownStartUrl:
		'https://bhc5p6b0d7.execute-api.ap-northeast-2.amazonaws.com/dev/dispatch/block',

	vehiclesRegisterUrl:
		'https://bhc5p6b0d7.execute-api.ap-northeast-2.amazonaws.com/dev/vehicle/register',

	dispatchRegisterUrl:
		'https://bhc5p6b0d7.execute-api.ap-northeast-2.amazonaws.com/dev/dispatch/register',

	dispatchDetailUrl:
		'https://bhc5p6b0d7.execute-api.ap-northeast-2.amazonaws.com/dev/dispatch/detail',

	dispatchListVnoUrl:
		'https://bhc5p6b0d7.execute-api.ap-northeast-2.amazonaws.com/dev/dispatch/list_vno/',

	dispatchListUrl: 'https://bhc5p6b0d7.execute-api.ap-northeast-2.amazonaws.com/dev/dispatch/list/',

	mypageMonthlyFeeUrl:
		'https://bhc5p6b0d7.execute-api.ap-northeast-2.amazonaws.com/dev/mypage/serviceplan/monthlyfee/',

	mypageServiceplanUrl:
		'https://bhc5p6b0d7.execute-api.ap-northeast-2.amazonaws.com/dev/mypage/serviceplan/info/',

	mypageMyaccountUrl:
		'https://bhc5p6b0d7.execute-api.ap-northeast-2.amazonaws.com/dev/mypage/account/',

	requestDeviceVideoUrl:
		'https://lae4wlaoka.execute-api.ap-northeast-2.amazonaws.com/dev/video/request',

	sendEventConfirmUrl: 'https://s.aws.getcarvi.com/di/confirm',

	getDrivingPerformanceUrl:
		'https://231kbbpl9i.execute-api.ap-northeast-2.amazonaws.com/dev/driverPerformence',

	getRentalListBetweenUrl:
		'https://231kbbpl9i.execute-api.ap-northeast-2.amazonaws.com/dev/rentlist',
	dispatchFileListUrl:
		'https://skqjyh891k.execute-api.ap-northeast-2.amazonaws.com/dev/get-daily-dispatch-list',
	getRegisteredDriverListUrl:
		'https://ifijsu5txd.execute-api.ap-northeast-2.amazonaws.com/dev/driver/get-list',
	addDriverListUrl:
		'https://ifijsu5txd.execute-api.ap-northeast-2.amazonaws.com/dev/driver/add-list',
	deleteDriversUrl: 'https://ifijsu5txd.execute-api.ap-northeast-2.amazonaws.com/dev/driver/expire',
	getGarageInfoUrl:
		'https://78pif797fj.execute-api.ap-northeast-2.amazonaws.com/dev/get-garage-info',
	getVehicleHistoryUrl:
		'https://gqndzvvpsa.execute-api.ap-northeast-2.amazonaws.com/dev/get-company-vehicle-info',
	updateVehicleInfoUrl:
		'https://gqndzvvpsa.execute-api.ap-northeast-2.amazonaws.com/dev/update-company-vehicle-info/',
	requestCompanyActionUrl:
		'https://gqndzvvpsa.execute-api.ap-northeast-2.amazonaws.com/dev/request-company-vehicle-action',
	getVocListUrl:
		'https://gqndzvvpsa.execute-api.ap-northeast-2.amazonaws.com/dev/request-company-vehicle-action-list',
	updateCompanyActionUrl:
		'https://gqndzvvpsa.execute-api.ap-northeast-2.amazonaws.com/dev/update-company-vehicle-action',

	// 안동시청 민원관리
	updateComplaintMemoUrl:
		'https://v0eo2lhan4.execute-api.ap-northeast-2.amazonaws.com/dev/update-mw-item',
	updateComplaintStatusUrl:
		'https://v0eo2lhan4.execute-api.ap-northeast-2.amazonaws.com/dev/update-mw-item',
	createComplaintUrl:
		'https://v0eo2lhan4.execute-api.ap-northeast-2.amazonaws.com/dev/update-mw-item',
	updateMwProcessInfoUrl:
		'https://v0eo2lhan4.execute-api.ap-northeast-2.amazonaws.com/dev/update-mw-item',

	// 스쿨존 알림 서비스
	getSchoolZoneEventListUrl:
		'https://3xt5cqomml.execute-api.ap-northeast-2.amazonaws.com/dev/school-zone/get-event-list',
	updateSchoolZoneEventUrl:
		'https://78pif797fj.execute-api.ap-northeast-2.amazonaws.com/dev/schoolzone-check',

	// 공지사항
	createNoticeUrl: 'https://6v0g4f71mc.execute-api.ap-northeast-2.amazonaws.com/dev/notice/update',
	getNoticeListUrl: 'https://6v0g4f71mc.execute-api.ap-northeast-2.amazonaws.com/dev/notice/list',
	downloadAttachmentUrl:
		'https://6v0g4f71mc.execute-api.ap-northeast-2.amazonaws.com/dev/notice/get-file-url',
	deleteAttachmentUrl:
		'https://6v0g4f71mc.execute-api.ap-northeast-2.amazonaws.com/dev/notice/remove-file',
};

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
