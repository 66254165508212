import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import {
	ChartComponent,
	ApexAxisChartSeries,
	ApexChart,
	ApexTitleSubtitle,
	ApexXAxis,
	ApexNonAxisChartSeries,
	ApexPlotOptions,
	ApexAnnotations,
	ApexStroke,
	ApexFill,
	ApexTooltip,
	ApexResponsive,
	ApexYAxis,
	ApexGrid,
	ApexStates,
	ApexTheme,
	ApexDataLabels,
	ApexLegend,
} from 'ng-apexcharts';
import { DataService } from 'src/app/service/data.service';

export type ChartOptions = {
	series: ApexAxisChartSeries | ApexNonAxisChartSeries;
	chart: ApexChart;
	xaxis: ApexXAxis;
	yaxis: ApexYAxis | ApexYAxis[];
	title: ApexTitleSubtitle;
	colors: string[];
	dataLabels: ApexDataLabels;
	plotOptions: ApexPlotOptions;
	annotations: ApexAnnotations;
	stroke: ApexStroke;
	labels: string[];
	fill: ApexFill;
	tooltip: ApexTooltip;
	responsive: ApexResponsive[];
	grid: ApexGrid;
	states: ApexStates;
	subtitle: ApexTitleSubtitle;
	theme: ApexTheme;
	legend: ApexLegend;
};

@Component({
	selector: 'app-fei-overall-bar-charts',
	templateUrl: './fei-overall-bar-charts.component.html',
	styleUrls: ['./fei-overall-bar-charts.component.scss'],
})

// 📌230222 연비 - 종합 bar
export class FeiOverallBarChartsComponent implements OnInit {
	@ViewChild('charts') charts: ChartComponent;
	public chartOptions: Partial<ChartOptions>;

	@Input() chart: ApexChart;
	@Input() annotations: ApexAnnotations;
	@Input() colors: string[];
	@Input() dataLabels: ApexDataLabels;
	@Input() series: ApexAxisChartSeries | ApexNonAxisChartSeries;
	@Input() stroke: ApexStroke;
	@Input() labels: string[];
	@Input() legend: ApexLegend;
	@Input() fill: ApexFill;
	@Input() tooltip: ApexTooltip;
	@Input() plotOptions: ApexPlotOptions;
	@Input() responsive: ApexResponsive[];
	@Input() xaxis: ApexXAxis;
	@Input() yaxis: ApexYAxis | ApexYAxis[];
	@Input() grid: ApexGrid;
	@Input() states: ApexStates;
	@Input() title: ApexTitleSubtitle;
	@Input() subtitle: ApexTitleSubtitle;
	@Input() theme: ApexTheme;

	@Input() chartTitle: string;

	constructor(private ds: DataService, private changeRef: ChangeDetectorRef) {}

	ngOnInit() {
		this.createChart();
	}

	createChart() {
		this.chartOptions = {
			series: [
				{
					data:
						this.chartTitle === '공회전'
							? [65.0, 62.9]
							: this.chartTitle === '연비주행'
							? [77.8, 72.4]
							: [0, 0],
				},
			],
			states: {
				hover: {
					filter: {
						type: 'none',
					},
				},
			},
			tooltip: {
				enabled: false,
			},
			chart: {
				width: this.chartTitle === '공회전' ? 660 : 680,
				height: 120,
				type: 'bar',
				offsetX: 0,
				offsetY: 0,
				toolbar: {
					show: false,
				},
				animations: {
					enabled: false,
				},
			},
			plotOptions: {
				bar: {
					horizontal: true,
					borderRadius: 12,
					barHeight: '74%',
					distributed: true,
					dataLabels: {
						position: 'bottom',
					},
					colors: {
						backgroundBarColors: ['#d9d9d9'],
						backgroundBarOpacity: 1,
						backgroundBarRadius: 12,
					},
				},
			},
			legend: {
				show: false,
			},
			colors: ['#01b1ee', '#bfbfbf'],

			dataLabels: {
				enabled: true,
				textAnchor: 'start',
				offsetX: 16,
				style: {
					fontSize: '18px',
					fontWeight: '400',
					colors: ['#fff'],
				},
			},
			grid: {
				show: false,
				position: 'back',
				xaxis: {
					lines: {
						show: false,
					},
				},
				yaxis: {
					lines: {
						show: false,
					},
				},
			},
			xaxis: {
				max: 100,
				categories: [this.chartTitle],
				labels: {
					show: false,
				},
				axisTicks: {
					show: false,
				},
			},
			yaxis: {
				show: true,

				max: 100,
				labels: {
					show: true,
					style: {
						fontSize: '20px',
						fontWeight: 'bold',
					},
					offsetX: this.chartTitle === '공회전' ? -12 : 0,
				},
			},
		};
	}
}
