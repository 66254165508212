import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-average-reports',
  templateUrl: './average-reports.component.html',
  styleUrls: ['./average-reports.component.scss'],
  
})
export class AverageReportsComponent implements OnInit {

  @Input() averageValue = 0;
  differenceValue: number
  arrowUpForce = false;
  @Input() set differenceValueSet(value) {
    this.differenceValue = value;  
    if(value==0 || value==null || value=='null'){
    } else if (value) {
        if (value.toString().indexOf('.') > 0) {
          if(parseFloat(value.toString()).toFixed(2) == '-0.00') {
            this.arrowUpForce = true;
          }
        }
    }
  };
  @Input() fontSize: string;
  @Input() percent = false;
  @Input() plusAfterAverage = false;
  @Input() arrow = true;
  @Input() arrowUp = false;
  @Input() color = '';
  
  constructor() { }

  ngOnInit() {
  }

  getColor(color: string): string {
    if(this.arrowUpForce) {
      return 'rgb(110, 194, 96)'
    }
      if (color === 'grey') { return '#909090'; }
    return color === 'green' ? '#6EC260' : '#FF004E';
  }

}
