import {
	Component,
	OnInit,
	Input,
	EventEmitter,
	Output,
	ChangeDetectorRef,
	OnDestroy,
} from '@angular/core';
import { VideoEntryBundle } from '../../types/data-types';
import { DateFormats } from '../../uicommon';
import { DataService } from '../../service/data.service';
import { TripsService } from '../../service/trips.service';
import { filter, switchMap, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { CustomTranslateService } from '../../service/customTranslate.service';
import { AlertService } from './../../service/alert.service';

@Component({
	selector: 'app-video-play-popup',
	templateUrl: './video-play-popup.component.html',
	styleUrls: ['./video-play-popup.component.scss'],
})
export class VideoPlayPopupComponent implements OnInit, OnDestroy {
	DateFormats = DateFormats;
	video: VideoEntryBundle;
	isEvent: Boolean;
	isDualVideo: Boolean;

	lang = '';
	transObj = {};

	playButton = false;
	noData = false;

	activeButton = false;
	noActive = false;

	@Input() videoType: any;

	@Input('video')
	set setvideo(video: VideoEntryBundle) {
		if (video['location'] == '0.00000,0.00000' || video['location'] == '0.000000,0.000000') {
			video['geocode']['kr'] = { address: '위치정보없음' };
		}

		if (video['explained'] != null) {
			// overview page 에서는 이미 json 형태이지만, 그 외에는 json parsing 해줌.
			try {
				video['explained'] = JSON.parse(video['explained']);
			} catch (e) {
				// 이미 json 형태임.
			}
		}

		if (video['video_file'] != null) {
			if (
				video['video_file'].indexOf('E1.mp4') !== -1 ||
				video['video_file'].indexOf('E2.mp4') !== -1
			) {
				if (video['event'] != null) {
					this.isEvent = true;
				} else {
					this.isEvent = false;
				}
			} else {
				this.isEvent = false;
			}
			if (
				video['video_file'].indexOf('E2.mp4') !== -1 ||
				video['video_file'].indexOf('I2.mp4') !== -1
			) {
				this.isDualVideo = true;
			} else {
				this.isDualVideo = false;
			}

			if (video['EUPMYEONDONG_CODE'] && video['ROAD_CODE']) {
				video['eup_road_name'] = `경상북도 안동시 ${video['EUPMYEONDONG']}`;
			}
		}

		this.video = video;
		if (this.video['video_status']) {
			console.log(this.video);
		}
		if (!!this.video && !!this.video.url) {
			this.tripsService.setTripVideoTrigger2(this.video);
		}

		setTimeout(() => {
			if (this.video && this.video['company_id']) {
				if (this.video.url) {
					this.playButton = true;
					this.noData = false;
				} else {
					this.noData = true;
					this.playButton = false;
				}
			}
		}, 800);

		setTimeout(() => {
			if (this.video['url'] && this.video['url2']) {
				this.activeButton = true;
				this.noActive = false;
			} else {
				this.noActive = true;
				this.activeButton = false;
			}
		}, 800);
	}
	@Output()
	close = new EventEmitter();
	subs = new Subscription();
	langKey: string;
	constructor(
		private ds: DataService,
		private cf: ChangeDetectorRef,
		private tripsService: TripsService,
		private customTranslateService: CustomTranslateService,
		public alertService: AlertService
	) {}
	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	ngOnInit() {
		this.subs.add(
			this.customTranslateService
				.getLang()
				.pipe(
					switchMap((value) => {
						const defaultLang = localStorage.getItem('defaultLng');
						if (!!this.lang) {
							if (this.lang !== defaultLang) {
							}
						}

						this.lang = value ? value : 'ko';
						this.langKey = value ? value : 'ko';
						return this.customTranslateService.sendJson(this.lang);
					})
				)
				.subscribe((value) => {
					this.transObj = value;
				})
		);

		this.subs.add(
			this.tripsService
				.getTripVideoTrigger2()
				.pipe(
					filter((value) => !!value),
					switchMap((event) => this.ds.getCameraStatusByID2(event))
				)
				.subscribe((res) => {
					this.video['url'] = res.message.video_url;
					if (res.message.video_url2) {
						this.video['url2'] = res.message.video_url2;
						// this.isDualVideo = true
					}
					this.cf.markForCheck();
				})
		);
	}

	async download(video: VideoEntryBundle) {
		const res = await this.ds.getCameraStatusByID(video);
		if (res.status == 'success') {
			saveAs(res.message.video_url);
		}
	}

	notExistFileAlert() {
		this.alertService
			.alertDialog(this.transObj['ALERTS']['File does not exist Please contact your manager'])
			.afterClosed()
			.subscribe((value) => {
				if (value) {
					this.close.emit();
				}
			});
	}

	// 📌 230330 업로드 영상 다운로드
	downloadVideo(video: any, type: number) {
		console.log(video);
		console.log(type);

		if (type === 0) {
			if (video.url) {
				const a = document.createElement('a');
				a.href = video.url;
				// a.target = '_blank';

				a.download = video.url.split('/').pop();
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
			} else {
				this.notExistFileAlert();
			}
		} else if (type === 1) {
			if (video.url2) {
				const a = document.createElement('a');
				a.href = video.url2;

				a.download = video.url2.split('/').pop();
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
			} else {
				this.notExistFileAlert();
			}
		}
	}
}
