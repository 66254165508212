import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-section-header',
    templateUrl: './section-header.component.html',
    styleUrls: ['./section-header.component.scss']
})
export class SectionHeaderComponent implements OnInit {

    @Input() title = '';
    @Input() desc = '';
    @Input() flag = true;

    constructor() { }

    ngOnInit() {
    }

    // openDescription() {
    //     this.helpService.showModal(this.title, this.desc);
    // }

}
