import {
	AfterViewInit,
	Component,
	Input,
	OnDestroy,
	OnInit,
	ViewChild,
	ChangeDetectorRef,
} from '@angular/core';

import * as Chart from 'chart.js';
import chartDataLabels from 'chartjs-plugin-datalabels';

@Component({
	selector: 'app-industry-idle-charts',
	templateUrl: './industry-idle-charts.component.html',
	styleUrls: ['./industry-idle-charts.component.scss'],
})

// 📌230306 동종업계 기관 개요(공회전)
export class IndustryIdleChartComponent implements OnInit {
	public chart: any;

	constructor() {}

	ngOnInit() {
		this.createChart();
	}

	createChart() {
		const chart = new Chart('industryIdleBar', {
			plugins: [chartDataLabels],
			type: 'horizontalBar',
			data: {
				labels: [''],
				datasets: [
					{
						data: [54],
						backgroundColor: 'rgb(180,199,231)',
						borderColor: 'rgb(180,199,231)',
						borderWidth: 1,
						barPercentage: 0.8,
						// barThickness: 40,
						datalabels: {
							display: false,
						},
						stack: 'Stack 1',
					},
					{
						data: [26],
						backgroundColor: 'rgb(217,217,217)',
						borderColor: 'rgb(217,217,217)',
						borderWidth: 1,
						barPercentage: 0.8,
						// barThickness: 40,
						datalabels: {
							display: false,
						},
						stack: 'Stack 1',
					},
					{
						data: [20],
						backgroundColor: 'rgb(80,87,104)',
						borderColor: 'rgb(80,87,104)',
						borderWidth: 1,
						barPercentage: 0.8,
						datalabels: {
							display: false,
						},
						stack: 'Stack 1',
					},
					{
						data: [47],
						backgroundColor: 'rgb(180,199,231)',
						borderColor: 'rgb(180,199,231)',
						borderWidth: 1,
						barPercentage: 0.8,
						// barThickness: 40,
						datalabels: {
							display: false,
						},
						stack: 'Stack 2',
					},
					{
						data: [23],
						backgroundColor: 'rgb(217,217,217)',
						borderColor: 'rgb(217,217,217)',
						borderWidth: 1,
						barPercentage: 0.8,
						// barThickness: 40,
						datalabels: {
							display: false,
						},
						stack: 'Stack 2',
					},
					{
						data: [30],
						backgroundColor: 'rgb(80,87,104)',
						borderColor: 'rgb(80,87,104)',
						borderWidth: 1,
						barPercentage: 0.8,
						datalabels: {
							display: false,
						},
						stack: 'Stack 2',
					},
				],
			},

			options: {
				responsive: false,
				animation: {
					duration: 0,
				},
				tooltips: {
					enabled: false,
				},
				layout: {
					padding: 0,
				},
				legend: {
					display: false,
				},
				plugins: {
					datalabels: {
						display: false,
					},
				},
				hover: {
					mode: null,
				},

				scales: {
					yAxes: [
						{
							display: false,
							gridLines: {
								display: false,
								color: 'transparent',
							},
							ticks: {
								display: false,
								beginAtZero: true,
								min: 0,
							},
							stacked: true,
						},
					],
					xAxes: [
						{
							display: false,
							gridLines: {
								display: false,
							},
							ticks: {
								beginAtZero: true,
								min: 0,
							},
							stacked: true,
						},
					],
				},
			},
		});
	}
}
