
// Promise 버전 sleep
export function sleep(ms: number): Promise<any> {
    return new Promise(res => setTimeout(res, ms));
}

// 동기 버전 sleep
export function sleepSync(ms: number) {
    // busy loop
    let start = new Date().getTime();
    let expire = start + ms;
    while (new Date().getTime() < expire) {
    }
    // nodejs
    // var sab = new SharedArrayBuffer(36);
    // Atomics.wait(new Int32Array(sab), 0, 0, ms);
}

const CharsetAlphanumericCase = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
export function randomString(n: number, possible: string = CharsetAlphanumericCase) {
    let text = '';
    for (let i = 0; i < n; i++) {
        text += possible.charAt((Math.random() * possible.length) | 0);
    }
    return text;
}

// [0, n) 또는 [n, m]
export function randomInt(n: number, m?: number) {
    return m === undefined ? (Math.random() * n) | 0 :
        n + ((Math.random() * (m - n + 1)) | 0);
}

export function binarySearch<T>(array: T[], value: T, pred: (lhs: T, rhs: T) => boolean) {
    let guess, min = 0, max = array.length - 1;
    while (min <= max) {
        guess = ~~((min + max) /2);
        if (array[guess] === value)
            return guess;
        else if (pred(array[guess], value))
            min = guess + 1;
        else
            max = guess - 1;
    }
    return null;
}
