import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import {DynamicDialogRef} from 'primeng/api';
import {DynamicDialogConfig} from 'primeng/api';
import { Title } from '@angular/platform-browser';
import { DaterangeService } from '../../../service/daterange.service';
import { DataService } from '../../../service/data.service';
import { TableViewStyle, DateFormats } from '../../../uicommon';
import { nameof } from '../../lib/typeutils';

@Component({
    selector: 'app-vehicle-history',
    templateUrl: './vehicle-history.component.html',
    styleUrls: ['./vehicle-history.component.scss']
})
export class VehicleHistoryComponent implements OnInit, OnDestroy {
    loading = false;
    TableViewStyle = TableViewStyle;
    DateFormats = DateFormats;
    viewStyle: TableViewStyle = TableViewStyle.Table;
    showDetail = false;
    drivers: any[] = [];
    drivertest : [];
    skor: any;
    tableData: any[] = [{
        driver: 22,
        id: 22,
        index: 22,
        grade: 13,
        name: 'asdf',
        photo: 213,
        totalMileage: 12,
        skor: 213,
        registeredDay: new Date(),
    },
    {
        driver: 22,
        id: 22,
        index: 22,
        grade: 13,
        name: 'asdf',
        photo: 213,
        totalMileage: 12,
        skor: 213,
        registeredDay: new Date(),
    }
    ];

    sorted: any = {};
    list :any =[]
    constructor( public ref: DynamicDialogRef, public config:DynamicDialogConfig) {
        console.log('this.data')
        console.log(this.config)    
        this.list = this.config.data.list
        
    }
    ngOnInit() {
    }
    goTripHistory(){
        
    }
    ngOnDestroy() {
    }
}
