import { animate } from '@angular/animations';
import { Injectable, NgZone } from '@angular/core';
import { MapsAPILoader, GoogleMapsAPIWrapper } from '@agm/core';
import { Observable, Subject } from 'rxjs';
declare var google: any;

@Injectable({
  providedIn: 'root'
})
export class MapService extends GoogleMapsAPIWrapper {
  getRouteSelect = new Subject();
  realtimeLatlng = new Subject();
  public isLoaded = false;
  public getAddress: string;
  public markersInit = [];
  public originLatLng: any;
  public mapInit: any;
  public directionsService: any;
  public directionsDisplay: any;
  public routeR: any;
  public lastLat: any;
  public lastLng: any;
  public isShowVideo = false;
  public mapStyles: any = [
    { elementType: 'geometry', stylers: [{ color: '#212121' }] },
    { elementType: 'labels.icon', stylers: [{ visibility: 'off' }]},
    { elementType: 'labels.text.stroke', stylers: [{ color: '#212121' }] },
    { elementType: 'labels.text.fill', stylers: [{ color: '#757575' }] },
    {
      featureType: 'administrative.land_parcel',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'administrative.locality',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#757575' }]
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#757575' }]
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [{ color: '#263c3f' }]
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#616161' }]
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [{ color: '#2c2c2c' }]
    },
    {
      featureType: 'road',
      elementType: 'geometry.stroke',
      stylers: [{ color: '#212a37' }]
    },
    {
      featureType: 'road',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#8a8a8a' }]
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [{ color: '#746855' }]
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.stroke',
      stylers: [{ color: '#1f2835' }]
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#f3d19c' }]
    },
    {
      featureType: 'transit',
      elementType: 'geometry',
      stylers: [{ color: '#757575' }]
    },
    {
      featureType: 'transit.station',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#757575' }]
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [{ color: '#000000' }]
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#8a8a8a' }]
    },
    {
      featureType: 'water',
      elementType: 'labels.text.stroke',
      stylers: [{ color: '#1b1b1b' }]
    }
  ];

  constructor(
    private __loader: MapsAPILoader,
    private __zone: NgZone
  ) {
    super(__loader, __zone);
    this.__loader.load().then(() => {
      // this.mapInit = new google.maps.Map(document.getElementById('carviMap'));
      this.directionsService = new google.maps.DirectionsService;
      this.directionsDisplay = new google.maps.DirectionsRenderer();
    });
  }

  public getMapStyles() {
    return this.mapStyles;
  }

  getLatLan(address: string) {
    console.log('Getting Address - ', address);
    const geocoder = new google.maps.Geocoder();
    return Observable.create(observer => {
      geocoder.geocode({ 'address': address }, function (results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          observer.next(results[0].geometry.location);
          observer.complete();
        } else {
          console.log('Error - ', results, ' & Status - ', status);
          observer.next({});
          observer.complete();
        }
      });
    });
  }

  public codeLatLng2(lat, lng) {
    const geocoder = new google.maps.Geocoder();
    const latlng = new google.maps.LatLng(lat, lng);
    geocoder.geocode({
      location: latlng
    }, function (results: any, status) {
      if (status === google.maps.GeocoderStatus.OK) {
        const res = results.filter((r) => {
          return r.types.indexOf('street_address') !== -1;
        });
        if (res.length > 0) {
          return res[0]['formatted_address'];
        } else {
          console.log('No results found');
          // alert('No results found');
        }
      } else {
        console.log('Geocoder failed due to: ' + status);
      }
    });
  }

  public codeLatLng(lat, lng) {
    // this.mapsAPILoader.load().then(() => {
    //     console.log('google script loaded');
    //     const geocoder = new google.maps.Geocoder();
    // });
    const geocoder = new google.maps.Geocoder();
    const latlng = new google.maps.LatLng(lat, lng);
    return new Promise((resolve, reject) => {
      geocoder.geocode({
        location: latlng
      }, function (results: any, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          const res = results.filter((r) => {
            return r.types.indexOf('street_address') !== -1;
          });
          if (res.length > 0) {
            resolve(res[0].formatted_address);
            console.log(res[0]);
          } else {
            resolve(null);
            // alert('No results found');
          }
        } else {
          resolve(null);
          // alert('Geocoder failed due to: ' + status);
        }
      });
    });
  }

  public getRouteDistance(cords: any[]) {
    let polylineLength = 0;
    const path = [];
    for (let i = 0; i < cords.length; i++) {
      const lat = parseFloat(cords[i].lat);
      const lng = parseFloat(cords[i].lng);
      const pointPath = new google.maps.LatLng(lat, lng);
      path.push(pointPath);
      if (i > 0) {
        polylineLength += google.maps.geometry.spherical.computeDistanceBetween(path[i], path[i - 1]);
        // polylineLength += google.maps.geometry.spherical.computeDistanceBetween(path[i], path[i - 1]);
      }
    }
    return polylineLength / 1609.34;
  }


  getCodeLatLng(lat: number, lng: number) {
    this.__loader.load().then(() => {
      const latlng = new google.maps.LatLng({ lat: lat, lng: lng });
      const geocoder = new google.maps.Geocoder();
      // const location = {
      //   country: null,
      //   state: null,
      //   city: null
      // };
      geocoder.geocode({
        'latLng': latlng
      }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          // console.log(results, 'sss');
          if (results[1]) {
            // console.log(results[1].formatted_address, 'sss');
            return results[1].formatted_address;
            // for (let i = 0; i < results[0].address_components.length; i++) {
            //   for (let b = 0; b < results[0].address_components[i].types.length; b++) {
            //     if (results[0].address_components[i].types[b] === 'country') {
            //       location.country = !location.country ? results[0].address_components[i] : location.country;
            //     } else if (results[0].address_components[i].types[b] === 'administrative_area_level_1') {
            //       location.state = !location.state ? results[0].address_components[i] : location.state;
            //     } else if (results[0].address_components[i].types[b] === 'administrative_area_level_2') {
            //       location.city = !location.city ? results[0].address_components[i] : location.city;
            //     }
            //     if (location.city && location.state && location.country) {
            //       break;
            //     }
            //   }
            // }
            // console.log(location);
            // return location;
          } else {
            console.log('Results not available');
          }
        } else {
          console.log('Geocoder failed due to: ', status);
        }
      });
    });
  }

  initMap() {
    this.__loader.load().then(() => {
      const myLatlng = new google.maps.LatLng(0.00, 0.00);
      const myOptions = {
        zoom: 9,
        center: myLatlng,
        styles: this.mapStyles,
      };
      const map = new google.maps.Map(document.getElementById('carviMap'), myOptions);
    });
  }

  initRealTimeMap(lat, lng) {
    // this.__loader.load().then(() => {
    const renderer = new google.maps.DirectionsRenderer;
    const service = new google.maps.DirectionsService;
    if (!this.isLoaded) {
      const latlng = new google.maps.LatLng(lat, lng);
      localStorage.setItem('originLat', lat);
      localStorage.setItem('originLng', lng);
      const myOptions = {
        zoom: 11,
        center: latlng,
        styles: this.mapStyles,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
      };
      this.mapInit = new google.maps.Map(document.getElementById('carviMap'), myOptions);

      const marker = new google.maps.Marker({
        position: latlng,
        map: this.mapInit,
        icon: `../../../assets/icons/icon_car.png`
      });
      this.markersInit.push(marker);
      // Service callback to process service results
      const service_callback = function (response, status) {
        if (status !== 'OK') {
          console.log('Directions request failed due to ' + status);
          return;
        }
        renderer.setMap(this.mapInit);
        // renderer.setOptions({ suppressMarkers: true, preserveViewport: true });
        renderer.setDirections(response);
      };
      // Service options
      const service_options = {
        origin: latlng,
        destination: latlng,
        optimizeWaypoints: true,
        provideRouteAlternatives: false,
        avoidFerries: false,
        avoidHighways: false,
        avoidTolls: false,
        travelMode: 'WALKING' // TRANSIT
      };
      // Send request
      service.route(service_options, service_callback);
      this.isLoaded = true;
    } else {
      const latlng = new google.maps.LatLng(lat, lng);
      const marker = new google.maps.Marker({
        position: latlng,
        icon: `../../../assets/icons/icon_car.png`,
        map: this.mapInit
      });
      const centerlatLng = marker.getPosition(); // returns LatLng object
      this.mapInit.setCenter(centerlatLng);


      for (let i = 0; i < this.markersInit.length; i++) {
        this.markersInit[i].setMap(null);
      }
      // Service callback to process service results
      const service_callback = function (response, status) {
        if (status !== 'OK') {
          console.log('Directions request failed due to ' + status);
          return;
        }
        renderer.setMap(this.mapInit);
        // renderer.setOptions({ suppressMarkers: true, preserveViewport: true });
        renderer.setDirections(response);
      };
      // Service options
      const oriLat = localStorage.getItem('originLat');
      const oriLng = localStorage.getItem('originLng');
      const origin = new google.maps.LatLng(oriLat, oriLng);
      console.log(origin, 'origin');
      console.log(latlng, 'des');
      const service_options = {
        origin: origin,
        destination: latlng,
        optimizeWaypoints: true,
        provideRouteAlternatives: false,
        avoidFerries: false,
        avoidHighways: false,
        avoidTolls: false,
        travelMode: 'WALKING' // TRANSIT
      };
      // Send request
      service.route(service_options, service_callback);
      this.markersInit.push(marker);
    }
    // });
  }


  initRealTimeMap2(lat, lng) {
    // this.mapInit.setZoom(10);
    const destination = new google.maps.LatLng(lat, lng);
    if (!this.isLoaded) {
      this.originLatLng = new google.maps.LatLng(lat, lng);
      localStorage.setItem('lat', lat);
      localStorage.setItem('lng', lng);
      this.mapInit = new google.maps.Map(document.getElementById('carviMap'), {
        zoom: 14,
        styles: this.mapStyles,
        // zoomControl: false,
        // scaleControl: true
      });
      this.isLoaded = true;
    } else {
      this.originLatLng = new google.maps.LatLng(localStorage.getItem('lat'), localStorage.getItem('lng'));
      localStorage.setItem('lat', lat);
      localStorage.setItem('lng', lng);
    }
    // this.directionsDisplay.setMap(null);
    // directionsDisplay.setMap(null);
    // directionsDisplay.setMap(this.mapInit);
    // const directionsDisplay = new google.maps.DirectionsRenderer({ map: this.mapInit });


    for (let i = 0; i < this.markersInit.length; i++) {
      this.markersInit[i].setMap(null);
    }
    const that = this;
    // WALKING directions.
    const bounds = new google.maps.LatLngBounds();
    this.directionsService.route({
      origin: this.originLatLng,
      destination: destination,
      optimizeWaypoints: true,
      provideRouteAlternatives: false,
      avoidFerries: false,
      avoidHighways: false,
      avoidTolls: false,
      travelMode: 'WALKING'
    }, function (response, status) {
      // Route the directions and pass the response to a function to create
      // markers for each step.
      if (status === 'OK') {
        // directionsDisplay.setDirections(null);
        // that.directionsDisplay.setMap(null);
        that.directionsDisplay.setMap(that.mapInit);
        that.directionsDisplay.setOptions({
          suppressMarkers: true,
          preserveViewport: true,
          polylineOtions: {
            strokeColar: '#0f0',
            strokeWeight: 5,
          }
        });
        that.directionsDisplay.setDirections(response);
        bounds.union(response.routes[0].bounds);
        that.mapInit.fitBounds(bounds);
        const marker = new google.maps.Marker({
          position: destination,
          icon: `../../../assets/icons/icon_car.png`,
          map: that.mapInit
        });
        marker.setMap(that.mapInit);
        that.markersInit.push(marker);
      } else {
        console.log('Directions request failed due to ' + status);
      }
    });
  }

  calculateAndDisplayRoute(directionsDisplay, directionsService, origin, destination, map) {
    for (let i = 0; i < this.markersInit.length; i++) {
      this.markersInit[i].setMap(null);
    }
    const that = this;
    // WALKING directions.
    directionsService.route({
      origin: origin,
      destination: destination,
      optimizeWaypoints: true,
      provideRouteAlternatives: false,
      avoidFerries: false,
      avoidHighways: false,
      avoidTolls: false,
      travelMode: 'WALKING'
    }, function (response, status) {
      // Route the directions and pass the response to a function to create
      // markers for each step.
      if (status === 'OK') {
        directionsDisplay.setMap(null);
        // directionsDisplay.setMap(that.mapInit);
        directionsDisplay.setOptions({ suppressMarkers: true });
        directionsDisplay.setDirections(response);

        const marker = new google.maps.Marker({
          position: destination,
          icon: `../../../assets/icons/icon_car.png`,
          map: map
        });
        marker.setMap(map);
        that.markersInit.push(marker);
      } else {
        console.log('Directions request failed due to ' + status);
      }
    });
  }

  // origin, destination, waypoinArr
  carviMap(origin, destination, waypoinArr) {
    console.log(origin, 'origin');
    console.log(destination, 'destination');
    this.__loader.load().then(() => {
      const parts = [];
      const service = new google.maps.DirectionsService;
      const map = new google.maps.Map(
        document.getElementById('carviMap'),
        {
          styles: this.mapStyles
        }
      );

      // list of points
      const stations = waypoinArr.filter(elm => {
        return (elm.location.lat !== 0 && elm.location.lng !== 0);
      });
      console.log(stations, 'stations');
      // Zoom and center map automatically by stations (each station will be in visible map area)
      const lngs = stations.map(function (station) { return station.location.lng; });
      const lats = stations.map(function (station) { return station.location.lat; });
      map.fitBounds({
        west: Math.min.apply(null, lngs),
        east: Math.max.apply(null, lngs),
        north: Math.min.apply(null, lats),
        south: Math.max.apply(null, lats),
      });

      // Show stations on the map as markers
      for (let i = 0; i < stations.length; i++) {
        if (stations[i].event === 'collision') {
          this.customMarker(map, stations[i].location, stations[i].event, 'icon_car_front_collision');
          // this.mapIcons = 'icon_car_front_collision';
          // this.situation = r.reaction;
        } else if (stations[i].event === 'departure') {
          this.customMarker(map, stations[i].location, stations[i].event, 'icon_car_lane_departure');
          // this.mapIcons = 'icon_car_lane_departure';
          // this.situation = r.direction;
        } else if (stations[i].event === 'Crash Detected') {
          this.customMarker(map, stations[i].location, stations[i].event, 'icon_car_crash');
          // this.mapIcons = 'icon_car_crash';
          // this.situation = r.situation;
        } else if (stations[i].situation === 'stop' && stations[i].event === 'sudden') {
          this.customMarker(map, stations[i].location, stations[i].event, 'icon_car_stop');
          // this.mapIcons = 'icon_car_stop';
          // this.situation = r.situation;
        } else if (stations[i].situation === 'brake' && stations[i].event === 'sudden') {
          this.customMarker(map, stations[i].location, stations[i].event, 'icon_car_hard_braking');
          // this.mapIcons = 'icon_car_hard_braking';
          // this.situation = r.situation;
        } else if (stations[i].situation === 'accel' && stations[i].event === 'sudden') {
          this.customMarker(map, stations[i].location, stations[i].event, 'icon_car_speeding');
          // this.mapIcons = 'icon_car_speeding';
          // this.situation = r.situation;
        }

      }

      this.customMarker(map, origin, 'origin', 'icon_location_A');
      this.customMarker(map, destination, 'destination', 'icon_location_B');

      // Divide route to several parts because max stations limit is 25 (23 waypoints + 1 origin + 1 destination)
      for (let i = 0, max = 23; i < stations.length; i = i + max) {
        parts.push(stations.slice(i, i + max + 1));
      }


      // Service callback to process service results
      const service_callback = function (response, status) {
        if (status !== 'OK') {
          console.log('Directions request failed due to ' + status);
          return;
        }
        const renderer = new google.maps.DirectionsRenderer;
        renderer.setMap(map);
        renderer.setOptions({
          suppressMarkers: true,
          preserveViewport: true ,
          polylineOtions: {
            strokeColar: '#0f0',
            strokeWeight: 5,
          }
        });
        renderer.setDirections(response);
      };

      console.log(parts, 'parts');
      // Send requests to service to get route (for stations count <= 25 only one request will be sent)
      for (let i = 0; i < parts.length; i++) {
        // Waypoints does not include first station (origin) and last station (destination)
        const waypoints = [];
        for (let j = 1; j < parts[i].length - 1; j++) {
          waypoints.push({ location: parts[i][j].location, stopover: false });
        }

        // Service options
        const service_options = {
          origin: origin,
          destination: destination,
          waypoints: waypoints,
          optimizeWaypoints: true,
          provideRouteAlternatives: false,
          avoidFerries: false,
          avoidHighways: false,
          avoidTolls: false,
          travelMode: 'DRIVING'
        };
        // Send request
        service.route(service_options, service_callback);
      }
    });
  }

  customMarker(map, latlng, data, img) {
    const that = this;
    const isVideo = null;
    // if (that.isShowVideo) {
    //   isVideo = `<div class="play-img" (click)="videoPlay()">
    //               <i class="fa fa-pause-circle"></i>
    //             </div>
    //           <div class="video-alert">
    //           <vg-player>
    //               <video #media [vgMedia]="media" id="singleVideo" preload="auto" controls crossorigin>
    //                 <source src="/assets/video/carvi.mp4" type="video/mp4">
    //               </video>
    //           </vg-player>
    //           </div>`;
    // } else {
    //   isVideo = `<div class="play-img" (click)="videoPlay()">
    //               <i  class="fa fa-play-circle"></i>
    //             </div>`;
    // }
    // const marker = [];
    // let lastWindow = null;
    const icon = {
      url: `../../../assets/icons/${img}.png`,
      // scaledSize : new google.maps.Size(36, 36)
    };
    const newmarker = new google.maps.Marker({
      position: new google.maps.LatLng(latlng),
      map: map,
      title: data,
      icon: icon
    });

    newmarker['infowindow'] = new google.maps.InfoWindow({
      content: `
      <div class="trip-info-w">
        <div class="trip-alert">Alert</div>
        <div class="trip-alert-d">
          <div class="title">${data} - ${data}</div>
          ${isVideo}
        </div>
      </div>`
    });

    google.maps.event.addListener(newmarker['infowindow'], 'domready', () => {
      const el = document.querySelector('i');
      el.addEventListener('click', () => {
        that.isShowVideo = !that.isShowVideo;
        that.customMarker(map, latlng, data, img);
        this.videoPlayContent(newmarker['infowindow'], that.isShowVideo, data);
      });
    });

    google.maps.event.addListener(newmarker, 'click', function () {
      // console.log(that.markersInit, 'marker');
      if (that.markersInit.length > 0) {
        for (let i = 0; i < that.markersInit.length; i++) {
          if (that.markersInit[i]['infowindow']) {
            that.markersInit[i]['infowindow'].close(map, that.markersInit[i]);
          }
        }
      }
      this['infowindow'].open(map, this);
      // that.markersInit.push(newmarker);
    });
    that.markersInit.push(newmarker);
  }

  videoPlayContent(infowindow, isShowVideo, data) {
    console.log(infowindow, 'm');
    console.log(isShowVideo, 'reavi');
    let isVideo = null;
    if (isShowVideo) {
      isVideo = `<div class="play-img" (click)="videoPlay()">
                  <i class="fa fa-pause-circle"></i>
                </div>
              <div class="video-alert">
              <vg-player>
                  <video #media [vgMedia]="media" id="singleVideo" preload="auto" controls crossorigin>
                    <source src="/assets/video/carvi.mp4" type="video/mp4">
                  </video>
              </vg-player>
              </div>`;
    } else {
      isVideo = `<div class="play-img" (click)="videoPlay()">
                  <i  class="fa fa-play-circle"></i>
                </div>`;
    }
    infowindow.setContent(`
    <div class="trip-info-w">
        <div class="trip-alert">Alert</div>
        <div class="trip-alert-d">
          <div class="title">${data} - ${data}</div>
          ${isVideo}
        </div>
      </div>
    `);
  }

  ravireal(lat, lng) {
    google.maps.event.addDomListener(window, 'load', this.realtimeNew(lat, lng));
  }

  realtimeNew(lat, lng) {
    if (this.lastLat !== lat && this.lastLng !== lng) {
      this.lastLat = lat;
      this.lastLng = lng;
    } else {
      return;
    }
    const destination = new google.maps.LatLng(lat, lng);
    if (!this.isLoaded) {
      this.originLatLng = new google.maps.LatLng(lat, lng);
      localStorage.setItem('lat', lat);
      localStorage.setItem('lng', lng);
      this.mapInit = new google.maps.Map(document.getElementById('carviMap'), {
        zoom: 18,
        styles: this.mapStyles,
      });
      this.getDirections(this.mapInit, this.originLatLng, destination);
      this.isLoaded = true;
    } else {
      this.originLatLng = new google.maps.LatLng(localStorage.getItem('lat'), localStorage.getItem('lng'));
      this.autoRefresh2(this.mapInit, destination);
    }
  }

  moveMarker(map, marker, latlng) {
    marker.setPosition(latlng);
    map.panTo(latlng);
  }

  autoRefresh2(map, coords) {
    const marker = new google.maps.Marker({map: map, icon: './../../assets/icons/icon_car.png'});
    this.routeR.getPath().push(coords);
    this.moveMarker(map, marker, coords);
  }

  autoRefresh(map, pathCoords) {
    this.routeR = new google.maps.Polyline({
      path: [],
      geodesic : true,
      strokeColor: 'green',
      strokeOpacity: 1.0,
      strokeWeight: 5,
      editable: false,
      map: map
    });

    const marker = new google.maps.Marker({map: map, icon: './../../assets/icons/icon_car.png'});
    const that = this;
    for (let i = 0; i < pathCoords.length; i++) {
      setTimeout(function(coords) {
        that.routeR.getPath().push(coords);
        that.moveMarker(map, marker, coords);
      }, 500 * i, pathCoords[i]);
    }
  }

  getDirections(map, origin, destination) {
    this.directionsDisplay.setMap(null);
    this.directionsDisplay.setOptions({
      map: map,
      suppressMarkers: true,
    });
    // const directionsService = new google.maps.DirectionsService();

    // const start = new google.maps.LatLng(17.416483, 78.513592);
    // const end = new google.maps.LatLng(17.424643, 78.645126);
    // const marker = new google.maps.Marker({
    //     position: end,
    //     map: map,
    //   });
    //   marker = new google.maps.Marker({
    //     position:  start ,
    //     map: map,
    //    icon: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png'
    //   });

    const that = this;

    const request = {
      origin: origin,
      destination: destination,
      optimizeWaypoints: true,
      provideRouteAlternatives: false,
      avoidFerries: false,
      avoidHighways: false,
      avoidTolls: false,
      travelMode: 'WALKING'
    };
    this.directionsService.route(request, function(result, status) {
      if (status === google.maps.DirectionsStatus.OK) {
        // that.directionsDisplay.setDirections(result);
        // console.log(result.routes[0].overview_path, 'ddd');
        that.autoRefresh(map, result.routes[0].overview_path);
      }
    });
  }

}
