import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import * as Chart from 'chart.js';

@Component({
	selector: 'app-dangerous-driving-count-stacked',
	templateUrl: './dangerous-driving-count-stacked.componant.html',
	styleUrls: ['./dangerous-driving-count-stacked.componant.scss'],
})

// 📌230109 1-2 위험운전 기관 개요(등급)
export class DangerousDrivingCountStackedComponent implements OnInit, OnDestroy {
	public chart: any;

	constructor() {}

	ngOnInit(): void {
		this.dangerousDrivingCountStackedChart();
	}

	dangerousDrivingCountStackedChart() {
		const chart = new Chart('countStackedBarChart', {
			type: 'bar',
			data: {
				labels: [
					'차선변경',
					'무단차선이탈',
					'전방충돌위험',
					'급제동',
					'고위험 충격발생',
					'보호구역위반',
				],
				datasets: [
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(106,142,208)',
						stack: 'one',
						data: [5, 5, 5, 5, 5, 5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(235,99,112)',
						stack: 'two',
						data: [5, 5, 5, 5, 5, 5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(191,191,191)',
						stack: 'three',
						data: [5, 5, 5, 5, 5, 5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(106,142,208)',
						stack: 'one',
						data: [5, 5, 5, 5, 5, 5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(235,99,112)',
						stack: 'two',
						data: [5, 5, 5, 5, 5, 5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(191,191,191)',
						stack: 'three',
						data: [5, 5, 5, 5, 5, 5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(106,142,208)',
						stack: 'one',
						data: [5, 5, 5, 5, 5, 5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(235,99,112)',
						stack: 'two',
						data: [5, 5, 5, 5, 5, 5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(191,191,191)',
						stack: 'three',
						data: [5, 5, 5, 5, 5, 5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(106,142,208)',
						stack: 'one',
						data: [5, 5, 5, 5, 5, 5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(235,99,112)',
						stack: 'two',
						data: [5, 5, 5, 5, 5, 5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(191,191,191)',
						stack: 'three',
						data: [5, 5, 5, 5, 5, 5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(106,142,208)',
						stack: 'one',
						data: [5, 5, 5, 5, 5, 5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(235,99,112)',
						stack: 'two',
						data: [5, 5, 5, 5, 5, 5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(191,191,191)',
						stack: 'three',
						data: [5, 5, 5, 5, 5, 5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(242,242,242)',
						stack: 'one',
						data: [5, 5, 5, 5, 5, 5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(235,99,112)',
						stack: 'two',
						data: [5, 5, 5, 5, 5, 5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(191,191,191)',
						stack: 'three',
						data: [5, 5, 5, 5, 5, 5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(242,242,242)',
						stack: 'one',
						data: [5, 5, 5, 5, 5, 5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(242,242,242)',
						stack: 'two',
						data: [5, 5, 5, 5, 5, 5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(191,191,191)',
						stack: 'three',
						data: [5, 5, 5, 5, 5, 5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
					},
				],
			},

			options: {
				responsive: false,
				animation: {
					duration: 0,
				},
				plugins: {
					labels: {
						render: '',
						fontColor: ['#fff'],
						fontSize: 0,
					},
				},
				layout: {
					padding: 20,
				},
				legend: {
					display: false,
				},
				tooltips: {
					enabled: false,
				},
				scales: {
					yAxes: [
						{
							id: 'bar-stack',
							stacked: true,
							ticks: {
								beginAtZero: true,
								display: false,
							},
							gridLines: {
								display: false,
								drawBorder: false,
							},
						},
					],
					xAxes: [
						{
							ticks: {
								beginAtZero: true,
								fontColor: '#000',
								fontSize: 18,
							},
							gridLines: {
								display: false,
								drawOnChartArea: false,
							},
						},
					],
				},
			},
		});
	}

	ngOnDestroy(): void {}
}
