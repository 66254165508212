import {
	Component,
	OnInit,
	ViewChild,
	AfterViewInit,
	Input,
	Output,
	EventEmitter,
} from '@angular/core';
import { DaterangePickerComponent } from 'ng2-daterangepicker';
//import { DaterangeService } from '../../service/daterange.service';
import * as moment from 'moment';
import { AnyService } from 'src/app/service/any.service';

@Component({
	selector: 'app-date-custom-range',
	templateUrl: './date-range-custom.component.html',
	styleUrls: ['./date-range-custom.component.scss'],
})
export class DateRangeCustomComponent implements OnInit, AfterViewInit {
	public daterange: any = {
		start: Date.now(),
		end: Date.now(),
		label: '',
	};
	date = new Date();
	startDate = moment(this.date.setUTCDate(this.date.getUTCDate() - 6)).format(
		'YYYY-MM-DD HH:mm:ss.SSS'
	);
	endDate = moment(this.date.setUTCDate(this.date.getUTCDate() + 6)).format(
		'YYYY-MM-DD HH:mm:ss.SSS'
	);

	@Input() range = {
		startDate: '',
		endDate: '',
	};

	@Input() rangeType: string;

	@ViewChild(DaterangePickerComponent)
	private picker: DaterangePickerComponent;
	options = {
		locale: { format: 'YYYY-MM-DD' },
		alwaysShowCalendars: false,
		startDate: '',
		endDate: '',
		ranges: false,
	};
	constructor(
		//private drs: DaterangeService
		private anyService: AnyService
	) {}

	ngOnInit() {
		const date = new Date();
		const startDate = moment(date.setUTCDate(date.getUTCDate() - 6)).format(
			'YYYY-MM-DD HH:mm:ss.SSS'
		);
		const endDate = moment(date.setUTCDate(date.getUTCDate() + 6)).format(
			'YYYY-MM-DD HH:mm:ss.SSS'
		);

		this.options.startDate = this.range.startDate;
		this.options.endDate = this.range.endDate;
		this.daterange.endDate = endDate;

		// const timePeriodText = this.drs.getTimePeriod(this.daterange.start, this.daterange.end);
		// this.drs.timePeriodText.next(timePeriodText);
		const dataSelect = { startDate: this.daterange.start, endDate: this.daterange.end };

		//this.ngAfterViewInit()
		// this.drs.dateRangeSelectShared.subscribe((dateRange)=>{
		//   this.daterange.start = dateRange.startDate;
		//   this.daterange.end = dateRange.endDate;
		// })
	}
	ngAfterViewInit() {
		// this.picker.datePicker.setStartDate(this.daterange.start);
		// this.picker.datePicker.setEndDate(this.daterange.end);
		// const timePeriodText = this.drs.getTimePeriod(this.daterange.start, this.daterange.end);
		// this.drs.timePeriodText.next(timePeriodText);
	}
	@Output() submit: EventEmitter<any> = new EventEmitter();

	public selectedDate(value: any) {
		// console.log('selectedDate value utc ', value);
		// value.start = moment(value.start).utc().format()
		// value.end = moment(value.end).utc().format()
		// this.daterange.start = value.start;
		// this.daterange.end = value.end;
		// const dataSelect = {startDate: value.start, endDate: value.end};
		//this.submit.emit(dataSelect);
		// this.drs.dateRangeSelectShared.next(dataSelect);
		//this.drs.dateLabelSelect.next(value.label);
	}
	public openDate() {
		this.anyService.setdatePickerSw(true);
	}
	public closeDate() {
		this.anyService.setdatePickerSw(false);
	}
	public applyDate(value: any) {
		if (value !== undefined) {
			if (this.rangeType === 'driverDetail') {
				// 범위 지정시 1일 자동 설정
				// const start = new Date(value.picker.startDate);
				// const startYear = start.getFullYear();
				// const startMonth = start.getMonth();
				// value.start = new Date(startYear, startMonth, 1);

				// 기존
				value.start = moment(value.picker.startDate).utc().format();
				value.end = moment(value.picker.endDate).utc().format();

				// const end = new Date(value.picker.endDate);
				// const endYear = end.getFullYear();
				// const endMonth = end.getMonth();
				// value.end = new Date(endYear, endMonth + 1, 0);
			} else {
				value.start = moment(value.picker.startDate).utc().format();
				value.end = moment(value.picker.endDate).utc().format();
			}

			this.range.startDate = value.start;
			this.range.endDate = value.end;
		}
		// console.log(this.range);
		const dataSelect = { startDate: this.range.startDate, endDate: this.range.endDate };
		this.submit.emit(dataSelect);

		console.log(value);
		//this.selectedDate(value)
		// const startDate = moment(this.daterange.start).format('YYYY-MM-DD');
		// const endDate = moment(this.daterange.end).format('YYYY-MM-DD');
		// const dataSelect = {startDate: startDate, endDate: endDate};
		// this.drs.dateRangeSelectShared.next(dataSelect);
		//this.drs.dateLabelSelect.next(e.picker.chosenLabel);
		// const timePeriodText = this.drs.getTimePeriod(startDate, endDate);
		// this.drs.timePeriodText.next(timePeriodText);
	}
}
