import {
	Component,
	OnInit,
	ViewChild,
	AfterViewInit,
	Input,
	Output,
	EventEmitter,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
} from '@angular/core';

@Component({
	selector: 'app-custom-date-picker',
	templateUrl: './custom-date-picker.component.html',
	styleUrls: ['./custom-date-picker.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomDatePickerComponent implements OnInit, AfterViewInit {
	constructor(private changeRef: ChangeDetectorRef) {}

	@Output() selectAllDates = new EventEmitter();
	@Output() dateOnChange = new EventEmitter();
	@Input() isShow: boolean;

	@Input() monthValue: string;

	// isShow: boolean = false;

	ngOnInit(): void {
		console.log(this.isShow);
	}

	selectAll() {
		this.isShow = false;
		this.monthValue = '';
		this.selectAllDates.emit();

		console.log('555');
		console.log(this.monthValue);
	}

	dateHandler(val: string) {
		this.monthValue = val ? val : '';
		console.log(val);
		this.dateOnChange.emit(val);
	}

	sendDates(month: string) {
		console.log(month);
	}

	showHandler() {
		console.log('call @@');
		// this.monthValue = null;

		if (!this.isShow) {
			console.log('111');
			this.isShow = true;
		}

		if (this.monthValue === '') {
			console.log('222');
			this.isShow = false;
		}

		if (this.monthValue && this.monthValue.length > 3) {
			console.log('333');
			this.isShow = false;
		}

		console.log(this.monthValue);
		console.log(this.isShow);

		this.changeRef.detectChanges();
	}

	ngAfterViewInit(): void {}
}
