import {
	AfterViewInit,
	Component,
	Input,
	OnDestroy,
	OnInit,
	ViewChild,
	ChangeDetectorRef,
} from '@angular/core';

import * as Chart from 'chart.js';
import chartDataLabels from 'chartjs-plugin-datalabels';

@Component({
	selector: 'app-station-entry-charts',
	templateUrl: './station-entry-charts.component.html',
	styleUrls: ['./station-entry-charts.component.scss'],
})

// 📌230306 정류장 진입, 진출 시(고위험)
export class StationEntryChartComponent implements OnInit {
	public chart: any;

	constructor(private changeRef: ChangeDetectorRef) {}

	ngOnInit() {
		this.createChart();
	}

	createChart() {
		const chart = new Chart('entryCharts', {
			plugins: [chartDataLabels],
			type: 'bar',
			data: {
				labels: ['급가속', '급감속', '급정거'],
				datasets: [
					{
						data: [133, 202, 2],
						backgroundColor: 'rgb(106,142,208)',
						borderColor: 'rgb(106,142,208)',
						barPercentage: 1,
						borderWidth: 1,
						barThickness: 55,
					},
					{
						data: [149, 228, 3],
						backgroundColor: 'rgb(235, 99, 112)',
						borderColor: 'rgb(235, 99, 112)',
						barPercentage: 1,
						borderWidth: 1,
						barThickness: 55,
					},
				],
			},

			options: {
				hover: {
					mode: null,
				},
				responsive: false,
				animation: {
					duration: 0,
				},
				tooltips: {
					enabled: false,
				},
				layout: {
					padding: 10,
				},
				legend: {
					display: false,
				},
				plugins: {
					datalabels: {
						display: true,
						align: 'top',
						anchor: 'end',
						color: '#000',
						font: {
							size: 20,
							weight: 'normal',
						},
					},
				},

				scales: {
					yAxes: [
						{
							display: true,
							gridLines: {
								display: true,
								drawBorder: false,
								lineWidth: 0,
								zeroLineWidth: 3,
								zeroLineColor: 'rgb(230, 230, 230)',
							},
							ticks: {
								display: true,
								beginAtZero: true,
								fontColor: '#000',
								fontStyle: 'normal',
								fontSize: 20,
								min: 0,
								maxTicksLimit: 6,
								padding: 10,
							},
						},
					],
					xAxes: [
						{
							display: true,
							gridLines: {
								display: false,
							},
							ticks: {
								display: true,
								beginAtZero: true,
								fontColor: '#000',
								fontStyle: 'normal',
								fontSize: 20,
							},
						},
					],
				},
			},
		});
	}
}
