import { Component, OnInit, Input } from '@angular/core';
interface BarRange {
  min: number;
  max: number;
  color: string;
}
export interface VerticalBarChartRange {
  ranges: BarRange[];
}

@Component({
  selector: 'app-vertical-slider-chart',
  templateUrl: './vertical-slider-chart.component.html',
  styleUrls: ['./vertical-slider-chart.component.scss']
})
export class VerticalSliderChartComponent implements OnInit {

  @Input() svgHeight = 320;
  @Input() svgWidth = 200;
  @Input() flag: boolean;
  @Input() hFlag: boolean;
  @Input() small: boolean;
  yArr = []; yLength = 0;
  rectHeight;
  rectY;

  constructor() { }

  ngOnInit() {
    if (this.flag) {
      this.yArr = ['90', '60', '30', '0', '-30', '-60', '-90'];
    } else {
      this.yArr = ['100', '80', '60', '40', '20', '0'];
    }
    if (this.hFlag) {
      // this.yLength = this.svgHeight / this.yArr.length;
      this.yLength = this.svgHeight / this.yArr.length;
    } else {
      this.yLength = 40;
    }
  }

}
