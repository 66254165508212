import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {

    transform(value: any, args?: any): any {
      
        if(value==0 || value==null || value=='null'){
            return 0
        }
        if (value) {
            if (value.toString().indexOf('.') > 0) {
                if (args === 0) {
                    const digets = 0;
                    const fixedDigitNumber = parseFloat(value.toString()).toFixed(digets);
                    if(fixedDigitNumber == '-0.00') {
                        return "0.00";
                    } else {
                        return fixedDigitNumber
                    }
                } else {
                    const digets = args ? parseInt(args, 10) : 2;
                    const fixedDigitNumber = parseFloat(value.toString()).toFixed(digets);
                    if(fixedDigitNumber == '-0.00') {
                        return "0.00";
                    } else {
                        return fixedDigitNumber
                    }
                }

            } else {
                return value;
            }
        }
        return;
    }
}
