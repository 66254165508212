import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DaterangeService {

    timePeriodText = new Subject();
    
    date = new Date();
    
    startDate = moment(this.date.setUTCDate(this.date.getUTCDate() - 30)).format('YYYY-MM-DD HH:mm:ss.SSS');
    endDate = moment(this.date.setUTCDate(this.date.getUTCDate() + 30)).format('YYYY-MM-DD HH:mm:ss.SSS');
    dateRangeSelectShared = new BehaviorSubject({'startDate':this.startDate,'endDate':this.endDate});
    dateLabelSelect = new BehaviorSubject('Last 7 Days');

    constructor() { }

    getTimePeriod(startDate: string, endDate: string) {
        const getStartDate = moment(startDate).format('YYYY-MM-DD');
        const getEndDate = moment(endDate).format('YYYY-MM-DD');
        const getTime = new Date(getEndDate).getTime() - new Date(getStartDate).getTime();
        const year = (new Date(getTime).getFullYear() - 1970);
        const month = new Date(getTime).getMonth();
        const day = (new Date(getTime).getDate());
        if (year === 0 && month !== 0) {
          if (month !== 0 && day === 0) {
            return 'Last ' + month + ' Month';
          }
          return month + ' Month and ' + day + ' Days';
        }
        if (year === 0 && month === 0) {
          return 'Last ' + day + ' Days';
        }
        return year + ' Year ' + month + ' Month ' + day + ' Days';

      }

    // unitConversion(unit, value): number {
    //     this.res = unit === 'km' ? value * this.dev : value / this.dev;
    //     return this.res;
    // }

}
