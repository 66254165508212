import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import {
	ChartComponent,
	ApexAxisChartSeries,
	ApexChart,
	ApexTitleSubtitle,
	ApexXAxis,
	ApexNonAxisChartSeries,
	ApexPlotOptions,
	ApexAnnotations,
	ApexStroke,
	ApexFill,
	ApexTooltip,
	ApexResponsive,
	ApexYAxis,
	ApexGrid,
	ApexStates,
	ApexTheme,
	ApexDataLabels,
	ApexLegend,
} from 'ng-apexcharts';

export type ChartOptions = {
	series: ApexAxisChartSeries | ApexNonAxisChartSeries;
	chart: ApexChart;
	xaxis: ApexXAxis;
	yaxis: ApexYAxis | ApexYAxis[];
	title: ApexTitleSubtitle;
	colors: string[];
	dataLabels: ApexDataLabels;
	plotOptions: ApexPlotOptions;
	annotations: ApexAnnotations;
	stroke: ApexStroke;
	labels: string[];
	fill: ApexFill;
	tooltip: ApexTooltip;
	responsive: ApexResponsive[];
	grid: ApexGrid;
	states: ApexStates;
	subtitle: ApexTitleSubtitle;
	theme: ApexTheme;
	legend: ApexLegend;
};

@Component({
	selector: 'app-dauMileage-charts',
	templateUrl: './apx-dauMileage-charts.component.html',
	styleUrls: ['./apx-dauMileage-charts.component.scss'],
})

// 📌221222 리뉴얼 운전자 리포트 - 총 주행거리
export class ApxDauMileageChartComponent implements OnInit, AfterViewInit {
	@ViewChild('charts') charts: ChartComponent;
	public chartOptions: Partial<ChartOptions>;

	@Input() chart: ApexChart;
	@Input() annotations: ApexAnnotations;
	@Input() colors: string[];
	@Input() dataLabels: ApexDataLabels;
	@Input() series: ApexAxisChartSeries | ApexNonAxisChartSeries;
	@Input() stroke: ApexStroke;
	@Input() labels: string[];
	@Input() legend: ApexLegend;
	@Input() fill: ApexFill;
	@Input() tooltip: ApexTooltip;
	@Input() plotOptions: ApexPlotOptions;
	@Input() responsive: ApexResponsive[];
	@Input() xaxis: ApexXAxis;
	@Input() yaxis: ApexYAxis | ApexYAxis[];
	@Input() grid: ApexGrid;
	@Input() states: ApexStates;
	@Input() title: ApexTitleSubtitle;
	@Input() subtitle: ApexTitleSubtitle;
	@Input() theme: ApexTheme;

	@Input() dauType: any;
	@Input() driverWeekMileageData: [];
	@Input() driverWeekTripTimeData: [];
	@Input() companyWeekMileageData: [];
	@Input() companyWeekTripTimeData: [];

	constructor() {}

	ngOnInit() {
		// setTimeout(() => {
		// }, 1000);
		this.createChart();
	}

	createChart() {
		// console.log('driverWeekMileageData => ', this.driverWeekMileageData);
		// console.log('companyWeekMileageData', this.companyWeekMileageData);
		// console.log('driverWeekTripTimeData', this.driverWeekTripTimeData);
		// console.log('companyWeekTripTimeData', this.companyWeekTripTimeData);
		this.chartOptions = {
			series: [
				{
					data:
						this.dauType === 'mileage' ? this.driverWeekMileageData : this.driverWeekTripTimeData,
				},
				{
					data:
						this.dauType === 'mileage' ? this.companyWeekMileageData : this.companyWeekTripTimeData,
				},
			],

			chart: {
				height: 450,
				type: 'bar',

				toolbar: {
					show: false,
				},
				animations: {
					enabled: false,
				},
			},
			colors: ['#00b0f0', '#d9d9d9'],
			plotOptions: {
				bar: {
					horizontal: true,
					borderRadius: 0,
					barHeight: '40%',
					distributed: false,
					colors: {
						ranges: [
							{
								from: 1,
								to: 5,
								color: '#01b1ee',
							},
						],
					},
					// dataLabels: {
					// 	position: 'top',
					// },
				},
			},
			legend: {
				show: false,
			},
			tooltip: {
				enabled: false,
			},
			states: {
				hover: {
					filter: {
						type: 'none',
					},
				},
			},
			dataLabels: {
				enabled: false,
				offsetX: 0,
				style: {
					fontSize: '12px',
					fontWeight: '400',
					colors: ['#fff'],
				},

				// background: {
				// 	enabled: false,
				// },
				formatter: function (val: any, opt: any) {
					const format = new Intl.NumberFormat().format(val);
					return `${format}`;
				},
			},
			stroke: {
				show: false,
				width: 1,
				colors: ['#fff'],
			},
			grid: {
				position: 'back',
				borderColor: '#fff',
				strokeDashArray: 0,
				show: true,
				// row: {
				// 	colors: ['red'],
				// 	opacity: 1,
				// },
				// column: {
				// 	colors: ['blue'],
				// 	opacity: 1,
				// },
				padding: {
					top: 20,
					right: 20,
					bottom: 36,
					left: 20,
				},

				xaxis: {
					lines: {
						show: false,
						offsetX: 0,
						offsetY: 0,
					},
				},
				yaxis: {
					lines: {
						show: false,
					},
				},
			},
			annotations: {
				xaxis: [
					{
						x: 0,
						strokeDashArray: 0,
						borderColor: '#d9d9d9',
						borderWidth: 2,
						opacity: 1,
					},
				],
			},
			xaxis: {
				categories: ['월', '화', '수', '목', '금', '토', '일'],
				labels: {
					show: true,
					// maxHeight: 400,
					minHeight: 0,
					style: {
						colors: [],
						fontSize: '24px',
					},
					offsetY: 10,
				},
				axisTicks: {
					show: false,
				},
				axisBorder: {
					show: false,
				},
			},
			yaxis: {
				show: true,
				tooltip: {
					enabled: false,
				},
				labels: {
					show: true,
					style: {
						colors: [],
						fontSize: '24px',
						fontWeight: 'bold',
					},
				},
				axisTicks: {
					show: false,
				},
				axisBorder: {
					show: false,
				},
			},
		};
	}

	ngAfterViewInit() {}
}
