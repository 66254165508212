import { Injectable ,} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { environment } from '../../environments/environment';
import { AlertDialogComponent } from '../shared/alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class LoginGuard implements CanActivate {
    constructor(
        private http: HttpClient,
        private dialog: MatDialog,
        private router:Router
    ) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        if(localStorage.getItem('UserPoolId') && localStorage.getItem('ClientId')){
            this.router.navigate(['overview']);
            return false;
          }else{
              return true;
          }
    }
}

