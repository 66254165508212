import { Component, OnInit, Input } from '@angular/core';
import { VideoEntry, VideoEntryBundle } from '../../types/data-types';
import { DateFormats } from '../../uicommon';
import { DataService } from '../../service/data.service';

@Component({
    selector: 'app-video-view',
    templateUrl: './video-view.component.html',
    styleUrls: ['./video-view.component.scss']
})
export class VideoViewComponent implements OnInit {

    DateFormats = DateFormats;

    @Input()
    isShow = false;

    @Input()
    video: VideoEntryBundle;

    bookmarked = false;

    playPopup = false;

    constructor(public ds: DataService) {}

    ngOnInit() {}

    toggleDetail(type: boolean) {
        this.playPopup = type;
    }

    async download(video: VideoEntryBundle) {
        const res = await this.ds.getCameraStatusByID(video);
        if (res.status == 'success') {
            saveAs(res.message.video_url);
        }
    }

    clickBookmark() {
        this.bookmarked = !this.bookmarked;
    }

}
