import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-input-box',
	templateUrl: './input-box.component.html',
	styleUrls: ['./input-box.component.scss'],
})
export class InputBoxComponent implements OnInit {
	@Input()
	readonly = false;
	@Input()
	placeholder;

	@Input()
	dropdown = false;

	@Input() isActive: string;

	@Input() value = '';
	@Output() valueChange = new EventEmitter<string>();

	constructor() {}

	ngOnInit() {}
}
