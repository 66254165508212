import gql from 'graphql-tag';

export const REAL_TIME_DATA_QUERY = gql`subscription realtime($Company_id:String!) {
  onUpdateRealtimeDataCompany(company_id:$Company_id){
      camera_id,
      company_id,
      driver_id,
      distance,
      location,
      time_stamp,
      start_time_stamp,
      trip_start,
      collision_cnt,
      dep_left_cnt,
      dep_right_cnt,
      idling_sec,
      speed,
      accel_cnt,
      brake_cnt,
      stop_cnt,
      heading,
      activated,
      vehicle_no,
      status,
      total_mileage,
      nosdcard,
      blinker,
      status_time_stamp,
      sim_time_stamp,
      camera_time_stamp,
      sd_time_stamp,
      blinker_time_stamp,
      mild_impact_cnt,
      severe_impact_cnt,
      drink_cnt
    }
  }
`;

export const GET_CAMERA_IDS_QUERY = gql`
  query($start_date: String!, $end_date: String) {
    getCompany(
        start_date: $start_date,
        end_date: $end_date,
        filters: {
            driver_page: 1
        }
      ){
          camera_ids
      }
    }
`;


export const DASHBOARD_QUERY = gql`
query($start_date: String!, $end_date: String) {
    getCompany(
        start_date: $start_date,
        end_date: $end_date,
        filters: {
            driver_page: 1
        }
      ){
          stats{
            mileage
              relative_speeds{
                min
                max
                avg
                quartile_25
                quartile_50
                quartile_75
              }
              idling{
                idling_count
                front_idling_count
              }
              score{
                  speed
                  focus
                  guard
                  overall
              }
              risk{
                  average_collision
                  average_departure
              }
              bias{
                  right
                  left
              }
              context{
                  average_acceleration
                  average_deceleration
                  average_stop
              }
          }
          diff{
              mileage
              relative_speeds{
                min
                max
                avg
                quartile_25
                quartile_50
                quartile_75
              }
              idling{
                idling_count
                front_idling_count
              }
              score{
                  speed
                  focus
                  guard
                  overall
              }
              risk{
                  average_collision
                  average_departure
              }
              bias{
                  right
                  left
              }
              context{
                  average_acceleration
                  average_deceleration
                  average_stop
              }
          }
          
       }
}`;

export const DASHBOARD_GRAPHS_STATS_PER_DAY = gql`
query($start_date: String!, $end_date: String) {
    getCompany(
        start_date: $start_date,
        end_date: $end_date,
        filters: {
             driver_page: 1
        }
      ){
        stats_per_day{
            relative_speeds{
                min
                max
                avg
                quartile_25
                quartile_50
                quartile_75
            }
            idling{
                idling_count
                front_idling_count
            }
            score{
              speed
              focus
              guard,
              overall
            }
              mileage
            risk{
              average_collision
              average_departure
            }
            trip_start_day
            bias{
              right
              left
            }
            context{
              average_acceleration
              average_deceleration
              average_stop
            }
          }
      }}`

export const DASHBOARD_QUERY_BOTTOM_DRIVERS = gql`
  query($start_date: String!, $end_date: String) {
    getCompany(
        start_date: $start_date,
        end_date: $end_date,
        filters: {
             driver_page: 1
        }
      ){
          stats{
       
			bottomDrivers {
				camera_id
				name
				stats {
					score {
						overall
					}
				}
				diff {
					score {
						overall
					}
				}
			}    
       }}
  }
`;


export const DASHBOARD_QUERY_TOP_DRIVERS = gql`
  query($start_date: String!, $end_date: String) {
    getCompany(
        start_date: $start_date,
        end_date: $end_date,
        filters: {
             driver_page: 1
        }
      ){
       
            stats {
                
                topDrivers {
                    camera_id
                    name
                    stats {
                        score {
                            overall
                        }
                    }
                    diff {
                        score {
                            overall
                        }
                    }
                }
            
            }
          } 		
       }
  
`;



export const DASHBOARD_GRAPHS_QUERY = gql`
  query($start_date: String!, $end_date: String) {
    getCompany(
        start_date: $start_date,
        end_date: $end_date,
        filters: {
            driver_page: 1
        }
      ){
          stats{
            mileage
            relative_speeds{
                min
                max
                avg
                quartile_25
                quartile_50
                quartile_75
              }
              idling{
                idling_count
                front_idling_count
              }
              score{
                  speed
                  focus
                  guard
                  overall
              }
              risk{
                  average_collision
                  average_departure
              }
              bias{
                  right
                  left
              }
              context{
                  average_acceleration
                  average_deceleration
                  average_stop
              }
            topDrivers{
              camera_id
              name
              stats{
                  score{
                    overall
                  }
              }
              diff{
                  score{
                    overall
                  }
              }
          }
          bottomDrivers{
              camera_id
              name
              stats{
                score{
                    overall
                  }
              }
              diff{
                  score{
                    overall
                  }
              }
          }
          }
          diff{
              mileage
              relative_speeds{
                min
                max
                avg
                quartile_25
                quartile_50
                quartile_75
              }
              idling{
                idling_count
                front_idling_count
              }
              score{
                  speed
                  focus
                  guard
                  overall
              }
              risk{
                  average_collision
                  average_departure
              }
              bias{
                  right
                  left
              }
              context{
                  average_acceleration
                  average_deceleration
                  average_stop
              }
          }
          stats_per_day{
            relative_speeds{
                min
                max
                avg
                quartile_25
                quartile_50
                quartile_75
            }
            idling{
                idling_count
                front_idling_count
            }
            score{
              speed
              focus
              guard,
              overall
            }
              mileage
            risk{
              average_collision
              average_departure
            }
            trip_start_day
            bias{
              right
              left
            }
            context{
              average_acceleration
              average_deceleration
              average_stop
            }
          }
     }
  }
`;
export const GET_DRIVERS_LIST_WITH_COMPANY_DATA = gql`
query($start_date: String!, $end_date: String) {
    getCompany(
        start_date: $start_date,
        end_date: $end_date,
        filters: {
            driver_page: 1
        }
      ) {
        
        stats {
          relative_speeds {
            min
            max
            avg
            quartile_25
            quartile_50
            quartile_75
         
          }
          idling {
            idling_count
            front_idling_count
         
          }
          mileage
          score {
            speed
            focus
            guard
            overall
        
          }
          risk {
            average_collision
            average_departure
          
          }
          bias {
            right
            left
           
          }
          context {
            average_acceleration
            average_deceleration
            average_stop
            
          }
         
        }
          diff {
            relative_speeds {
              min
              max
              avg
              quartile_25
              quartile_50
              quartile_75
           
            }
            mileage
            score {
              speed
              focus
              guard
              overall
           
            }
            risk {
              average_collision
              average_departure
           
            }
            bias {
              right
              left
           
            }
            context {
              average_acceleration
              average_deceleration
              average_stop
           
            }
         
          }
       
        
    }
 }
`;

export const GET_DRIVERS_LIST_WITH_COMPANY_DATA_DRIVER = gql`
query($start_date: String!, $end_date: String) {
    getCompany(
        start_date: $start_date,
        end_date: $end_date,
        filters: {
            driver_page: 1
        }
      ) {
          drivers {
            name
            camera_id
            stats {
              mileage
              relative_speeds{
                min
                max
                avg
                quartile_25
                quartile_50
                quartile_75
              }
              idling{
                idling_count
                front_idling_count
              }
              score {
                speed
                focus
                guard
                overall
              }
              risk {
                average_collision
                average_departure
              }
              bias {
                right
                left
              }
              context {
                average_acceleration
                average_deceleration
                average_stop
              }
            }
          }  
    }
 }
`;


export const GET_DRIVERS_LIST = gql`
 query($start_date: String!, $end_date: String) {
    getCompany(
        start_date: $start_date,
        end_date: $end_date,
        filters: {
            driver_page: 1
        }
      ) {
      drivers{
        name,
        camera_id
        stats{
            mileage
            score{
                speed
                focus
                guard
                overall
            }
            risk{
                average_collision
                average_departure
            }
            bias{
                right
                left
            }
            context{
                average_acceleration
                average_deceleration
                average_stop
            },
        }
        diff{
            mileage
            score{
                speed
                focus
                guard
                overall
            }
            risk{
                average_collision
                average_departure
            }
            bias{
                right
                left
            }
            context{
                average_acceleration
                average_deceleration
                average_stop
            }
        }
      }
    }
   }
`;


export const GET_DRIVERS_TRIPS_LIST = gql`
 query($start_date: String!, $end_date: String) {
    getCompany(
        start_date: $start_date,
        end_date: $end_date,
        filters: {
            driver_page: 1
        }
      ) {
      drivers{
        name,
        camera_id
        stats{
            mileage
            score{
                overall
            }
        }
        diff{
            score{
                overall
            }
        }
      }
    }
   }
`;

export const GET_DRIVERS_ROUTE_TRIPS_LIST = gql`
 query($camera_id: String!, $start_date: String!) {
    getDriverById(
        camera_id: $camera_id,
        start_date: $start_date,
        filters: {
            trips_page: 1
        }
      ) {
        camera_id
        name
        trips {
            camera_id
            trip_start
            trip_end
        }
     }
   }
`;
export const GET_DRIVERS_TRIPS_DETAILS = gql`
 query($camera_id: String!, $start_date: String!, $trips_page: Int) {
    getDriverById(
        camera_id: $camera_id,
        start_date: $start_date,
        filters: {
            trips_page: $trips_page
        }
      ) {
        camera_id
        name
        trips {
            camera_id
            trip_start
            trip_end
            stats {
              mileage
              score {
                overall
              }
            }
            location {
                source {
                  lat
                  lng
                }
                destination {
                  lat
                  lng
                }
              }
            trip_logs(filter:{event_types: ["collision","departure", "Crash Detected", "sudden"]}){
             camera_id
             time_stamp
             trip_start
              event
              situation
              direction
              reaction
              location{
                lat
                lng
              }
            }
          }
    }
   }
`;

export const GET_DRIVERS_TRIPS_DETAILS_OLD = gql`
 query($camera_id: String!, $start_date: String!, $trips_page: Int) {
    getDriverById(
        camera_id: $camera_id,
        start_date: $start_date,
        filters: {
            trips_page: $trips_page
        }
      ) {
        camera_id
        name
        trips {
            camera_id
            trip_start
            trip_end
            stats {
              mileage
              score {
                speed
                focus
                guard
                overall
              }
            }
            location {
              source {
                lat
                lng
              }
              destination {
                lat
                lng
              }
            }
            trip_logs{
             camera_id
             time_stamp
             trip_start
              event
              distance
              speed
              direction
              reduction_speed
              collision_distance
              ttc
              left_lane
              right_lane
              situation
              reaction
              location{
                lat
                lng
              }
            }
          }
    }
   }
`;


export const GET_DRIVERS_ROUTES_LIST = gql`
 query($start_date: String!, $end_date: String) {
    getCompany(
        start_date: $start_date,
        end_date: $end_date,
        filters: {
            driver_page: 1
        }
      ) {
      drivers{
        name,
        camera_id
        stats{
            mileage
            score{
                overall
            }
        }
        diff{
            mileage
            score{
                overall
            }
        }
        trips{
            camera_id
            trip_start
            location{
                destination{
                    lat
                    lng
                    city
                }
                source{
                    lat
                    lng
                    city
                }
            }
        }
      }
    }
   }
`;

export const GET_ROUTES_LIST = gql`
query($camera_id: String!, $start_date: String!, $trips_page: Int) {
    getDriverById(
        camera_id: $camera_id,
        start_date: $start_date,
        filters: {
            trips_page: $trips_page
        }
      ) {
        camera_id
        name
        trips {
            camera_id
            trip_start
            stats {
              mileage
              score {
                overall
              }
            }
            location {
              source {
                lat
                lng
              }
              destination {
                lat
                lng
              }
            }
        }
     }
   }
`;

export const GET_DRIVERS_SINGLE_TRIPS_DATA = gql`
 query($camera_id: String!, $trip_start: String!) {
    getTripById(
        camera_id: $camera_id,
        trip_start: $trip_start
      ) {
        camera_id
        trip_start
        trip_end
        stats {
        mileage
        score {
            speed
            focus
            guard
            overall
            }
        }
        location {
        source {
            lat
            lng
        }
        destination {
            lat
            lng
        }
        }
        trip_logs(filter:{event_types: ["collision","departure", "Crash Detected", "sudden"]}) {
        camera_id
        time_stamp
        trip_start
        event
        distance
        speed
        direction
        reduction_speed
        collision_distance
        ttc
        left_lane
        right_lane
        situation
        reaction
        bias
        front_distance
        front_speed
        front_reduction_speed
        front_reason
        location {
            lat
            lng
            }
        }
    }
   }
`;

// export const GET_REPORTLIST_QUERY = gql`

//   query(){
//          getReportsByFilter(filter:{report_id:{
//              ne:" "
//             }
//         }) {
//              report_id
//              report_title 
//              report_month_year 
//              created_date 
//              company_id 
//              url 
//             }
//         }
// `;

// query getAll{
//     getReportsByFilter(filter:{report_id:{ne:" "}}){
//       report_id
//       report_title
//       report_month_year
//       created_date
//       company_id
//       url
//     }
//   }