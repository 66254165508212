import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class AnyService {

    currentUnitEmitter: EventEmitter<string> = new EventEmitter();
    isCurrentThemeWhite = new Subject<any>();
    photoSrcTrigger$=new Subject<string>();
    pdfsw$=new Subject<string>();
    datePickerSw$=new Subject<boolean>();
    private currentUnit: string;
    public imei: string;
    public dev = 1.609344;
    public res = 0;
    public activeLanguage: string;
    constructor(
        private http: HttpClient
    ) { }
    addUserPreference(mileage, lan) {
        const email = localStorage.getItem('email');
        const data = {
            email: email,
            mileage: mileage,
            lan: lan
        };
        const theme = localStorage.getItem('theme')
        this.isCurrentThemeWhite.next(theme == 'white-theme')
        this.http.post(`${environment.dynamoDBUrl}/addUserPreference`, data).subscribe(res => {
            // console.log(res);
            if (res['data']) {
                localStorage.setItem('unit', mileage);
                localStorage.setItem('lan', lan);
            }
        });
    }
    setdatePickerSw(value:boolean){
        this.datePickerSw$.next(value);
    }
    getdatePickerSw(){
        return this.datePickerSw$.asObservable();
    }
    setPdfSwTrigger(value:string){
        this.pdfsw$.next(value);
    }
    getPdfSwTrigger(){
        return this.pdfsw$.asObservable();
    }
    setPhotoSrcTrigger(value:string){
        this.photoSrcTrigger$.next(value);
    }
    getPhotoSrcTrigger(){
        return this.photoSrcTrigger$;
    }
    getUserPreference() {
        const email = localStorage.getItem('email');
        return this.http.get(`${environment.dynamoDBUrl}/getUserPreference/${email}`);
    }


    getLangageName(id) {
        if (id === 'ja') {
            this.activeLanguage = 'Japanese';
        } else if (id === 'fr') {
            this.activeLanguage = 'French';
        } else if (id === 'zh') {
            this.activeLanguage = 'Chinese';
        } else if (id === 'ko') {
            this.activeLanguage = 'Korean';
        } else if (id === 'es') {
            this.activeLanguage = 'Spanish';
        } else {
            this.activeLanguage = 'English';
        }
        return this.activeLanguage;
    }

    getCurrentUnit() {
        // this.getUserPreference().subscribe(res => {
        //     console.log(res['data']['mileage'], 'lll');
        //     this.currentUnit = res['data']['mileage'];
        // });
        // return this.currentUnit;
        const unit = localStorage.getItem('unit') ? localStorage.getItem('unit') : 'mi';
        return this.currentUnit = unit;
    }

    setCurrentUnit(unit: string) {
        const lan = localStorage.getItem('defaultLng');
        this.addUserPreference(unit, lan);
        localStorage.setItem('unit', unit);
        if (this.currentUnit !== unit) {
            this.currentUnit = unit;
            this.currentUnitEmitter.emit(unit);
        }
    }

    // unitConversion(unit, value): number {
    //     this.res = unit === 'km' ? value * this.dev : value;
    //     return this.res;
    // }
    unitConversion(unit, value): number {
        return unit === 'mi' ? value / this.dev : value;
    }

    getTimePeriodObject(startDate: string, endDate: string) {
        const getStartDate = moment((startDate)).format('YYYY-MM-DD') + " " + '00:00:00';
        const getEndDate = moment((endDate)).format('YYYY-MM-DD') + " " + "23:59:59";
        console.log('getStartDate=========>', getStartDate)
        console.log('getStartDate=========>', getEndDate)
        let e = moment(getEndDate)
        let s = moment(getStartDate)

        let year = e.diff(s, 'year');
        s.add(year, 'years');

        let month = Math.floor(e.diff(s, 'months', true));
        s.add(month, 'months');

        let day = Math.ceil(e.diff(s, 'days', true));
        if (day == moment(e).daysInMonth()) {
            day = 0;
            month += 1;
        }
        console.log('year', year, 'month', month, 'day', day);

        if (year === 0 && month !== 0) {
            if (month !== 0 && day === 0) {
                return { timeText: 'Last ' + month + ' Month', type: 1, month: month };
            }
            return { timeText: month + ' Month and ' + day + ' Days', type: 2, month: month, day: day };
        }
        if (year === 0 && month === 0) {
            return { timeText: 'Last ' + day + ' Days', type: 3, day: day };
        }
        return { timeText: year + ' Year ' + month + ' Month ' + day + ' Days', year: year, month: month, day: day };

    }

    getTimePeriod(startDate: string, endDate: string) {
        const getStartDate = moment(startDate).format('YYYY-MM-DD');
        const getEndDate = moment(endDate).format('YYYY-MM-DD');
        const getTime = new Date(getEndDate).getTime() - new Date(getStartDate).getTime();
        const year = (new Date(getTime).getFullYear() - 1970);
        const month = new Date(getTime).getMonth();
        const day = (new Date(getTime).getDate());
        if (year === 0 && month !== 0) {
            if (month !== 0 && day === 0) {
                return 'Last ' + month + ' Month';
            }
            return month + ' Month and ' + day + ' Days';
        }
        if (year === 0 && month === 0) {
            return 'Last ' + day + ' Days';
        }
        return year + ' Year ' + month + ' Month ' + day + ' Days';

    }

    secondsToTime(serverTimeinSeconds) {
        let seconds = Math.floor(Number(serverTimeinSeconds));
        const days = Math.floor(seconds / (24 * 60 * 60));
        seconds -= Math.floor(days * (24 * 60 * 60));
        const hours = Math.floor(seconds / (60 * 60));
        seconds -= Math.floor(hours * (60 * 60));
        const minutes = Math.floor(seconds / (60));
        seconds -= Math.floor(minutes * (60));

        const dDisplay = days > 0 ? days + (days === 1 ? ` Day` : ` Day `) : '';
        const hDisplay = hours > 0 ? hours + (hours === 1 ? ` Hour` : ` Hour `) : '';
        const mDisplay = minutes > 0 ? minutes + (minutes === 1 ? ` Min` : ` Min `) : '';
        const sDisplay = seconds > 0 ? seconds + (seconds === 1 ? ` Sec` : ` Sec `) : '';
        const fdispaly = dDisplay + hDisplay + mDisplay + sDisplay;
        return fdispaly ? fdispaly : `0 Sec`;
        // const dDisplay = days;
        // const hDisplay = hours;
        // const mDisplay = minutes;
        // const sDisplay = seconds;
        // const fdispaly = dDisplay + hDisplay + mDisplay + sDisplay;
        // return fdispaly ? fdispaly : '0 Sec';
    }

    secondsToTimeC(serverTimeinSeconds) {
        let seconds = Math.floor(Number(serverTimeinSeconds));
        const days = Math.floor(seconds / (24 * 60 * 60));
        seconds -= Math.floor(days * (24 * 60 * 60));
        const hours = Math.floor(seconds / (60 * 60));
        seconds -= Math.floor(hours * (60 * 60));
        const minutes = Math.floor(seconds / (60));
        seconds -= Math.floor(minutes * (60));

        const dDisplay = days;
        const hDisplay = hours;
        const mDisplay = minutes;
        const sDisplay = seconds;
        const fdispaly = {
            d: dDisplay,
            h: hDisplay,
            m: mDisplay,
            s: sDisplay,
        };
        return fdispaly;
    }

    busNumberShow(cameraId) {
        cameraId = ""+cameraId;
        if (cameraId === '861107036077593') {
            this.imei = '1610';
        } else if (cameraId === '861107036063171') {
            this.imei = '1405';
        } else if (cameraId === '861107036037902') {
            this.imei = '1205';
        } else if (cameraId === '861107036059112') {
            this.imei = '1402';
        } else if (cameraId === '861107036111616') {
            this.imei = '1807';
        } else if (cameraId === '861107036108653') {
            this.imei = '1803';
        } else if (cameraId === '861107036108463') {
            this.imei = '1808';
        } else if (cameraId === '861107036115740') {
            this.imei = '1805';
        } else if (cameraId === '861107036103571') {
            this.imei = '1605';
        } else if (cameraId === '861107036046028') {
            this.imei = '1804';
        } else {
            this.imei = cameraId;
        }

        return this.imei;


    }


   
    // unitConversion(unit, value): number {
    //     this.res = unit === 'km' ? value * this.dev : value / this.dev;
    //     return this.res;
    // }

}
