import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { AlertDialogComponent } from '../shared/alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';
@Injectable({
	providedIn: 'root',
})
export class AlertService {
	constructor(private http: HttpClient, private dialog: MatDialog) {}
	public getCameraStatusByID(camera_id, filepath: string, timestamp) {
		return this.http.get(
			environment.getCameraStatusByIDUrl +
				'/' +
				camera_id +
				'?' +
				'file_path=' +
				filepath +
				'&' +
				'time_stamp=' +
				timestamp
		);
	}
	public getCameraAlertByID(camera_id, filepath: string, timestamp) {
		return this.http.get(
			environment.getCameraAlertByIDUrl +
				'/' +
				camera_id +
				'?' +
				'file_path=' +
				filepath +
				'&' +
				'time_stamp=' +
				timestamp
		);
	}

	public alertDialog(message: string, btnName?: string, key?: string) {
		const dialogRef = this.dialog.open(AlertDialogComponent, {
			data: { message, btnName, key },
			panelClass: 'custom-dialog-container',
			backdropClass: 'popup-backdrop-container',
		});
		return dialogRef;
	}
}
