import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject, of, throwError, empty } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from './service/auth.service';

import { JwtHelper, tokenNotExpired } from 'angular2-jwt';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService, private router: Router,private spinner: Ng4LoadingSpinnerService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const that = this;
        next.handle(request).pipe(
            catchError((error) => {
              let errorMessage = '';
              console.error('api error', error);
              if (error.error && error.error.message) {
                // client-side error
                errorMessage = `Error: ${error.error.message}`;
              } else if (error.error.length) {
                error.error.forEach(element => {
                  errorMessage += `Error: ${element.message}\n`;
                });
              } else {
                // server-side error
                errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
              }
                //this.spinner.hide();
              
              return throwError(errorMessage);
            })
          );
        // console.log('token',this.authService.getToken());
        if(this.authService.getToken() && this.authService.getToken() != 'null'){
            // console.log('in if')
            if (JwtHelper.prototype.isTokenExpired(this.authService.getToken())) {
                // this.authService.logout();
                let that = this;
    
                const requestObs: Subject<any> = new Subject<any>();
                
                // that.authService.getAuthenticatedUser().getSession((err, data) => {
                    
                    // that.authService.getAuthenticatedUser().refreshSession(data.getRefreshToken(), (error, data) => {
                    
                    //     that.authService.setToken(data.getIdToken().getJwtToken())
                    //     const token = this.authService.getToken(); // auth is provided via constructor.
                    //     if (token) {
                    //         request = request.clone({
                    //             setHeaders: {
                    //                 Authorization: `Bearer ${token}`
                    //             }
                    //         });
                    //     }
                       
                    //     next.handle(request).subscribe(data => {
                    //         requestObs.next(data);
                    //     })
    
                    // })
                //})
                return requestObs;
            } else {
    
    
                const token = localStorage.getItem('token'); // auth is provided via constructor.
                if (token) {
                    request = request.clone({
                        setHeaders: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                }
                return next.handle(request);
            }
        } else {
            // console.log('in else')
            return next.handle(request);  
        }
       
        // return next.handle(request).pipe(catchError(err => {
        //     // console.log(err, 'ee');
        //     if (err.status === 401) {
        //         // auto logout if 401 response returned from api
        //         // this.authService.logout();
        //         this.router.navigate(['/auth/sign-in']);
        //         // return false;
        //         // location.reload(true);
        //     }
        //     const error = err.error.message || err.statusText;
        //     return throwError(error);
        // }));
    }
}
