import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import * as Chart from 'chart.js';

@Component({
	selector: 'app-dangerous-driving-grade-stacked',
	templateUrl: './dangerous-driving-grade-charts.component.html',
	styleUrls: ['./dangerous-driving-grade-charts.component.scss'],
})

// 📌230110 2-2 위험운전 기관 개요(등급)
export class DangerousDrivingGradeStackedComponent implements OnInit, OnDestroy {
	public chart: any;

	constructor() {}

	ngOnInit(): void {
		this.dangerousDrivingGradeStackedChart();
	}

	dangerousDrivingGradeStackedChart() {
		const chart = new Chart('gradeStackedBarChart', {
			type: 'bar',
			data: {
				labels: ['위험거리지속시간', '제한속도위반시간'],
				datasets: [
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(106,142,208)',
						data: [5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
						stack: 'first',
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(235,99,112)',
						data: [5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
						stack: 'second',
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(191,191,191)',
						data: [5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
						stack: 'third',
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(106,142,208)',
						data: [5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
						stack: 'first',
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(235,99,112)',
						data: [5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
						stack: 'second',
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(191,191,191)',
						data: [5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
						stack: 'third',
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(106,142,208)',
						data: [5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
						stack: 'first',
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(235,99,112)',
						data: [5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
						stack: 'second',
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(191,191,191)',
						data: [5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
						stack: 'third',
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(106,142,208)',
						data: [5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
						stack: 'first',
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(235,99,112)',
						data: [5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
						stack: 'second',
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(191,191,191)',
						data: [5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
						stack: 'third',
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(106,142,208)',
						data: [5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
						stack: 'first',
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(235,99,112)',
						data: [5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
						stack: 'second',
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(191,191,191)',
						data: [5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
						stack: 'third',
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(106,142,208)',
						data: [5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
						stack: 'first',
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(235,99,112)',
						data: [5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
						stack: 'second',
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(191,191,191)',
						data: [5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
						stack: 'third',
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(106,142,208)',
						data: [5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
						stack: 'first',
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(235,99,112)',
						data: [5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
						stack: 'second',
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(191,191,191)',
						data: [5, 5],
						borderWidth: 2,
						borderColor: '#fff',
						barThickness: 45,
						stack: 'third',
					},
				],
			},

			options: {
				responsive: false,
				animation: {
					duration: 0,
				},
				plugins: {
					labels: {
						render: '',
						fontColor: ['#fff'],
						fontSize: 0,
					},
				},
				layout: {
					padding: 20,
				},
				legend: {
					display: false,
				},
				tooltips: {
					enabled: false,
				},
				scales: {
					yAxes: [
						{
							id: 'bar-stack',
							stacked: true,
							labels: ['최상', '상', '중상', '중', '중하', '하', '최하'],
							gridLines: {
								display: true,
								color: 'transparent',
								zeroLineColor: '#d9d9d9',
								zeroLineWidth: 2,
								drawTicks: false,
							},

							ticks: {
								beginAtZero: true,
								display: false,
							},
						},
					],
					xAxes: [
						{
							ticks: {
								beginAtZero: true,
								fontColor: '#000',
								fontSize: 18,
							},
							gridLines: {
								display: false,
								drawOnChartArea: false,
							},
						},
					],
				},
			},
		});
	}

	ngOnDestroy(): void {}
}
