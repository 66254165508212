import {
	AfterViewInit,
	Component,
	Input,
	OnDestroy,
	OnInit,
	ViewChild,
	ChangeDetectorRef,
} from '@angular/core';

import * as Chart from 'chart.js';
import chartDataLabels from 'chartjs-plugin-datalabels';

@Component({
	selector: 'app-dangerous-driving-hour-charts',
	templateUrl: './dangerous-driving-hour-charts.component.html',
	styleUrls: ['./dangerous-driving-hour-charts.component.scss'],
})

// 📌230109 2-1. 위험운전 기관 개요(시간)
export class DangerousDrivingHourChartComponent implements OnInit, AfterViewInit, OnDestroy {
	public chart: any;

	@Input() prevSafetyDisBinaryChartData: any;
	@Input() thisSafetyDisBinaryChartData: any;
	@Input() prevSpeedOverChartData: any;
	@Input() thisSpeedOverChartData: any;
	@Input() industryRiskTime: any;

	constructor(private changeRef: ChangeDetectorRef) {}

	ngOnInit() {
		setTimeout(() => {
			this.dangerousDrivingHourChart();
		}, 1000);
	}

	dangerousDrivingHourChart() {
		const chart = new Chart('hourBarChart', {
			plugins: [chartDataLabels],
			type: 'bar',
			data: {
				labels: ['위험거리지속시간', '제한속도위반시간'],
				datasets: [
					{
						data: [
							this.prevSafetyDisBinaryChartData.prevSafetyDisBinaryChartValue,
							this.prevSpeedOverChartData.prevSpeedOverChartValue,
						],
						backgroundColor: 'rgb(106,142,208)',
						borderColor: 'rgb(106,142,208)',
						borderWidth: 1,
						barPercentage: 1,
					},
					{
						data: [
							this.thisSafetyDisBinaryChartData.thisSafetyDisBinaryChartValue,
							this.thisSpeedOverChartData.thisSpeedOverChartValue,
						],
						backgroundColor: 'rgb(235,99,112)',
						borderColor: 'rgb(235,99,112)',
						borderWidth: 1,
						barPercentage: 1,
					},
					// {
					// 	data: [
					// 		this.industryRiskTime.industrySafetyDisBinaryValue,
					// 		this.industryRiskTime.industrySpeedOverValue,
					// 	],
					// 	backgroundColor: 'rgb(191,191,191)',
					// 	borderColor: 'rgb(191,191,191)',
					// 	borderWidth: 1,
					// },
				],
			},

			options: {
				plugins: {
					datalabels: {
						display: false,
						color: '#000',
						align: 'top',
						anchor: 'end',
						font: {
							size: 18,
							weight: 500,
						},
					},
				},
				responsive: false,
				animation: {
					duration: 0,
				},

				tooltips: {
					enabled: false,
				},
				layout: {
					padding: 20,
				},
				legend: {
					display: false,
				},
				scales: {
					yAxes: [
						{
							gridLines: {
								display: true,
								color: 'transparent',
								zeroLineColor: '#d9d9d9',
								zeroLineWidth: 2,
								drawTicks: false,
							},
							ticks: {
								beginAtZero: true,
								min: 0,
								// max: 100,
								stepSize: 100,
								fontSize: 22,
								fontColor: '#000',
								padding: 10,
								fontStyle: '600',
								callback(value, index, values) {
									const format = new Intl.NumberFormat().format(Number(value));
									return format;
								},
							},
						},
					],
					xAxes: [
						{
							gridLines: {
								display: false,
							},
							ticks: {
								beginAtZero: true,
								fontSize: 22,
								fontColor: '#000',
							},
						},
					],
				},
			},
		});
	}

	ngAfterViewInit(): void {}
	ngOnDestroy(): void {}
}
