import { Component, OnInit, Input } from '@angular/core';
import { Label, Color } from 'ng2-charts';

@Component({
	selector: 'app-doughnut-chart',
	templateUrl: './doughnut-chart.component.html',
	styleUrls: ['./doughnut-chart.component.scss'],
})
export class DoughnutChartComponent implements OnInit {
	@Input()
	value = 50;

	@Input('color')
	set setColor(color: string) {
		this.colors = [
			{
				backgroundColor: [color, 'lightgray'],
				borderColor: 'lightgray',
				borderWidth: 0,
			},
		];
	}

	doughnutChartLabels: Label[] = ['A'];
	doughnutChartOptions: Partial<any> = {
		responsive: true,
		maintainAspectRatio: true,
		cutoutPercentage: 85,
	};
	colors: Color[] = [
		{
			backgroundColor: ['red', 'lightgray'],
			borderColor: 'lightgray',
			borderWidth: 0,
		},
	];

	constructor() {}

	ngOnInit() {}
}
