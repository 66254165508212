import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Subject, BehaviorSubject } from 'rxjs';
import { ChangeDetectorRef } from '@angular/core';
import 'moment-timezone';
import { Country } from '../enum/country.enum';
import { DateFormats } from 'src/app/uicommon';
import { totalmem } from 'os';
const monthes = [
	{ index: 1, name: 'Jan' },
	{ index: 2, name: 'Feb' },
	{ index: 3, name: 'Mar' },
	{ index: 4, name: 'Apr' },
	{ index: 5, name: 'May' },
	{ index: 6, name: 'Jun' },
	{ index: 7, name: 'Jul' },
	{ index: 8, name: 'Aug' },
	{ index: 9, name: 'Sep' },
	{ index: 10, name: 'Oct' },
	{ index: 11, name: 'Nov' },
	{ index: 12, name: 'Dec' },
];
@Pipe({
	name: 'getDateStart',
})
export class GetDateStartPipe implements PipeTransform {
	commonValue = '';
	dateObservable = new BehaviorSubject<string>('');

	constructor(private _translateService: TranslateService, private cdRef: ChangeDetectorRef) {
		this._translateService.onLangChange.subscribe(() => {
			const value = this.commonValue;

			if (this._translateService.currentLang == 'ja') {
				console.log('index', _.findIndex(monthes, { name: value.split(',')[0].split(' ')[0] }));
				const date =
					value.split(',')[1].split(' ')[1] +
					'年' +
					(_.findIndex(monthes, { name: value.split(',')[0].split(' ')[0] }) + 1) +
					'月' +
					value.split(',')[0].split(' ')[1] +
					'日';
				this.dateObservable.next(date);
				console.log('value dateeee', date, value);
			} else {
				// console.log('value else', value)
				this.dateObservable.next(value);
			}
		});
	}
	transform(value: string, args?: any): any {
		this.commonValue = value;
		console.log('before value', value);
		if (this._translateService.currentLang == 'ja') {
			value =
				value.split(',')[1].split(' ')[1] +
				'年' +
				(_.findIndex(monthes, { name: value.split(',')[0].split(' ')[0] }) + 1) +
				'月' +
				value.split(',')[0].split(' ')[1] +
				'日';
		}
		console.log('after value', value);
		this.dateObservable.next(value);
		return this.dateObservable;
	}
	ngAfterViewChecked() {
		this.cdRef.detectChanges();
	}
}

@Pipe({
	name: 'getDateEnd',
})
export class GetDateEndPipe implements PipeTransform {
	commonValue = '';
	dateObservable = new BehaviorSubject<string>('');

	constructor(private _translateService: TranslateService, private cdRef: ChangeDetectorRef) {
		this._translateService.onLangChange.subscribe(() => {
			const value = this.commonValue;
			if (this._translateService.currentLang == 'ja') {
				const date =
					value.split(',')[1].split(' ')[1] +
					'年' +
					(_.findIndex(monthes, { name: value.split(',')[0].split(' ')[0] }) + 1) +
					'月' +
					value.split(',')[0].split(' ')[1] +
					'日';
				this.dateObservable.next(date);
			} else {
				this.dateObservable.next(value);
			}
		});
	}
	transform(value: string, args?: any): any {
		this.commonValue = value;
		if (this._translateService.currentLang == 'ja') {
			value =
				value.split(',')[1].split(' ')[1] +
				'年' +
				(_.findIndex(monthes, { name: value.split(',')[0].split(' ')[0] }) + 1) +
				'月' +
				value.split(',')[0].split(' ')[1] +
				'日';
		}
		this.dateObservable.next(value);
		return this.dateObservable;
	}
	ngAfterViewChecked() {
		this.cdRef.detectChanges();
	}
}

@Pipe({
	name: 'getDay',
})
export class GetDayPipe implements PipeTransform {
	transform(value: string, args?: any): any {
		//example value => Jan 5, 2018;
		return value.split(',')[0].split(' ')[1];

		// console.log(new Date(value).getTimezoneOffset())
	}
}

@Pipe({
	name: 'getMonth',
})
export class GetMonthPipe implements PipeTransform {
	transform(value: any, args?: any): any {
		console.log(value);
		return value.split(',')[0].split(' ')[0];
		// // console.log(new Date(value).getTimezoneOffset())
		// const localDate = this.addMinutes(value, new Date(value).getTimezoneOffset())
		// // console.log((localDate))
		// const day = moment(value).format('Do');
		// // console.log('get month name', value.toString());
		// // console.log(new Date(value).getUTCDate())
		// const month = new Date(localDate).getUTCMonth();
		// // console.log(month)
		// const year = moment(new Date(value).getFullYear());
		// // console.log('year',year)
		// return day + ' ' + this.getMonthName(month) + ' ' + year;
	}

	getMonthName(index: number): string {
		// let monthes = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ];
		//const localStorage.getItem('lan')
		const monthes = [
			{ index: 1, name: 'Jan' },
			{ index: 2, name: 'Feb' },
			{ index: 3, name: 'Mar' },
			{ index: 4, name: 'Apr' },
			{ index: 5, name: 'May' },
			{ index: 6, name: 'Jun' },
			{ index: 7, name: 'Jul' },
			{ index: 8, name: 'Aug' },
			{ index: 9, name: 'Sep' },
			{ index: 10, name: 'Oct' },
			{ index: 11, name: 'Nov' },
			{ index: 12, name: 'Dec' },
		];

		console.log('date pipe===========>', monthes[index].name);

		return monthes[index].name;
	}
}

@Pipe({
	name: 'getYear',
})
export class GetYearPipe implements PipeTransform {
	transform(value: any, args?: any): any {
		return value.split(',')[1].split(' ')[1];
	}
}

@Pipe({
	name: 'customDateFormat',
})
export class CustomDateFormatPipe implements PipeTransform {
	transform(value: Date, langp): string {
		if (!!value) {
			let timeText = value.toString().match(/([A-Z]+[\+-][0-9]+)/)[1];
			let gmtString = timeText.split(/[0-9]/)[0];
			let time = timeText.split(/[\+-]/)[1];
			if (time.length === 4) {
				time =
					time[0] === '0'
						? time[1] + ':' + time[2] + time[3]
						: time[0] + time[1] + ':' + time[2] + time[3];
			}
			const type = navigator.appName;
			let lang: string = type === 'Netscape' ? navigator.language : navigator['userLanguage'];
			lang = lang.substring(0, 2);
			let aera = Country[lang];
			let format = 'ddd MMM DD, YYYY / HH:mm:ss';
			if (langp === 'ko') {
				format = 'MMM DD일 dddd, YYYY / HH:mm:ss';
			}
			return moment(value).format(format) + ' ' + gmtString + time + ' ' + '(Seoul, South Korea)';
		}
		return '';
	}
}
@Pipe({
	name: 'lastUsedPipe',
})
export class LastUsedPipe implements PipeTransform {
	transform(value: string): string {
		moment(new Date(value));
		return moment(new Date(value)).format('YYYY-MM-DD HH:mm:ss ');
	}
}

@Pipe({
	name: 'videoDatePipe',
})
export class VideoDatePipe implements PipeTransform {
	transform(value: any): string {
		let text = '';
		if (!!value) {
			text = String(value);
			if (new Date(Number(text)).toString() === 'Invalid Date') {
				text = moment(text).format('MM/DD/YYYY, HH:mm:ss');
			} else {
				text = moment(new Date(Number(text))).format('MM/DD/YYYY, HH:mm:ss');
			}
		}

		// 'MM/dd/yyyy, HH:mm:ss
		return text;
	}
}
@Pipe({
	name: 'driverDatePipe',
})
export class DriverDatePipe implements PipeTransform {
	transform(value: any): string {
		let text = '';
		if (!!value) {
			text = String(value);
			if (value.indexOf('.') !== -1) {
				text = text.split('.')[0];
			}
			if (new Date(value).toString() === 'Invalid Date') {
				const date1 = text.split(' ')[0];
				const date2 = text.split(' ')[1];
				const date1split = date1.split('-');
				const date2split = date2.split(':');
				const year = date1split[0];
				const month = date1split[1];
				const date = date1split[2];
				const hour = date2split[0];
				const min = date2split[1];
				const sec = date2split[2];
				console.log(date1 + date2);
				moment(new Date(date1 + date2)).format('MM/DD/YYYY, HH:mm:ss');
				text = month + '/' + date + '/' + year + ', ' + hour + ':' + min + ':' + sec;
			} else {
				text = moment(text).format('MM/DD/YYYY, HH:mm:ss');
			}
		}

		// 'MM/dd/yyyy, HH:mm:ss
		return text;
	}
}
