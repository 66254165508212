import {
	AfterViewInit,
	Component,
	Input,
	OnDestroy,
	OnInit,
	ViewChild,
	ChangeDetectorRef,
} from '@angular/core';

import * as Chart from 'chart.js';
import chartDataLabels from 'chartjs-plugin-datalabels';

@Component({
	selector: 'app-idle-round-bar-charts',
	templateUrl: './idle-round-bar-charts.component.html',
	styleUrls: ['./idle-round-bar-charts.component.scss'],
})

// 📌230224 기점/종점 회차지 공회전
export class IdleRoundBarChartComponent implements OnInit {
	public chart: any;

	constructor() {}

	ngOnInit() {
		this.createChart();
	}

	createChart() {
		const chart = new Chart('idleRoundBarChart', {
			plugins: [chartDataLabels],
			type: 'bar',
			data: {
				labels: ['전월', '당월', '전월', '당월'],
				datasets: [
					{
						data: [880, 850, 180, 150],
						backgroundColor: [
							'rgb(217,217,217)',
							'rgb(191,191,191)',
							'rgb(217,217,217)',
							'rgb(247,156,156)',
						],
						barPercentage: 0.7,
						barThickness: 50,
						datalabels: {
							display: false,
						},
					},
				],
			},

			options: {
				plugins: {
					datalabels: {
						display: false,
					},
				},
				hover: {
					mode: null,
				},
				responsive: false,
				animation: {
					duration: 0,
				},
				tooltips: {
					enabled: false,
				},
				layout: {
					padding: 20,
				},
				legend: {
					display: false,
				},

				scales: {
					yAxes: [
						{
							display: true,
							gridLines: {
								display: true,
								drawBorder: false,
								lineWidth: 2,
								zeroLineWidth: 2,
								zeroLineColor: 'rgb(233,233,233)',
							},
							ticks: {
								display: true,
								beginAtZero: true,
								maxTicksLimit: 6,
								fontColor: '#000',
								fontStyle: '500',
								fontSize: 20,
								min: 0,
								padding: 10,
								stepSize: 200,
							},
						},
					],
					xAxes: [
						{
							display: true,
							gridLines: {
								display: false,
							},
							ticks: {
								display: true,
								beginAtZero: true,
								fontColor: '#000',
								fontStyle: '500',
								fontSize: 20,
							},
						},
					],
				},
			},
		});
	}
}
