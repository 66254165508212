import { Skor, Driver2, Score, DriverStats2, Location, Locatio } from './data-types';
export const TempNames = [
    'Jhon Smith',
    'Jannes Jacobs',
    'Donald Teel',
    'Sam Wheeler',
    'Emir Saldierna',
    'Diana Simum',
    'Gregory Gibbons',
    'Asiya Handley',
    'Mimi Gibbs',
    'Eleri Schneider',
    'Kelvin Schmitt',
    'Lorcan Oakley',
    'Kiya Finney',
    'Michael Meyer',
    'Ayden Mcintosh',
    'Glyn Moyer',
    'Tyler-Jay Bailey',
    'Renee Sweet',
    'Ritchie Newman',
    'Aimie Mercer',
    'Shaquille Benitez',
    'Cobie Howarth',
    'Jadene Franklin',
    'Abigale York',
    'Eman Dunn',
    'Jana Stokes',
    'Nathanael Beil',
    'Sheena Wolf',
    'Adina Weber',
    'Hisham Vickers',
    'Elana Buxton',
    'Kaydan Bloom',
    'Isa Davison',
    'Teegan Munoz',
    'Farhaan Hawkins',
    'Freddy Mcnally',
];

export const TempAvatars = [
    `avatar-0.png`,
    `avatar-1.png`,
    `avatar-2.png`,
    `avatar-3.png`,
    `avatar-4.png`,
    `avatar-5.png`,
    `avatar-6.png`,
    `avatar-7.png`,
    `avatar-8.png`,
    `avatar-9.png`,
    `avatar-10.png`,
    `avatar-11.png`,
    `avatar-12.png`,
    `avatar-13.png`,
    `avatar-14.png`,
    `avatar-15.png`,
];

export function driver2TempPhoto(cameraId: string) {
    const n = +cameraId;
    return `/assets/images/${TempAvatars[n % TempAvatars.length]}`;
}

export function driver2TempName(cameraId: string | number) {
    // const n = +cameraId;
    // return TempNames[n % TempNames.length];
    return '' + cameraId;
}

export function getGrade(score: Score) {
    const v = (score && score.overall) | 0;
    return v >= 90 ? 5 :
        v >= 80 ? 4 :
        v >= 70 ? 3 :
        v >= 60 ? 2 : 1;
}

export function driver2Skor(driver: Driver2): Skor {
    const stats = stats2Skor(driver && driver.stats);
    stats.id = driver ? driver.camera_id : 'DRIVER';
    stats.title = driver ? driver2TempName(driver.camera_id) : 'DRIVER';
    stats.email = driver ? `${driver.camera_id}@email.com` : 'e-mail';
    stats.photo = driver ? driver2TempPhoto(driver.camera_id) : '/assets/images/cow.jpg';
    return stats;
}

export function stats2Skor(stats: DriverStats2): Skor {
    return {
        title: undefined,
        grade: stats ? getGrade(stats.score) : 0,
        overall: (stats && stats.score && stats.score.overall) || 0,
        focus: (stats && stats.score && stats.score.focus) || 0,
        guard: (stats && stats.score && stats.score.guard) || 0,
        speed: (stats && stats.score && stats.score.speed) || 0,
        email: undefined,
        photo: undefined,
    };
}

export function driver2ScoreSafe(driver: Driver2): Score {
    console.log(driver);
    const score = driver && driver.stats && driver.stats.score;
    console.log('score');
    console.log(score);
    if (!score) {
        return {
            overall: 0,
            focus: 0,
            guard: 0,
            speed: 0,
        };
    }
    
    return {
        overall: score.overall || 0,
        focus: score.focus || 0,
        guard: score.guard || 0,
        speed: score.speed || 0,
    };
}

export function driver2TempVehicle(cameraId?: number) {
    return `Vehicle ${cameraId % 9 + 1}`;
}

export function stringifyLocation(loc: Location | Locatio) {
    return `${loc.lat.toFixed(3)}, ${((loc as Location).lng || (loc as Locatio).lon).toFixed(3)}`;
}

// 'lon,lat' 형태를 파스. 예시) 37.46664,126.89168
export function parseLngLat(loc: string): Location {
    const a: string[] = loc.split(',');
    return {
        lat: +a[0],
        lng: +a[1],
    };
}

export function mileToKm(miles: number) {
    return miles * 1.609344;
}
