import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';

import * as Chart from 'chart.js';
import chartDataLabels from 'chartjs-plugin-datalabels';

@Component({
	selector: 'app-turn-signal-charts',
	templateUrl: './turn-signal-charts.component.html',
	styleUrls: ['./turn-signal-charts.component.scss'],
})

// 📌230103 방향 지시등 작동
export class TurnSignalAppliedComponent implements OnInit, AfterViewInit {
	public chart: any;

	@Input() leftBlinker: any;

	constructor() {}

	ngOnInit() {
		// setTimeout(() => {
		// }, 1000);
		this.turnSignalAppliedChart();
	}

	turnSignalAppliedChart() {
		const chart = new Chart('turnSignalBar', {
			plugins: [chartDataLabels],
			type: 'bar',
			data: {
				datasets: [
					{
						yAxisID: 'bar-stack',
						backgroundColor: 'rgb(217,217,217)',
						hoverBackgroundColor: 'rgb(217,217,217)',
						stack: 'left',
						data: [isNaN(this.leftBlinker.noBlinkerLeft) ? 0 : this.leftBlinker.noBlinkerLeft],
						borderWidth: 1,
						borderColor: 'rgb(217,217,217)',
						barThickness: 60,
						datalabels: {
							color: '#7f7f7f',
							formatter: function (value, ctx) {
								return `${value}%`;
							},
						},
					},
					{
						yAxisID: 'bar-stack',
						backgroundColor: '#0070C0',
						hoverBackgroundColor: '#0070C0',
						stack: 'left',
						data: [isNaN(this.leftBlinker.yesBlinkerLeft) ? 0 : this.leftBlinker.yesBlinkerLeft],
						borderWidth: 1,
						borderColor: '#0070C0',
						barThickness: 60,
						datalabels: {
							color: '#fff',
							formatter: function (value, ctx) {
								if (value === 0) {
									return '';
								} else {
									return `${value}%`;
								}
							},
						},
					},
				],
			},

			options: {
				responsive: false,
				animation: {
					duration: 0,
				},
				tooltips: {
					enabled: false,
				},

				plugins: {
					datalabels: {
						anchor: 'center',
						align: 'center',

						font: {
							size: 20,
						},
					},
				},

				legend: {
					display: false,
					labels: {
						usePointStyle: true,
						padding: 30,
					},
					position: 'bottom',
					align: 'center',
				},

				layout: {
					padding: 0,
				},

				scales: {
					yAxes: [
						{
							id: 'bar-stack',
							stacked: true,
							gridLines: {
								display: false,
							},
							ticks: {
								beginAtZero: true,
								display: false,
							},
						},
					],
					xAxes: [
						{
							gridLines: {
								display: false,
							},
							ticks: {
								beginAtZero: true,
							},
						},
					],
				},
			},
		});
	}

	ngAfterViewInit() {}
}
