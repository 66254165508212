import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'convertText'
})
export class TextPipe implements PipeTransform {
    transform(value: string): string {
        let text = '';
        if (!!value) {
            text=value[0];
            for (let index = 1; index < value.length; index++) {
                text+=value[index].toLocaleLowerCase();
                
            }
        }
        return text;
    }
}

@Pipe({
    name: 'convertString'
})
export class ConvertStringPipe implements PipeTransform {
    transform(value: any): string {
        let text = '';
        if (!!value) {
            text=String(value);
        }
        return text;
    }
}
