import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';

import * as Chart from 'chart.js';
import chartDataLabels from 'chartjs-plugin-datalabels';

@Component({
	selector: 'app-collision-danger-charts',
	templateUrl: './collision-danger-charts.component.html',
	styleUrls: ['./collision-danger-charts.component.scss'],
})

// 📌230104 리뉴얼 운전자 리포트 - 전방충돌 발생위험 bar chart
export class CollisionDangerChartComponent implements OnInit, AfterViewInit {
	public chart: any;

	@Input() driverTtcData: any;

	constructor() {}

	ngOnInit() {
		// setTimeout(() => {
		// }, 1000);
		this.collisionDangerChart();
	}

	collisionDangerChart() {
		const chart = new Chart('collisionDangerBar', {
			plugins: [chartDataLabels],

			type: 'bar',
			data: {
				labels: ['고위험', '중위험', '위험'],
				datasets: [
					{
						data: [
							this.driverTtcData.ttcVeryDangerous,
							this.driverTtcData.ttcFstWarning,
							this.driverTtcData.ttcLowDangerous,
						],
						// backgroundColor: ['rgb(242,242,242)', 'rgb(242,152,161)', 'rgb(235,99,112)'],
						backgroundColor: ['rgb(235,99,112)', 'rgb(242,152,161)', 'rgb(247,156,156)'],
						barThickness: 50,
						fill: true,
						datalabels: {
							align: 'top',
							anchor: 'end',
							display: false,
						},
					},
				],
			},

			options: {
				responsive: false,
				animation: {
					duration: 0,
				},
				tooltips: {
					enabled: false,
				},
				plugins: {
					labels: {
						render: 'value',
						fontSize: '0',
						fontColor: '#000',
					},
					datalabels: {
						color: '#000',
						font: {
							size: 18,
						},
						align: 'center',
						formatter: function (value, ctx) {
							return `상위\n${value}%`;
						},
					},
				},
				layout: {
					// padding: 20,
					padding: {
						top: 10,
					},
				},

				legend: {
					display: false,
					labels: {
						boxWidth: 10,
						fontColor: '#000',
					},
					position: 'right',
					align: 'center',
				},

				scales: {
					yAxes: [
						{
							gridLines: {
								display: false,
							},
							ticks: {
								beginAtZero: true,
								display: false,
								max: 100,
							},
						},
					],
					xAxes: [
						{
							gridLines: {
								display: true,
								drawOnChartArea: false,
								tickMarkLength: 0,
								lineWidth: 2,
								color: '#d9d9d9',
								zeroLineWidth: 2,
							},
							ticks: {
								display: true,
								fontColor: '#000',
								beginAtZero: true,
								fontSize: 18,
								padding: 16,
							},
						},
					],
				},
			},
		});
	}

	ngAfterViewInit() {}
}
