import { Component, OnInit, Input, ChangeDetectionStrategy, OnDestroy } from '@angular/core';

import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AppContextService } from 'src/app/app-context.service';

// service
import { AlertService } from 'src/app/service/alert.service';
import { CustomTranslateService } from 'src/app/service/customTranslate.service';

@Component({
	selector: 'app-drivers-table-contents',
	templateUrl: './drivers-table-contents.component.html',
	styleUrls: ['./drivers-table-contents.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriversTableContentsComponent implements OnInit, OnDestroy {
	@Input() date: string;
	@Input() table_0: any;
	@Input() table_1: any;
	@Input() table_2: any;
	@Input() table_more: any;

	subs = new Subscription();
	lang = '';
	transObj = {};

	driversData: any;
	drivers = [];

	isIndustry: boolean = false;

	constructor(
		public appContext: AppContextService,
		public alertService: AlertService,
		private spinner: Ng4LoadingSpinnerService,
		private customTranslateService: CustomTranslateService
	) {}

	ngOnInit() {
		if (localStorage.getItem('industry_id') === '연비절감사업') {
			this.isIndustry = true;
		}

		this.subs.add(
			this.customTranslateService
				.getLang()
				.pipe(
					switchMap((value) => {
						const defaultLang = localStorage.getItem('defaultLng');
						if (!!this.lang) {
							if (this.lang !== defaultLang) {
								this.spinner.show();
							}
						}

						this.lang = value ? value : 'ko';
						return this.customTranslateService.sendJson(this.lang);
					})
				)
				.subscribe((value) => {
					this.transObj = value;
				})
		);
	}

	// 숫자 포맷
	formatNum(value: any) {
		return Intl.NumberFormat().format(value);
	}

	ngOnDestroy() {
		this.subs.unsubscribe();
	}
}
