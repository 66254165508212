import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { AnyService } from '../../service/any.service'

interface BarRange {
    min: number;
    max: number;
    color: string;
}
export interface VerticalBarChartRange {
    reportDate: string;
    ranges: BarRange[];
}

@Component({
    selector: 'app-vertical-bar-range-chart',
    templateUrl: './vertical-bar-range-chart.component.html',
    styleUrls: ['./vertical-bar-range-chart.component.scss']
})
export class VerticalBarRangeChartComponent implements OnInit, OnChanges {

    isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    @Input() svgTitle = '';
    @Input() svgHeight = 500;
    @Input() svgWidth = 960;
    @Input() data: VerticalBarChartRange[] = [];
    @Input() barWidth = 20;
    @Input() maxValue = 1;
    @Input() minValue = 0;
    @Input() oneBar = false;
    tooltipYPosition1 = 0;
    tooltipYPosition2 = 0;

    private origMaxValue = 1;
    private origMinValue = 1;
    public currentUnit: string;

    yArr = [];
    yLength = 0;
    xlenght = 0;

    constructor(private anyService: AnyService) {
        this.tooltipYPosition1 = this.isSafari ? -25 : 10;
        this.tooltipYPosition2 = this.isSafari ? -15 : 10;
    }

    ngOnInit() {
        this.updateGraph();
        const unit = this.anyService.getCurrentUnit();
        this.currentUnit = unit;
        if (unit !== this.currentUnit) {
            this.currentUnit = unit;
        }
        this.anyService.currentUnitEmitter.subscribe(u => {
            this.currentUnit = u;
          });
    }

    public updateGraph() {
        this.origMaxValue = this.maxValue;
        this.origMinValue = this.minValue;
        this.maxValue = this.getMaxValue(this.data);
        this.minValue = this.getMinValue(this.data);
        this.yArr = this.getYAxis(this.maxValue - this.minValue);
        this.yLength = (this.svgHeight - 50) / this.yArr.length;
        if (this.data.length > 0) {
            // console.log(this.data)
            this.xlenght = this.svgWidth / this.data.length;
        }
         this.anyService.currentUnitEmitter.subscribe(u => {
      this.currentUnit = u;
    });
    }

    ngOnChanges() {
        this.updateGraph();
    }

    unitConversion(unit, value) {
        // let v = this.anyService.unitConversion(unit, value);
        // console.log(v)
        return this.anyService.unitConversion(unit, value);
    }



    getMaxValue(data: VerticalBarChartRange[]) {
        const arr = [];
        data.map(r => {
            if (r.ranges.length > 0) {
                arr.push(r.ranges.reduce((a, b) => (a.max > b.max) ? a : b).max);
            }
        });
        if (arr.length < 1) {
            return this.maxValue;
        }
        const max = arr.reduce((a, b) => (a > b) ? a : b);
        return Math.max(max, this.maxValue);
    }

    getMinValue(data: VerticalBarChartRange[]) {
        const arr = [];
        data.map(r => {
            if (r.ranges.length > 0) {
                arr.push(r.ranges.reduce((a, b) => (a.min < b.min) ? a : b).min);
            }
        });
        if (arr.length < 1) {
            return this.minValue;
        }
        const min = arr.reduce((a, b) => (a < b) ? a : b);
        return Math.min(min, this.minValue);
    }

    getYAxis(value: number) {
        const arr = [];
        const countTicks = 6;
        const step = value / countTicks;
        for (let i = 0; i <= countTicks; i++) {
            arr.push(Math.round(step * i + this.minValue));
        }
        // while (value >= 0) {
        //     arr.push(value);
        //     value = value - 10;
        // }
        return arr.reverse();
    }

    getHeightAndYPositionRect(range: BarRange) {
        const minY = this.getYFromValue(range.min);
        const maxY = this.getYFromValue(range.max);
        return [minY - maxY, maxY];
    }

    getYFromValue(value: number) {
        const svgHeight_ex = this.svgHeight - 82;
        const height = svgHeight_ex * value / (this.maxValue - this.minValue);
        return svgHeight_ex - height + 33 + this.minValue;
    }
}
