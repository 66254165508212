import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import {
	ChartComponent,
	ApexAxisChartSeries,
	ApexChart,
	ApexTitleSubtitle,
	ApexXAxis,
	ApexNonAxisChartSeries,
	ApexPlotOptions,
	ApexAnnotations,
	ApexStroke,
	ApexFill,
	ApexTooltip,
	ApexResponsive,
	ApexYAxis,
	ApexGrid,
	ApexStates,
	ApexTheme,
	ApexDataLabels,
	ApexLegend,
} from 'ng-apexcharts';

export type ChartOptions = {
	series: ApexAxisChartSeries | ApexNonAxisChartSeries;
	chart: ApexChart;
	xaxis: ApexXAxis;
	yaxis: ApexYAxis | ApexYAxis[];
	title: ApexTitleSubtitle;
	colors: string[];
	dataLabels: ApexDataLabels;
	plotOptions: ApexPlotOptions;
	annotations: ApexAnnotations;
	stroke: ApexStroke;
	labels: string[];
	fill: ApexFill;
	tooltip: ApexTooltip;
	responsive: ApexResponsive[];
	grid: ApexGrid;
	states: ApexStates;
	subtitle: ApexTitleSubtitle;
	theme: ApexTheme;
	legend: ApexLegend;
};

@Component({
	selector: 'app-mileage-charts',
	templateUrl: './apx-mileage-charts.component.html',
	styleUrls: ['./apx-mileage-charts.component.scss'],
})

// 📌221222 리뉴얼 운전자 리포트 - 총 주행거리
export class ApxMileageChartComponent implements OnInit, AfterViewInit {
	@ViewChild('charts') charts: ChartComponent;
	public chartOptions: Partial<ChartOptions>;

	@Input() chart: ApexChart;
	@Input() annotations: ApexAnnotations;
	@Input() colors: string[];
	@Input() dataLabels: ApexDataLabels;
	@Input() series: ApexAxisChartSeries | ApexNonAxisChartSeries;
	@Input() stroke: ApexStroke;
	@Input() labels: string[];
	@Input() legend: ApexLegend;
	@Input() fill: ApexFill;
	@Input() tooltip: ApexTooltip;
	@Input() plotOptions: ApexPlotOptions;
	@Input() responsive: ApexResponsive[];
	@Input() xaxis: ApexXAxis;
	@Input() yaxis: ApexYAxis | ApexYAxis[];
	@Input() grid: ApexGrid;
	@Input() states: ApexStates;
	@Input() title: ApexTitleSubtitle;
	@Input() subtitle: ApexTitleSubtitle;
	@Input() theme: ApexTheme;

	@Input() type: string;
	@Input() companyMileageData: any;
	@Input() companyTripTimeData: any;
	@Input() driverMileageData: any;
	@Input() driverTripTimeData: any;

	constructor() {}

	ngOnInit() {
		// setTimeout(() => {
		// }, 1000);
		this.createChart();
	}

	createChart() {
		if (this.type === 'distance') {
			this.chartOptions = {
				series: [
					{
						data: [
							this.driverMileageData.driverMileage,
							...this.companyMileageData.company_mileage,
						],
					},
				],

				chart: {
					height: 100,
					type: 'bar',
					offsetY: 0,
					offsetX: 0,
					width: 400,
					// foreColor: '#373d3f',
					toolbar: {
						show: false,
					},
					animations: {
						enabled: false,
					},
				},
				states: {
					hover: {
						filter: {
							type: 'none',
						},
					},
				},
				plotOptions: {
					bar: {
						horizontal: true,
						borderRadius: 0,
						barHeight: '100%',
						distributed: true,
						dataLabels: {
							position: 'bottom',
						},
					},
				},
				tooltip: {
					enabled: false,
				},
				legend: {
					show: false,
					position: 'left',
					floating: false,
				},
				colors: ['#01b1ee', '#d9d9d9'],

				dataLabels: {
					enabled: false,
					textAnchor: 'start',
					offsetX: 0,
					offsetY: 0,
					style: {
						fontSize: '12px',
						fontWeight: '400',
						colors: ['#01b1ee', '#d9d9d9'],
					},
					formatter: function (val: any, opt: any) {
						const format = new Intl.NumberFormat().format(val);
						return `${format} km`;
					},
				},
				grid: {
					position: 'back',
					strokeDashArray: 0,
					show: false,

					padding: {
						top: 0,
						right: 10,
						bottom: 0,
						left: 10,
					},

					xaxis: {
						lines: {
							show: false,
							offsetX: 0,
							offsetY: 0,
						},
					},
					yaxis: {
						lines: {
							show: false,
						},
					},
				},
				annotations: {
					xaxis: [
						{
							x: 0,
							strokeDashArray: 0,
							borderColor: '#fff',
							borderWidth: 2,
							opacity: 1,
						},
					],
				},
				xaxis: {
					categories: [' '],
					labels: {
						show: false,
						style: {
							colors: ['#0000ff', '#bfbfbf'],
							fontSize: '15px',
						},
					},
					axisTicks: {
						show: false,
					},
				},
				yaxis: {
					show: true,
					tooltip: {
						enabled: true,
					},

					labels: {
						show: true,
						style: {
							colors: [],
							fontSize: '12px',
							fontWeight: 'bold',
						},
					},
				},
			};
		} else {
			this.chartOptions = {
				series: [
					{
						data: [
							this.driverTripTimeData.driverTripTime,
							...this.companyTripTimeData.company_triptime,
						],
					},
				],

				chart: {
					height: 100,
					type: 'bar',
					offsetY: 0,
					offsetX: 0,
					width: 400,
					// foreColor: '#373d3f',
					toolbar: {
						show: false,
					},
					animations: {
						enabled: false,
					},
				},
				states: {
					hover: {
						filter: {
							type: 'none',
						},
					},
				},
				plotOptions: {
					bar: {
						horizontal: true,
						borderRadius: 0,
						barHeight: '100%',
						distributed: true,
						dataLabels: {
							position: 'bottom',
						},
					},
				},
				tooltip: {
					enabled: false,
				},
				legend: {
					show: false,
					position: 'left',
					floating: false,
				},
				colors: ['#01b1ee', '#d9d9d9'],

				dataLabels: {
					enabled: false,
					textAnchor: 'start',
					offsetX: 285,
					offsetY: 0,
					style: {
						fontSize: '12px',
						fontWeight: '400',
						colors: ['#01b1ee', '#d9d9d9'],
					},
					formatter: function (val: any, opt: any) {
						const format = new Intl.NumberFormat().format(val);
						return `${format} 분`;
					},
				},
				grid: {
					position: 'back',
					strokeDashArray: 0,
					show: false,

					padding: {
						top: 0,
						right: 10,
						bottom: 0,
						left: 10,
					},

					xaxis: {
						lines: {
							show: false,
							offsetX: 0,
							offsetY: 0,
						},
					},
					yaxis: {
						lines: {
							show: false,
						},
					},
				},
				annotations: {
					xaxis: [
						{
							x: 0,
							strokeDashArray: 0,
							borderColor: '#fff',
							borderWidth: 2,
							opacity: 1,
						},
					],
				},
				xaxis: {
					categories: [' '],
					labels: {
						show: false,
						style: {
							colors: ['#0000ff', '#bfbfbf'],
							fontSize: '15px',
						},
					},
					axisTicks: {
						show: false,
					},
				},
				yaxis: {
					show: true,
					tooltip: {
						enabled: true,
					},

					labels: {
						show: true,
						style: {
							colors: [],
							fontSize: '12px',
							fontWeight: 'bold',
						},
					},
				},
			};
		}
	}

	ngAfterViewInit() {}
}
