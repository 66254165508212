import { Component, OnInit, Input } from '@angular/core';
import { Driver_info, Trip, Skor, Event } from '../../types/data-types';
import { AppContextService } from '../../app-context.service';
import { DataService } from '../../service/data.service';
import { TripsService } from '../../service/trips.service';

import { Subscription } from 'rxjs';
import { driver2Skor } from '../../types/common';
import { take } from 'rxjs/operators';
import { DaterangeService } from '../../service/daterange.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import * as _ from 'lodash'
import * as moment from 'moment';
import {AlertService} from '../../service/alert.service';
declare const BMap: any;
declare const BMAP_SATELLITE_MAP: any;
@Component({
    selector: 'app-trip-history-page',
    templateUrl: './trip-history-page.component.html',
    styleUrls: ['./trip-history-page.component.scss']
})
export class TripHistoryPageComponent implements OnInit {
    private dateRangeSelectSharedSubscription: Subscription

    // @Input('driver')
    // set setDriver(driver: Driver_info) {
    //     this.driver = driver;
    //     // this.skor = driver2Skor(driver);
    //     // this.skor.overall = Number(driver.overall);
    //     // this.skor.focus = Number(driver.focus);
    //     // this.skor.speed = Number(driver.speed);
    //     // this.skor.guard = Number(driver.guard);
    // }

    @Input('trip_param')
    set setTrips(trip_param: any) {
        this.trips_param = trip_param;
        // this.skor = driver2Skor(driver);
        // this.skor.overall = Number(driver.overall);
        // this.skor.focus = Number(driver.focus);
        // this.skor.speed = Number(driver.speed);
        // this.skor.guard = Number(driver.guard);
    }

    driver: Driver_info;
    skor: Skor;
    callCount = 0;
    trips: Trip[] = [];
    tripEvents: Event[] = [];
    trips_param = {driver_id:'',startDate:'',endDate:''};
    
    tripsSub: Subscription;

    constructor(
        private appContext: AppContextService, 
        private ds: DataService,
        private drs: DaterangeService,
        private tripService: TripsService,
        private spinner: Ng4LoadingSpinnerService,
        private alertService:AlertService
    ) {
        this.tripsSub = this.appContext.trips$.subscribe({
            next: this.onTripsChanged.bind(this)
        });
    }

    ngOnInit() {
        // this.dateRangeSelectSharedSubscription = this.drs.dateRangeSelectShared.subscribe(res => {
        //     console.log('resete callledd')
        
        // });
        // const date = new Date();
        // const startDate = moment(date.setUTCDate(date.getUTCDate() - 6)).format('YYYY-MM-DD HH:mm:ss.SSS');
        // const endDate = moment(date.setUTCDate(date.getUTCDate() + 6)).format('YYYY-MM-DD HH:mm:ss.SSS');
       
        const dataSelect = {driver_id: this.trips_param.driver_id, startDate: this.trips_param.startDate, endDate: this.trips_param.endDate};
        this.rangeApply(dataSelect)
    }
    ngOnDestroy() {
        if (this.dateRangeSelectSharedSubscription) {
            this.dateRangeSelectSharedSubscription.unsubscribe();
        }
    }
    async rangeApply(range) {
        // let camera_id = this.driver.camera_id;
        let camera_id = '';
        const timestampstart = moment.utc(moment(range.startDate).utc().format('YYYY-MM-DD')).valueOf();
        const timestampend = moment.utc(moment(range.endDate).utc().format('YYYY-MM-DD')).valueOf();
        const Drivers_Trips_Query = {
            "query": `\n\nquery listTrip {\n  listTripRangeDriver(driver_id:\"${range.driver_id}\",trip_start_ts:\"${range.startDate}\",trip_end_ts:\"${range.endDate}\")\n  {\n  \t items {\n    company_id\n device_id\n driver_id\n trip_start\n   trip_details\n vehicle_no\n driver_name\n}\n     nextToken\n     }  \n}\n`,
            "variables": "{}",
            "operationName": "listTrip"
        }
        let triplist = [];

        triplist['getDriverById'] = {};
        triplist['getDriverById']['trips'] = [];
        triplist['getDriverById']['camera_id'] = ''
        triplist['getDriverById']['nextToken'] = '';
        triplist['getDriverById']['name'] = ''
        triplist['getDriverById']['startdate'] = ''
        triplist['getDriverById']['enddate'] = range.endDate;
        this.spinner.show();
        this.tripService.getTripslist(Drivers_Trips_Query).subscribe(trip => {
            
            if (trip['data']['listTripRangeDriver']['items'].length > 0) {
                triplist['getDriverById']['nextToken'] = trip['data']['listTripRangeDriver'].nextToken;
                triplist['getDriverById']['name'] = 'Reserved'
                triplist['getDriverById']['startdate'] = range.startDate;
                trip['data']['listTripRangeDriver']['items'].forEach((trip, idx) => {
                // console.log(trip)
                triplist['getDriverById']['camera_id'] = trip.device_id
                triplist['getDriverById']['trips'].push(JSON.parse(trip.trip_details));
                triplist['getDriverById']['trips'][idx].trip_start_ts = trip.trip_start_ts
                // triplist['getDriverById']['trip_start_ts']=trip.trip_start_ts
        
        
                this.tripService.routesTripLists.next(triplist);
                this.spinner.hide();
                // console.log(JSON.parse(trip.trip_details));
                });
                // console.log('trip start date', triplist['getDriverById']['startdate'])
        
        
        
            } else {
                // console.log('else null')
        
                // console.log('next token',data['data']['listTrips'].nextToken)
                triplist['getDriverById']['nextToken'] = null;
                triplist['getDriverById'] = {};
                triplist['getDriverById']['trips'] = [];
                triplist['getDriverById']['camera_id'] = camera_id;
                triplist['getDriverById']['name'] = 'Reserved'
                // triplist['getDriverById']['startdate']=startDate
        
                // triplist['getDriverById']['trips'] = [];
        
                this.spinner.hide();
                this.tripService.routesTripLists.next(triplist);
                
            }
            console.log(triplist['getDriverById']['trips']);
            this.trips = triplist['getDriverById']['trips']
            }, (error) => {
            console.log(error.message, 'getRoutesList');
            this.spinner.hide();
            this.tripService.routesTripLists.next(null);
            triplist['getDriverById']['camera_id'] = camera_id;
            triplist['getDriverById']['name'] = 'Reserved'
            triplist['getDriverById']['startdate'] = range.startDate
        
        
        });
        //this.appContext.tripSelected$.next(trip);
        // const events = await this.appContext.loadTripEvents(861107036576362,range.startDate,range.endDate);
        // this.tripEvents = events;


          // alert 호출
        //   let alertQuery = {};
        //   if (this.nexttoken) {
        //     alertQuery = {
        //       "query": `query getList\n{\n  getEventsList(input:{camera_id:\"${this.cameraId}\",time_stamp:\"${getStartDate}\",end_date:\"${getEndDate}\"}, nextToken:\"${this.nexttoken}\"){\n    items{\n      camera_id\n      time_stamp\n      location\n      trip_start\n      event\n      filepath\n      situation\n  vdo_status\n    }\n    nextToken\n  }\n}`,
        //       "variables": "{}",
        //       "operationName": "getList"
        //     }
        //   } else {
        //     alertQuery = {
        //       "query": `query getList\n{\n  getEventsList(input:{camera_id:\"${this.cameraId}\",time_stamp:\"${getStartDate}\",end_date:\"${getEndDate}\"}){\n    items{\n      camera_id\n      time_stamp\n      location\n      trip_start\n      event\n      filepath\n      situation\n  vdo_status\n    }\n    nextToken\n  }\n}`,
        //       "variables": "{}",
        //       "operationName": "getList"
        //     }
        //   }
      
        //   this.tripService.getAlertEventDetails(alertQuery).pipe(take(1)).subscribe(events => {
        //     // console.log(events['data']['getEventsList']['items'].length)
        //     events['data']['getEventsList']['items'].forEach(trip => {
        //       console.log(trip)
      
        //       this.allTripList.push(trip);
        //       this.allTripList.forEach(tr => {
        //         tr.vdo_status = tr.filepath != "null" ? (tr.vdo_status == null ? 1 : tr.vdo_status) : 0
        //       })
        //       // console.log('trip record', trip)
      
        //     });
      
        //     this.alldata = events['data']['getEventsList']['items'];
      
      
        //     console.log('allData', this.alldata);
        //     this.alldata.forEach(tr => {
        //       tr.vdo_status = tr.filepath != "null" ? (tr.vdo_status == null ? 1 : tr.vdo_status) : 0
        //     })
        //     console.log('alldata after', this.alldata);
        //     let tripslistfinal = uniqBy(this.alldata, function (e) {
        //       return e['trip_start'];
        //     });
        //     console.log('triplist final', tripslistfinal);
      
        //     this.alertTriplist = [...this.alertTriplist, ...tripslistfinal];
        //     this.alertTriplist = uniqBy(this.alertTriplist, function (e) {
        //       return e['trip_start']
        //     })
        //     if (this.selectedID) {
        //       console.log(this.selectedID)
        //       this.selectTrips(this.selectedID, null);
        //     }
      
      
        //     this.previoustoken = this.nexttoken
        //     this.nexttoken = events['data'].getEventsList.nextToken;
      
      
        //     this.spinner.hide();
        //   }, error => { this.spinner.hide(); })
    }
    async onTripsChanged(trips: Trip[]) {
        this.trips = !trips ? [] : trips;
    }
    private _map: any;
    mapOptions: any = {};
    status = '';
    arrFeatures = [];
    tripLocationList = [];
    selectTrip = {}

    async handleClickTrip(trip: Trip) {
        this.tripLocationList = []
        this.spinner.show();
        this.callCount = 1
        this.selectTrip = trip;
        console.log(trip);
        
        var start = trip['location'].source;
        var chinastart = Number(start['lng']) - 10;
        // start = new BMap.Point( start['lng'],start['lat']);
        start = new BMap.Point( chinastart ,start['lat']);
        var end = trip['location'].destination;
        var chinaend = Number(end['lng']) -10
        //end = new BMap.Point( end['lng'], end['lat'],);
        end = new BMap.Point( chinaend, end['lat'],);
        
        this.ds.getTripLatLon(trip).then(route=>{
            console.log(route);
            // console.log('routes===>', route['data']['listTripRange']['items'].length)
            // if (route['data']['listTripRange']['items'].length > 0) {
            //     route['data']['listTripRange']['items'].forEach((trip,idx) => {
            //     // console.log(trip)
            //     console.log('json TEST TEST',JSON.parse(trip.trip_details))

            //     if (JSON.parse(trip.trip_details).location && JSON.parse(trip.trip_details).location.destination.lat != null &&
            //         JSON.parse(trip.trip_details).location.destination.lng != null &&
            //         JSON.parse(trip.trip_details).location.source.lat != null &&
            //         JSON.parse(trip.trip_details).location.source.lng != null) {
            //     } else {
            //         console.log('null location', trip.trip_details.location);
            //     }

            //     this.spinner.hide();
            //     // console.log(JSON.parse(trip.trip_details));
            //     });
            //     console.log('routes===>', route['data']['listTripRange']['items'].length)
            // } else {
            //     // routelist['getDriverById']['nextToken'] = null;
            //     // routelist['getDriverById']['trips'] = [];
            //     // routelist['getDriverById']['camera_id'] = camera_id;
            //     // routelist['getDriverById']['name'] = 'Reserved'
            //     // this.tripService.routesTripLists.next(routelist);
            //     this.spinner.hide();
            // }
        })
        this._map.centerAndZoom(start,20);
        var driving = new BMap.DrivingRoute( this._map, {renderOptions:{map:  this._map, autoViewport: true}});
        var marker = new BMap.Marker(end);
        this._map.addOverlay(marker)
        driving.search(start,end)
        
        console.log(start, end);
        driving.search(start, end)
        console.log(driving);
        this.appContext.tripSelected$.next(trip);
        const events = await this.appContext.loadTripEvents(trip);
        this.tripEvents = events;

        console.log('this.tripEvents',this.tripEvents)
        let map = this._map;
        let opts = {
                    width : 250,     // 信息窗口宽度
                    height: 80,     // 信息窗口高度
                    title : "" , // 信息窗口标题
                    enableMessage:true//设置允许信息窗发送短息
                };
        function addClickHandler(content,marker){
            marker.addEventListener("click",
            function(e){
            console.log('clickeeee');
            openInfo(content,e)
            });
        }
        function openInfo(content,e){
            console.log('open')
            var p = e.target;
            //var point = new BMap.Point(p.getPosition().lng, p.getPosition().lat);
            var date = new Date(Number(content.time_stamp))
            console.log(date);
            var t = content.location.split(',')
            var p4 = new BMap.Point(t[1],t[0]);
            var text = "type : "+ content.event +"<br/> time : " +  moment(date).format('YYYY-MM-DD HH:mm:ss');; 
            var infoWindow = new BMap.InfoWindow(text,opts);  // 创建信息窗口对象 
            
            map.openInfoWindow(infoWindow,p4); //开启信息窗口
        }
        
        for(var i  in this.tripEvents){
            var t = this.tripEvents[i].location.split(',')
            var p4 = new BMap.Point(t[1],t[0]);
            console.log(p4)
            var marker = new BMap.Marker(p4);
            
            
            this._map.addOverlay(marker);
            addClickHandler(this.tripEvents[i],marker);
        }
        
        this.spinner.hide();
    }
    
    async refresh(e){
        console.log(this.selectTrip);
        if(!this.selectTrip['camera_id']){
            this.alertService.alertDialog('no');
          return false
        }
        this.spinner.show();
        let trip:any = this.selectTrip;
        
        const events = await this.appContext.loadTripEvents(trip);
        this.tripEvents = events;
        this.spinner.hide();
    }
    onReady(map: any) {
        this._map = map;

        map.centerAndZoom(new BMap.Point(139.72043,35.63185), 11);
        map.enableScrollWheelZoom(true);
        //map.addControl(new BMap.ZoomControl());
        map.setCurrentCity('北京');
        //var zoomCtrl = ;
        //map.addControl(new BMap.MapTypeControl());
        
        this.status = '상태?';
        map.addEventListener('tilesloaded', () => {
        this.status = 'load complete';
        });
      
    }
}
