import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { ApolloModule, Apollo } from 'apollo-angular';
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';
import { AuthService } from './service/auth.service';

@NgModule({
  exports: [HttpClientModule, ApolloModule, HttpLinkModule],
})
export class GraphQLModule {

  constructor(
    apollo: Apollo,
    httpLink: HttpLink,
    private router: Router,
    private authService: AuthService) {
    const uri = environment.graphqlUrl; // <-- add the URL of the GraphQL server here
    const authLink = setContext((_, { headers }) => {
      // get the authentication token from local storage if it exists
      const token = this.authService.getToken();
      // console.log(token, 'graphQl token');
      // return the headers to the context so httpLink can read them
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : null,
        }
      };
    });

    const linkError = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          ),
        );
      }
      console.log(networkError, 'networkError');
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
        
      }
    });
    const httpLinkWithAuthToken = linkError.concat(authLink);

    apollo.create({
      link: httpLinkWithAuthToken.concat(httpLink.create({ uri })),
      cache: new InMemoryCache(),
      defaultOptions: {
        watchQuery: {
          errorPolicy: 'all'
        }
      }
    });
  }
}
