import {
	Component,
	OnInit,
	ViewChild,
	AfterViewInit,
	Input,
	Output,
	EventEmitter,
	ChangeDetectorRef,
	ChangeDetectionStrategy,
	OnChanges,
	SimpleChanges,
} from '@angular/core';
import { DaterangePickerComponent } from 'ng2-daterangepicker';
import { DaterangeService } from '../../service/daterange.service';
import * as moment from 'moment';
import { inputs } from '@syncfusion/ej2-angular-calendars/src/calendar/calendar.component';
import { DatePickerComponent } from 'ng2-date-picker';
// import { EventEmitte } from 'protractor';

@Component({
	selector: 'app-date-picker',
	templateUrl: './date-picker.component.html',
	styleUrls: ['./date-picker.component.scss'],
})
export class AppDatePickerComponent implements OnInit, AfterViewInit {
	@ViewChild('monthpicker') datePicker: DatePickerComponent;

	config: any = {};
	@Input() date = new Date(); //day,month
	@Input('max')
	set setMax(max) {
		if (max) {
			this.max = max;
			this.config['max'] = this.max;
			this.config['min'] = this.min;
		}
	}
	max = '';
	@Input('min') set setMin(min) {
		if (min) {
			this.min = min;
			this.config['max'] = this.max;
			this.config['min'] = this.min;
		}
	}
	min = '';
	@Input() mode = 'day'; //day,month
	@Output() selectionChangedDate = new EventEmitter<string>();
	@Input() placeholder: string;
	@Input() datepickerType: string;

	@Output() openEvent: EventEmitter<void> = new EventEmitter<void>();
	@Output() closeEvent: EventEmitter<void> = new EventEmitter<void>();

	locationList: any = ['Gwangju', 'Gimpo', 'Daegu', 'Changwon'];
	// [
	//   "Seoul",
	//   "Incheon",
	//   "Gwangju",
	//   "Gimpo",
	//   "Daegu",
	//   "Changwon",
	//   "Busan",
	// ]

	constructor(private drs: DaterangeService) {}
	ngOnInit() {
		if (this.mode == 'day') {
			this.config = {
				disableKeypress: true,
				format: 'YYYY-MM-DD',
				max: this.max,
				min: this.min,
			};
		} else {
			if (
				this.datepickerType &&
				(this.datepickerType === 'drivers' ||
					this.datepickerType === 'fei' ||
					this.datepickerType === 'vehicle')
			) {
				this.config = {
					// showAllButton: true, // "전체" 버튼 표시
					// allButtonText: '전체', // "전체" 버튼 텍스트 설정
					disableKeypress: true,
					format: 'YYYY-MM',
					monthFormat: 'MMM-YYYY',
					max: '',
					min: '',
					// drops: 'up', //  up, down
					// opens: 'left' // left, right
					// allowMultiSelect: false, // 여러 날짜 선택
					closeOnSelectDelay: 0, // 선택, 선택기 접기 사이의 지연
					showGoToCurrent: true,
					enableMonthSelector: false,
					closeOnSelect: true, // 날짜 선택 후 닫기
					hideOnOutsideClick: true,
					locale: 'ko',
				};
			} else {
				this.config = {
					disableKeypress: true,
					format: 'YYYY-MM',
					max: '',
					min: '',
					locale: 'ko',
				};
			}
		}
	}

	public applyDate(e: any) {
		// console.log(e);
		// console.log('max ',this.max)
		// console.log('min',this.min)

		this.config['max'] = this.max;
		this.config['min'] = this.min;
		// console.log(this.config)
		this.selectionChangedDate.emit(e);
		this.date = e;
		// console.log(this.date)
		// console.log(e);
	}

	// public selectAll(e: any) {
	// 	console.log(e);
	// 	this.selectionChangedDate.emit('전체');
	// 	this.date = null;
	// 	this.isShow = false;
	// 	this.datePicker.api.close();
	// 	console.log('123');
	// }

	openDatePicker() {
		// 		this.datePicker.api.open();
		// console.log('open => ');
		// this.openEvent.emit();
	}

	closeDatePicker() {
		// this.datePicker.api.close();
		console.log('close => ');
		// this.closeEvent.emit();
	}

	ngAfterViewInit() {}
}
