import {
	AfterViewInit,
	Component,
	Input,
	OnDestroy,
	OnInit,
	ViewChild,
	ChangeDetectorRef,
} from '@angular/core';

import * as Chart from 'chart.js';
import chartDataLabels from 'chartjs-plugin-datalabels';

@Component({
	selector: 'app-dangerous-driving-count-charts',
	templateUrl: './dangerous-driving-count-charts.component.html',
	styleUrls: ['./dangerous-driving-count-charts.component.scss'],
})

// 📌230109 1-1위험운전 기관 개요(횟수)
export class DangerousDrivingCountChartComponent implements OnInit, AfterViewInit, OnDestroy {
	public chart: any;

	// @Input() dangerousDrivingOrganAvg: any;

	@Input() prevCompanyCountChartData: [];
	@Input() thisCompanyCountChartData: [];
	@Input() industryRiskCount: [];

	constructor(private changeRef: ChangeDetectorRef) {}

	ngOnInit() {
		setTimeout(() => {
			this.dangerousDrivingCountChart();
		}, 1000);
	}

	dangerousDrivingCountChart() {
		const chart = new Chart('countBarChart', {
			plugins: [chartDataLabels],
			type: 'bar',
			data: {
				labels: [
					'차선변경',
					'무단차선이탈',
					'전방충돌위험',
					'급제동',
					'고위험 충격발생',
					// '보호구역위반',
				],
				datasets: [
					// {
					// 	data: this.prevCompanyCountChartData,
					// 	backgroundColor: 'rgb(106,142,208)',
					// 	borderColor: 'rgb(106,142,208)',
					// 	borderWidth: 1,
					// },
					{
						data: this.thisCompanyCountChartData,
						backgroundColor: 'rgb(235,99,112)',
						borderColor: 'rgb(235,99,112)',
						borderWidth: 1,
						barPercentage: 0.5,
					},
					// {
					// 	data: this.industryRiskCount,
					// 	backgroundColor: 'rgb(191,191,191)',
					// 	borderColor: 'rgb(191,191,191)',
					// 	borderWidth: 1,
					// },
				],
			},
			options: {
				plugins: {
					datalabels: {
						color: '#000',
						align: 'top',
						anchor: 'end',
						font: {
							size: 20,
							weight: 500,
						},
						formatter(value, context) {
							const format = new Intl.NumberFormat().format(value);
							return format;
						},
					},
				},
				responsive: false,
				animation: {
					duration: 0,
				},
				tooltips: {
					enabled: false,
				},
				layout: {
					padding: 30,
				},
				legend: {
					display: false,
				},

				scales: {
					yAxes: [
						{
							gridLines: {
								display: false,
							},
							ticks: {
								beginAtZero: true,
								min: 0,
								// max: 250,
								stepSize: 200,
								fontSize: 22,
								fontStyle: '600',
								fontColor: '#000',
								callback(value, index, values) {
									const format = new Intl.NumberFormat().format(Number(value));
									return format;
								},
							},
						},
					],
					xAxes: [
						{
							gridLines: {
								display: false,
							},
							ticks: {
								beginAtZero: true,
								fontSize: 22,
								fontColor: '#000',
							},
						},
					],
				},
			},
		});
	}

	ngOnDestroy(): void {}
	ngAfterViewInit(): void {}
}
