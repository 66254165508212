import { Component, OnInit, Input } from '@angular/core';
import { Label, Color } from 'ng2-charts';
import { Score } from '../../types/data-types';

@Component({
	selector: 'app-skor-chart',
	templateUrl: './skor-chart.component.html',
	styleUrls: ['./skor-chart.component.scss'],
})
export class SkorChartComponent implements OnInit {
	public barChartOptions: any = {
		responsive: true,
		// We use these empty structures as placeholders for dynamic theming.
		scales: {
			xAxes: [
				{
					ticks: {
						min: 0,
						max: 100,
					},
				},
			],
			yAxes: [{}],
		},
	};
	public barChartLabels: Label[] = ['Overall', 'Focus', 'Guard', 'Speed'];
	colors: Color[] = [
		{
			backgroundColor: ['#000000', '#ea533c', '#9fa09c', '#ffffff'],
			borderColor: 'lightgray',
		},
	];
	@Input('score')
	score: Score;
	set setScore(score) {
		console.log('score', 'dddddd', score);
		this.score = score;
		this.chartData.data[0].data = [score.overall, score.focus, score.guard, score.speed];
	}
	//[ { data: [score?.overall, score?.focus, score?.guard, score?.speed], label: "",barPercentage:20, } ]
	chartData = {
		data: [
			{
				label: '',
				data: [5, 5, 5, 5],
				barPercentage: 5,
				//barThickness: 6,
				maxBarThickness: 20,
				minBarLength: 1,
			},
		],
	};

	constructor() {}
	ngOnInit() {
		console.log('dafaef', this.score.overall);
		this.chartData = {
			data: [
				{
					label: '',
					data: [this.score.overall, this.score.focus, this.score.guard, this.score.speed],
					barPercentage: 5,
					//barThickness: 6,
					maxBarThickness: 10,
					minBarLength: 1,
				},
			],
		};
	}
}
