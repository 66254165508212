import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';

import * as Chart from 'chart.js';
// import 'chart.piecelabel.js';
// import * as ChartPiece from 'chart.piecelabel.js'
import 'chartjs-plugin-doughnutlabel';

@Component({
	selector: 'app-protected-doughnut-charts',
	templateUrl: './protected-doughnut-charts.component.html',
	styleUrls: ['./protected-doughnut-charts.component.scss'],
})

// 📌221223 리뉴얼 운전자 리포트 - 보호구역속도위반(발생횟수)
export class ProtectedDoughnutChartComponent implements OnInit, AfterViewInit {
	public chart: any;

	constructor() {}

	ngOnInit() {
		this.protectedDoughnutChart();
	}

	protectedDoughnutChart() {
		const chart = new Chart('protectedDoughnut', {
			type: 'doughnut',
			// speed-limit-donut-charts
			data: {
				labels: ['발생횟수'],
				datasets: [
					{
						type: 'doughnut',
						data: [5],
						borderWidth: 0,
						backgroundColor: ['rgb(106,142,208)'],
					},
				],
			},

			options: {
				animation: {
					duration: 0,
				},

				plugins: {
					labels: {
						fontSize: 0,
						fontColor: ['#fff'],
					},
					doughnutlabel: {
						labels: [
							{
								text: '5',
								font: {
									size: '56',
									weight: '700',
									// units: 'em',
									// family: 'Arial, Helvetica, sans-serif',
									// style: 'italic',
								},
								color: '#000',
							},
						],
					},
				},

				layout: {
					padding: 20,
				},

				responsive: false,
				cutoutPercentage: 80,

				legend: {
					display: true,
					align: 'center',
					position: 'bottom',

					labels: {
						boxWidth: 0,
						usePointStyle: true,
						fontSize: 14,
						fontColor: '#000',
						// fontStyle: '600',
						padding: 20,
					},
				},
				elements: {
					line: {
						fill: false,
					},
				},
			},
		});
	}

	ngAfterViewInit() {}
}
