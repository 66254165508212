export interface Company {
    camera_ids: string;
    name: string;
    drivers: Driver;
    stats: CompanyStats;
    previous_stats: CompanyStats;
    diff: CompanyStats;
    stats_per_day: DayStats;

}

export interface CompanyStats {
    relative_speed: number;
    score: Score;
    mileage: number;
    topDrivers: Driver;
    bottomDrivers: Driver;
    risk: Risk;
    bias: Bias;
    context: Context;
}

export interface DriverCompanyStats {
    relative_speed: number;
    score: Score;
    mileage: number;
    risk: Risk;
    bias: Bias;
    context: Context;
}

export interface DayStats {
    trip_start_day: string;
    relative_speed: number;
    score: Score;
    mileage: number;
    risk: Risk;
    bias: Bias;
    context: Context;
}

export interface Score {
    speed: number;
    focus: number;
    guard: number;
    overall: number;
}

export interface Risk {
    average_collision: number;
    average_departure: number;
    collision_count: number;
    departure_count: number;
}

export interface Bias {
    right: number;
    left: number;
}

export interface Idling {
    idling_count: number;
    front_idling_count: number;
}

export interface Context {
    acceleration_count: number;
    deceleration_count: number;
    stop_count: number;
    average_acceleration: number;
    average_deceleration: number;
    average_stop: number;
}

export interface Driver {
    camera_id: string;
    name: string;
    trips: Trip;
    stats: DriverStats;
    previous_stats: DriverStats;
    diff: DriverStats;
}

export interface Driver_profile {
    driver_id: string;
    age: string;
    gender: string;
    created_at: string;
    email: string;
    first: string;
    last: string;
    location: string;
    mileage: string;
    overall: string;
}

export interface DriverStats {
    relative_speed: number;
    score: Score;
    mileage: number;
    risk: Risk;
    bias: Bias;
    context: Context;
}



export interface TripStats {
    relative_speed: number;
    score: Score;
    mileage: number;
    risk: Risk;
    bias: Bias;
    context: Context;
}

export interface TripLogs {
    event: string;
    distance: number;
    speed: number;
    direction: string;
    reduction_speed: number;
    collision_distance: number;
    ttc: number;
    left_lane: string;
    right_lane: string;
    situation: string;
    reaction: string;
    bias: number;
    front_distance: number;
    front_speed: number;
    front_reduction_speed: number;
    front_reason: string;
    location: Location;
}

export interface Locatio {
    lat: number;
    lon: number;
}

export interface Location {
    lat: number;
    lng: number;
}

export interface Skor {
    id?: string;
    rank?: number;
    title: string;
    grade: number;
    overall: number;
    focus: number;
    guard: number;
    speed: number;
    email?: string;
    registered?: Date;
    mileage?: number;
    location?: string;
    photo?: string;
}
export interface CameraIdHolder {
    camera_id?: number;
}

export interface VideoHolder extends CameraIdHolder {
    time_stamp?: string; // date string
    time_stamp_date?: Date;
    filepath?: string;
    vdo_status?: number;
    url?: string;
    thumbnail?: string;
}

export type VideoEntry = VideoHolder;

export interface VideoEntryBundle extends VideoEntry {
    url?: string;
    level?: string;
    direction? : string;
}

export interface Event extends VideoHolder {
    location?: string;
    trip_start?: string; // date string
    event?: string;
    subEvent?:{time:number,event:string}[];
    situation?: string;
}

export interface DriverStats2 {
    mileage?: number | string;
    relative_speeds?: {
        min: string;
        max: string;
    };
    idling?: Idling;
    score?: Score;
    risk?: Risk;
    bias?: Bias;
    context?: Context;
}

export interface Driver2 {
    camera_id?: string;
    driver_id?: string;
    name?: string;
    ranger?: string;
    firmware_ver?: string;
    diff?: DriverStats2;
    stats?: DriverStats2;
    realTimeNullData?: boolean;
    realTime?: any; // ?
    location?: string;
}
export interface Driver_info {
    driver_id?: string;
    company_id?: string;
    location?: string;
    created_at?: string;
    trip_time?: string;
    email?: string;
    first?: string;
    gender?: string;
    lang?: string;
    focus?: number;
    guard?: number;
    speed?: number;
    overall?: number;
    last?: string;
    rent_count?: string;
    age?: string;
    mileage?: string;
    realTime?: any; // ?
    rank?: string;
    total_count?: number;
}

export interface DriverStatsData {
    previous: Driver2[];
    current: Driver2[];
}
export enum VideoStatus {
    NotAvailable = 0,
    ReadyForRequest = 1, // 요청 준비
    Processing = 2, // 상태 확인 필요
    Failure = 3, // 업로드 중
    ReadyForView = 4, // 재생 가능
    Error = -1,
}

export const VideoStatusTextMap = {
    [VideoStatus.NotAvailable]: 'Not Available',
    [VideoStatus.ReadyForRequest]: 'Request',
    [VideoStatus.Processing]: 'Processing...',
    [VideoStatus.Failure]: 'Failure',
    [VideoStatus.ReadyForView]: 'View',
    [VideoStatus.Error]: 'Error',
};
export interface MessageResponseBase {
    status: 'success' | 'error';
}

export interface CameraStatus extends MessageResponseBase {
    message: { 
        video_url: string,
        video_url2: string,
        thumbnail_url: string
    };
}

export interface DateRange {
    start?: Date;
    end?: Date;
    eventSource?: any;
}

export interface Trip {
    camera_id?: number;
    device_id?: number;
    vehicle_no?: number;
    trip_details?: string; // jsonify된 Trip.
    trip_start_ts?: number;
    trip_start?: string; // date string
    trip_end?: string; // date string
    location?: TripLocation;
    stats?: TripStats;
    trip_logs: TripLogs;
    eventCount?: number;
    alert_count?: number,
    trip_details_parsed?: {
        stats?: TripStats,
        location?: TripLocation,
    };
    nevents: any;
}

// export interface Trip {
//     camera_id?: string;
//     trip_start?: string;
//     stats: TripStats;
    
// }
export interface TripLocation {
    source?: Locatio;
    destination?: Locatio;
}
type LatLon = [string, string];
type StrPair = [string, string];

export interface TripDetails {
    gps_points: LatLon[];
    events: StrPair[];
}
export enum Factor {
    Overall = 'overall',
    Focus = 'focus',
    Guard = 'guard',
    Speed = 'speed',
}

export enum FactorColor {
    Overall = '#3cf2a4',
    Focus = '#008ee0',
    Guard = '#ed22ff',
    Speed = '#33e4f4',
}

export interface Address {
    location: string;
    address: string;
    country: string;
}

// just an interface for type safety.
export interface marker {
    camera_id: String;
    driver_id: String;
    driver_name: String;
	lat: number;
	lng: number;
	label?: string;
    draggable?: boolean;
    rotation: string;
    icon: string;
    width: number;
    height: number;
}
//reports
export interface driverProfile {
    driver_id: String;
	gender: number;
	phone_number: number;
	created_at: string;
	reg_dt: string;
    usage: number;
    mileage: string;
    first: string;
    last: string;
    email: string;
    overall: string;
    focus: string;
    guard: string;
    speed: string;
    location: string;
    rank: string;
    rent_count: number;
    trip_time: string;

}
//risk factor item  score
// 항목 추가되면 AWS Lambda - carvi_distributions_company 확인필요.
export interface riskFactorItemScore {
    company_id              : String;
    eventflow_guard_skor    : String;
    relative_speed_skor     : String;
    safety_guard_skor       : String;
    skor_bias_std           : String;
    skor_dangerous_change   : String;
    speed_moment_skor       : String;
    speed_trip_conti        : String;
    ldws_react_skor        : String;
    fcws_react_skor        : String;
    speed_diff_skor        : String;
}

//risk factor trend
export interface riskFactorTrend {
    skor_dangerous_change?   : trendChartData[];
    skor_bias_std?           : trendChartData[];
    safety_guard_skor?       : trendChartData[];
    eventflow_guard_skor?    : trendChartData[];
    speed_trip_conti?        : trendChartData[];
    speed_moment_skor?       : trendChartData[];
    relative_speed_skor?     : trendChartData[];
    ldws_react_skor?     : trendChartData[];
    fcws_react_skor?     : trendChartData[];
    speed_diff_skor?     : trendChartData[];
}

export interface trendChartData {
    date_key: string;
    score: number;
}
export interface Ouput{
    status:string;
    distributions:Distributions
};
export interface Distributions{
    eventflow_guard_skor:string;
    relative_speed_skor:string;
    safety_guard_skor:string;
    skor_bias_std:string;
    skor_dangerous_change:string;
    speed_moment_skor:string;
    speed_trip_conti:string;
    ldws_react_skor:string;
    fcws_react_skor:string;
    speed_diff_skor:string;
}
export interface SiteController{
    company_Id:string;
    company_name:string;
    readonly:boolean;
}
