import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import {
	ChartComponent,
	ApexAxisChartSeries,
	ApexChart,
	ApexTitleSubtitle,
	ApexXAxis,
	ApexNonAxisChartSeries,
	ApexPlotOptions,
	ApexAnnotations,
	ApexStroke,
	ApexFill,
	ApexTooltip,
	ApexResponsive,
	ApexYAxis,
	ApexGrid,
	ApexStates,
	ApexTheme,
	ApexDataLabels,
	ApexLegend,
} from 'ng-apexcharts';

export type ChartOptions = {
	series: ApexAxisChartSeries | ApexNonAxisChartSeries;
	chart: ApexChart;
	xaxis: ApexXAxis;
	yaxis: ApexYAxis | ApexYAxis[];
	title: ApexTitleSubtitle;
	colors: string[];
	dataLabels: ApexDataLabels;
	plotOptions: ApexPlotOptions;
	annotations: ApexAnnotations;
	stroke: ApexStroke;
	labels: string[];
	fill: ApexFill;
	tooltip: ApexTooltip;
	responsive: ApexResponsive[];
	grid: ApexGrid;
	states: ApexStates;
	subtitle: ApexTitleSubtitle;
	theme: ApexTheme;
	legend: ApexLegend;
};

@Component({
	selector: 'app-semiDonut-charts',
	templateUrl: './apx-semiDonut-charts.component.html',
	styleUrls: ['./apx-semiDonut-charts.component.scss'],
})

// 📌221222 리뉴얼 운전자 리포트 - 반도넛 차트
export class ApxSemiDonutChartComponent implements OnInit, AfterViewInit {
	@ViewChild('charts') charts: ChartComponent;
	public chartOptions: Partial<ChartOptions>;

	@Input() chart: ApexChart;
	@Input() annotations: ApexAnnotations;
	@Input() colors: string[];
	@Input() dataLabels: ApexDataLabels;
	@Input() series: ApexAxisChartSeries | ApexNonAxisChartSeries;
	@Input() stroke: ApexStroke;
	@Input() labels: string[];
	@Input() legend: ApexLegend;
	@Input() fill: ApexFill;
	@Input() tooltip: ApexTooltip;
	@Input() plotOptions: ApexPlotOptions;
	@Input() responsive: ApexResponsive[];
	@Input() xaxis: ApexXAxis;
	@Input() yaxis: ApexYAxis | ApexYAxis[];
	@Input() grid: ApexGrid;
	@Input() states: ApexStates;
	@Input() title: ApexTitleSubtitle;
	@Input() subtitle: ApexTitleSubtitle;
	@Input() theme: ApexTheme;

	@Input() type: string;
	@Input() skorChangeWoTsColors: string[];
	@Input() skorChangeWTsColors: string[];
	@Input() safetyAndEventflowGuardSkorColors: string[];
	@Input() speedTripContiColors: string[];
	@Input() speedMomentSkorColors: string[];
	@Input() suddenIndexAndSpeedSkorAccelColors: string[];

	constructor() {}

	ngOnInit() {
		this.createChart();
	}

	createChart() {
		// 무단차선변경 skorChangeWoTsColors
		// 급속차선변경 skorChangeWTsColors
		// 안전거리 safetyAndEventflowGuardSkorColors
		// 제한속도위반시간 speedTripContiColors
		// 제한속도위반범위 speedMomentSkorColors
		// 방어운전 suddenIndexAndSpeedSkorAccelColors

		this.chartOptions = {
			series: [10, 10, 10, 10, 10, 10, 10],
			chart: {
				width: 400,
				type: 'donut',
				offsetX: 0,
				offsetY: 0,
				toolbar: {
					show: false,
				},
				animations: {
					enabled: false,
				},
			},
			tooltip: {
				enabled: false,
			},

			annotations: {},
			plotOptions: {
				pie: {
					startAngle: -120,
					endAngle: 120,

					donut: {
						size: '75%',
					},
				},
			},
			states: {
				hover: {
					filter: {
						type: 'none',
					},
				},
			},
			stroke: {
				show: true,
				width: 4,
				colors: ['#fff'],
			},
			legend: {
				show: false,
			},

			colors:
				this.type === '무단차선변경'
					? this.skorChangeWoTsColors
					: this.type === '급속차선변경'
					? this.skorChangeWTsColors
					: this.type === '안전거리'
					? this.safetyAndEventflowGuardSkorColors
					: this.type === '제한속도 위반시간'
					? this.speedTripContiColors
					: this.type === '제한속도 위반범위'
					? this.speedMomentSkorColors
					: this.type === '방어운전'
					? this.suddenIndexAndSpeedSkorAccelColors
					: [],

			dataLabels: {
				enabled: false,
			},
			xaxis: {
				labels: {
					show: false,
				},
			},
			yaxis: {
				show: false,
			},
		};
	}

	ngAfterViewInit() {}
}
