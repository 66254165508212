import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'myDatePipe'
})
export class DatePipePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    console.log(value)
    // console.log(new Date(value).getTimezoneOffset())
    const localDate = this.addMinutes(value, new Date(value).getTimezoneOffset())
    // console.log((localDate))
    const day = moment(value).format('Do');
    // console.log('get month name', value.toString());
    // console.log(new Date(value).getUTCDate())
    const month = new Date(localDate).getUTCMonth();
    // console.log(month)
    const year = moment(new Date(value).getFullYear());
    // console.log('year',year)
    return day + ' ' + this.getMonthName(month) + ' ' + year;
  }

  getMonthName(index: number): string {
    // let monthes = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ];
    //const localStorage.getItem('lan')
    const monthes = [
      { index: 1, name: 'Jan' },
      { index: 2, name: 'Feb' },
      { index: 3, name: 'Mar' },
      { index: 4, name: 'Apr' },
      { index: 5, name: 'May' },
      { index: 6, name: 'Jun' },
      { index: 7, name: 'Jul' },
      { index: 8, name: 'Aug' },
      { index: 9, name: 'Sep' },
      { index: 10, name: 'Oct' },
      { index: 11, name: 'Nov' },
      { index: 12, name: 'Dec' },
    ];


    console.log('date pipe===========>', monthes[index].name)

    return monthes[index].name;
  }

  addMinutes(date, minutes) {

    if (minutes < 0) {
      return ((new Date(date)).getTime() - minutes * 60000);
    }
    else {
      // return ((new Date(date)).getTime() + minutes * -60000); // correct last month
          return ((new Date(date)).getTime() + minutes * 60000); // correct custom

    }



  }

}
