import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import * as Chart from 'chart.js';
// import { Chart } from 'chart.js';
import 'chartjs-plugin-annotation';

@Component({
	selector: 'app-departure-count-charts',
	templateUrl: './departure-count-charts.component.html',
	styleUrls: ['./departure-count-charts.component.scss'],
})

// 📌230111 전체 운전자 차로이탈경보/횟수 차트
export class DepartureCountChartComponent implements OnInit, OnDestroy {
	public chart: any;

	constructor() {}

	ngOnInit(): void {
		this.departureCountChart();
	}

	departureCountChart() {
		const labels = [
			'김ㅇㅇ',
			'이ㅇㅇ',
			'김ㅇㅇ',
			'강ㅇㅇ',
			'구ㅇㅇ',
			'김ㅇㅇ',
			'백ㅇㅇ',
			'김ㅇㅇ',
			'최ㅇㅇ',
			'박ㅇㅇ',
		];

		const average = (ctx: any) => {
			const values = ctx.chart.data.datasets[0].data;
			return values.reduce((a: any, b: any) => a + b, 0) / values.length;
		};

		// const annotation = {
		// 	id: 'a-line-1',
		// 	type: 'line',
		// 	mode: 'vertical',
		// 	scaleID: 'y-axis-0',
		// 	// value: 5,
		// 	borderColor: '#fbfb9c',
		// 	borderWidth: 4,
		// 	borderDashOffset: 0,
		// 	label: {
		// 		enabled: false,
		// 	},
		// 	value: (ctx: any) => String(average(ctx)),
		// };

		const chart = new Chart('departureBarChart', {
			type: 'horizontalBar',
			data: {
				labels: labels,
				datasets: [
					{
						data: [10, 17, 8, 20, 12, 16, 27, 4, 9, 30],
						backgroundColor: 'rgb(235,106,118)',
						hoverBackgroundColor: 'rgb(235,106,118)',
						borderColor: 'rgb(235,106,118)',
						borderWidth: 0,
						fill: false,
					},
					{
						data: [17, 10, 18, 10, 4, 26, 17, 10, 3, 20],
						backgroundColor: 'rgb(113,147,210)',
						hoverBackgroundColor: 'rgb(113,147,210)',
						borderColor: 'rgb(113,147,210)',
						borderWidth: 0,
						fill: false,
					},
				],
			},

			options: {
				responsive: false,
				animation: {
					duration: 0,
				},
				annotation: {
					annotations: [
						{
							type: 'line',
							mode: 'vertical',
							drawTime: 'afterDatasetsDraw',
							id: 'a-line-1',
							scaleID: 'x-axis-0',
							value: '5',
							// endValue: '10',
							borderColor: '#fbfb9c',
							borderWidth: 4,
							borderDashOffset: 0,
							label: {
								enabled: false,
							},
						},
					],
				},

				tooltips: {
					enabled: false,
				},
				legend: {
					display: false,
				},
				layout: {
					padding: 0,
				},
				scales: {
					yAxes: [
						{
							stacked: false,

							gridLines: {
								display: false, // false
								color: 'transparent',
								zeroLineColor: '#d9d9d9',
								zeroLineWidth: 2,
								drawTicks: false,
							},
							ticks: {
								display: true,
								beginAtZero: true,
								// min: 0,
								// max: 100,
								// stepSize: 20,
								fontSize: 13,
								fontColor: '#000',
								padding: 20,
							},
						},
					],
					xAxes: [
						{
							stacked: false,

							gridLines: {
								display: true,
								color: 'transparent',
								zeroLineColor: '#d9d9d9',
								zeroLineWidth: 2,
								drawTicks: false,
							},
							ticks: {
								display: false, // false
								beginAtZero: true,
								fontSize: 16,
								fontColor: '#000',
							},
						},
					],
				},
			},
		});
	}

	ngOnDestroy(): void {}
}
