import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { Subject, Observable, BehaviorSubject, timer, Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

import {
	CognitoUserPool,
	CognitoUserAttribute,
	CognitoUser,
	AuthenticationDetails,
	CognitoUserSession,
} from 'amazon-cognito-identity-js';

import { User } from '../pages/auth/user.model';
import { map, repeatWhen, takeUntil } from 'rxjs/operators';
import { IndexedDBService } from './indexedDB.service';

let POOL_DATA = {
	UserPoolId: environment.POOL_ID, // 'POOL_ID'
	ClientId: environment.APP_CLIENT_ID, // 'APP_CLIENT_ID'
};
let userPool = new CognitoUserPool(POOL_DATA);

@Injectable({
	providedIn: 'root',
})
export class AuthService implements OnDestroy {
	authIsLoading = new BehaviorSubject<boolean>(false);
	authDidFail = new BehaviorSubject<boolean>(false);
	authStatusChanged = new Subject<boolean>();
	isLoggedIn$ = new BehaviorSubject<boolean>(false);
	errMessage = new Subject<any>();
	sessionTime$ = new BehaviorSubject<number>(null);
	current_token_time = 0;
	registeredUser: CognitoUser;
	currentUrl = '';
	sw = false;
	subs = new Subscription();
	private readonly _stop = new Subject<void>();
	private readonly _start = new Subject<void>();
	private token: string = null;

	constructor(
		private router: Router,
		private apollo: Apollo,
		private http: HttpClient,
		private indexedDBService: IndexedDBService
	) {
		this.token = localStorage.getItem('token');
		if (localStorage.getItem('UserPoolId') && localStorage.getItem('ClientId')) {
			this.updatePoolData(localStorage.getItem('UserPoolId'), localStorage.getItem('ClientId'));
		}
	}
	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}
	public updatePoolData(UserPoolId, ClientId) {
		POOL_DATA.UserPoolId = UserPoolId;
		POOL_DATA.ClientId = ClientId;
		userPool = new CognitoUserPool(POOL_DATA);
	}
	public getToken() {
		return this.token;
	}
	setSessionTime(value: number) {
		this.sessionTime$.next(value);
	}
	getSessionTime() {
		return this.sessionTime$;
	}
	public setToken(token: string) {
		this.token = token;
		localStorage.setItem('token', token);
	}

	signInFleetadmin(user: any, pass: any) {
		const useremail = { email: user };

		// const httpOptions = {
		// 	headers: new HttpHeaders({
		// 		'Content-Type': 'application/json',
		// 		// 'Authorization': this.authService.getToken(),
		// 	}),
		// };
		return this.http.post(`${environment.signInuserpool}`, useremail);
	}

	signUp(username: string, email: string, password: string): void {
		if (username.endsWith('-a')) {
			username = username.replace('-a', '');
			localStorage.setItem('multi-lang', 'true');
		}
		this.authIsLoading.next(true);
		const user: User = {
			username: username,
			email: email,
			password: password,
		};
		const attrList: CognitoUserAttribute[] = [];
		const emailAttribute = {
			Name: 'email',
			Value: user.email,
		};
		attrList.push(new CognitoUserAttribute(emailAttribute));
		userPool.signUp(user.username, user.password, attrList, null, (err, result) => {
			if (err) {
				this.authDidFail.next(true);
				this.authIsLoading.next(false);
				return;
			}
			this.authDidFail.next(false);
			this.authIsLoading.next(false);
			this.registeredUser = result.user;
		});
		return;
	}

	confirmUser(username: string, code: string) {
		this.authIsLoading.next(true);
		const userData = {
			Username: username,
			Pool: userPool,
		};
		const cognitUser = new CognitoUser(userData);
		cognitUser.confirmRegistration(code, true, (err, result) => {
			if (err) {
				this.authDidFail.next(true);
				this.authIsLoading.next(false);
				return;
			}
			this.authDidFail.next(false);
			this.authIsLoading.next(false);
			// this.router.navigate(['/dashboard']);
		});
	}

	signIn(username: string, password: string): void {
		if (username.endsWith('-a')) {
			username = username.replace('-a', '');
			localStorage.setItem('multi-lang', 'true');
		}
		this.authIsLoading.next(true);
		const authData = {
			Username: username,
			Password: password,
		};
		const authDetails = new AuthenticationDetails(authData);
		const userData = {
			Username: username,
			Pool: userPool,
		};
		const cognitoUser = new CognitoUser(userData);
		const that = this;
		cognitoUser.authenticateUser(authDetails, {
			onSuccess(result: CognitoUserSession) {
				// console.log(result);
				that.setToken(result['idToken']['jwtToken']);
				localStorage.setItem('email', username);

				if (result['accessToken']['payload']['exp']) {
					localStorage.setItem('timeOut', result['accessToken']['payload']['exp']);
				}

				that.authStatusChanged.next(true);
				that.authIsLoading.next(true);
				that.authDidFail.next(false);
				that.authIsLoading.next(false);
				that.authStatusChanged.next(true);
				that.router.navigate(['/overview']);
			},
			onFailure(err) {
				that.authDidFail.next(true);
				that.authIsLoading.next(false);
				that.errMessage.next(err);
				that.authStatusChanged.next(false);
				that.router.navigate(['/']);
				// console.log(err);
			},
		});
		// create user with cognito data

		this.indexedDBService.setExpirationTimeOfStore();

		return;
	}

	getAuthenticatedUser() {
		return userPool.getCurrentUser();
	}

	logout() {
		// CognitoUser.globalSignOut();
		this.authStatusChanged.next(false);
		this.setToken(null);
		localStorage.removeItem('company_id');
		localStorage.removeItem('company_name');
		localStorage.removeItem('token');
		localStorage.removeItem('email');
		localStorage.removeItem('unit');
		localStorage.removeItem('originLat');
		localStorage.removeItem('originLng');
		localStorage.removeItem('UserPoolId');
		localStorage.removeItem('ClientId');
		localStorage.removeItem('DashTitle');
		localStorage.removeItem('교통약자');
		localStorage.removeItem('연비절감사업');
		localStorage.removeItem('교통약자_2');
		localStorage.removeItem('vehicle_total');

		setTimeout(() => {
			this.apollo.getClient().resetStore();
		}, 10);
		this.getAuthenticatedUser().signOut();
		this.subs.unsubscribe();
		this.router.navigate(['/auth/sign-in']);
		if (!localStorage.getItem('saved_email')) {
			localStorage.clear();
		} else {
			if (localStorage.getItem('sub_company_id_length')) {
				const subCompany = localStorage.getItem('sub_company_id_length');
				for (let index = 0; index < +subCompany; index++) {
					localStorage.removeItem('sub_company_id' + index);
				}
			}

			localStorage.removeItem('company_type');
			localStorage.removeItem('fei');
			localStorage.removeItem('show_driver');
			localStorage.removeItem('location_list');
			localStorage.removeItem('support_ws');
			localStorage.removeItem('show_region_info');
			localStorage.removeItem('company_vehicles');
			localStorage.removeItem('industry_id');
			localStorage.removeItem('report_name');
			localStorage.removeItem('support_fei_report');
			localStorage.removeItem('support_ar');
			localStorage.removeItem('idling_limit');
			localStorage.removeItem('support_mq');
			localStorage.removeItem('all_login_status');
			localStorage.removeItem('imageIndex');
			localStorage.removeItem('dash_title');
			localStorage.removeItem('sub_company_id_length');
			localStorage.removeItem('company_status');
			localStorage.removeItem('garage_info');
			localStorage.removeItem('audio_in');
			localStorage.removeItem('andong_company');
			localStorage.removeItem('andong_event_count');
			localStorage.removeItem('andong_all_event_count');
			localStorage.removeItem('vehicle_total');
		}

		// this.indexedDBService.setExpirationTimeOfStore(); // 로그아웃시 indexedDB 만료시간 추가 및 db 삭제

		this.indexedDBService.clearStore(); // 만료시간 상관없이 제거

		return new Promise((resolve, reject) => resolve(false));
	}

	isAuthenticated(): { islogin: boolean; message: string } {
		const user = this.getAuthenticatedUser();
		// console.log('USER',user)
		let logind = false;
		let message = 'Please log in';
		if (!!user) {
			user.getSession((err, session) => {
				// console.log('isAuthenticated',err)
				// console.log('isAuthenticated-session',session)
				//console.log(user.getSignInUserSession().getIdToken().)
				if (err) {
					logind = false;
					message = 'There is error in session restoring. Please try to log in again.';
				} else {
					if (session == null) {
						logind = false;
						message = 'Session is expired. Please try to log in again.';
					}
					if (session.isValid()) {
						logind = true;
						message = '';
						//observer.next({status: true});
					} else {
						logind = false;
						message = 'Session is expired. Please try to log in again.';
					}
				}
			});
		} else {
			message = 'Session is expired. Please try to log in again.';
			localStorage.clear();
		}

		return { islogin: logind, message };
	}

	isAuthenticatedCheck(): Observable<boolean> {
		// const user = this.getAuthenticatedUser();
		// const obs = Observable.create((observer) => {
		//   if (!user) {
		//     this.setToken(null);
		//     observer.next({status: false, message: 'User information is removed. Please try to log in again.'});
		//   } else {
		//     user.getSession((err, session) => {

		//       if (err) {
		//         this.setToken(null);
		//         observer.next({status: false, message: 'There is error in session restoring. Please try to log in again.'});
		//       } else {
		//         if((session == null)){
		//           this.logout();
		//           observer.next({status: false, message: 'Session is expired. Please try to log in again.'});
		//         }
		//         if (session.isValid()) {

		//           this.setToken(session['idToken']['jwtToken']);
		//           observer.next({status: true});
		//         } else {
		//           this.setToken(null);
		//           observer.next({status: false, message: 'Session is expired. Please try to log in again.'});
		//         }
		//       }
		//     });
		//   }
		//   observer.complete();
		// });
		// return obs;
		return null;
	}
	setSesstionStart() {
		this._start.next();
	}
	setSesstionStop() {
		this._stop.next();
	}
	refreshSession() {
		if (!!this.getAuthenticatedUser()) {
			this.getAuthenticatedUser().getSession((err, data) => {
				this.getAuthenticatedUser().refreshSession(data.getRefreshToken(), (error, result) => {
					this.setToken(result.getIdToken().getJwtToken());
					if (result['accessToken']['payload']['exp']) {
						localStorage.setItem('timeOut', result['accessToken']['payload']['exp']);
					}
				});
			});
		}
	}

	checkSession() {
		return timer(0, 1000).pipe(
			takeUntil(this._stop),
			repeatWhen(() => this._start)
		);
	}

	initAuth() {
		return this.checkSession().pipe(
			map((value) => {
				const time = localStorage.getItem('timeOut');
				if (!!time) {
					//console.log(Number(time), Math.floor((new Date().getTime() / 1000)));
					if (Number(time) <= Math.floor(new Date().getTime() / 1000)) {
						return 'notLogin';
					}
				}
			})
		);
	}
}
