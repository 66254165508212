import { Component, OnInit, Input, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import {
	VideoStatusTextMap,
	VideoHolder,
	VideoStatus,
	SiteController,
} from '../../types/data-types';
import { SiteControllerService } from '../../service/sitecontroller.service';
import { AlertService } from '../../service/alert.service';
import { TripsService } from '../../service/trips.service';
import { CustomTranslateService } from '../../service/customTranslate.service';
import { switchMap } from 'rxjs/operators';
@Component({
	selector: 'app-video-status-view',
	templateUrl: './video-status-view.component.html',
	styleUrls: ['./video-status-view.component.scss'],
})
export class VideoStatusViewComponent implements OnInit {
	VideoStatus = VideoStatus;
	VideoStatusTextMap = VideoStatusTextMap;
	readonly = false;
	lang = 'en';
	@Input()
	set setmode(mode) {
		console.log(mode);
		this.mode = mode;
	}
	// mode: 'fasdfdas';

	@Input() mode: string;

	@Input()
	video: any;

	@Output()
	play = new EventEmitter();

	@Output()
	request = new EventEmitter();

	requestImg = 'request';
	failImg = 'fail';
	viewImg = 'view';
	fail_img = 'fail';
	loadingImg = 'loading';
	transObj = {};
	constructor(
		private siteControllerService: SiteControllerService,
		private alertService: AlertService,
		private tripsService: TripsService,
		private cf: ChangeDetectorRef,
		private customTranslateService: CustomTranslateService
	) {}
	//파일명+국가코드.png
	ngOnInit() {
		this.customTranslateService
			.getLang()
			.pipe(
				switchMap((value) => {
					this.lang = value ? value : 'ko';
					return this.customTranslateService.sendJson(this.lang);
				})
			)
			.subscribe((value) => {
				this.transObj = value;

				this.requestImg = 'request_' + this.lang + '.png'; // 영상요청
				this.failImg = 'fail_' + this.lang + '.png'; // 요청실패
				this.fail_img = 'fail_img2' + '.png'; // 재요청
				this.viewImg = 'view_' + this.lang + '.png'; // 영상재생
				this.loadingImg = 'loading_' + this.lang + '.png'; // 요청중

				/**
				 * 📌 video_file === ' '인 경우는 재요청 불가
				 * 📌 사업전략 요청으로 고위험, 교통사고 페이지에서는 '요청실패' 아이콘 대신 '재요청' 아이콘으로 변경
				 *
				 * 파일명: (최종)_대시보드 운전자 동기화 시나리오_사업전략_230720
				 */

				this.cf.markForCheck();
			});

		this.tripsService
			.getListbuttonStatus()
			.subscribe((value: { status: number; time_stamp: string }) => {
				if (this.video['time_stamp'] === value.time_stamp) {
					console.log(value);
					this.video['video_status'] = value.status;
					this.cf.markForCheck();
				}
			});
		this.siteControllerService.getReadonlyTrigger().subscribe((value) => {
			if (value !== null && value !== undefined) {
				this.readonly = value;
			}
		});
		// console.log(this.mode);
		/**
		 * Error -1
		 * Failure 3 -> fail_img2 (파일 없는 경우, 재요청)
		 * NotAvailable 0
		 * Processing 2 -> loading_
		 * ReadyForRequest 1 -> request_
		 * ReadyForView 4 -> view_
		 */
	}
	submitRequest() {
		if (!this.readonly) {
			// this.alertService.alertDialog(
			//     this.transObj['ALERTS']['video request to active device']
			// )
			this.request.emit();
		} else {
			this.alertService
				.alertDialog(
					this.transObj['ALERTS'][
						'This service is not supported by your subscription plan. Please contact the provider.'
					]
				)
				.afterClosed()
				.subscribe(() => {
					return false;
				});
		}
	}

	notExistFileAlert() {
		this.alertService.alertDialog('파일이 존재하지 않습니다');
	}

	alert_fail() {
		// alert("요청한 영상이 존재 하지 않습니다. SD 카드를 확인해주세요.")

		this.alertService
			.alertDialog(this.transObj['ALERTS']['Do you really want to request?'], '', '1')
			.afterClosed()
			.subscribe((value) => {
				if (!!value) {
					this.request.emit();
				}
			});
	}
}
