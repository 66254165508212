import {
	Component,
	OnInit,
	ViewChild,
	AfterViewInit,
	Input,
	Output,
	EventEmitter,
	Inject,
} from '@angular/core';
import { DaterangePickerComponent } from 'ng2-daterangepicker';
import { DaterangeService } from '../../service/daterange.service';
import * as moment from 'moment';
import { inputs } from '@syncfusion/ej2-angular-calendars/src/calendar/calendar.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { EventEmitte } from 'protractor';

@Component({
	selector: 'app-alert-dialog',
	templateUrl: './alert-dialog.component.html',
	styleUrls: ['./alert-dialog.component.scss'],
})
export class AlertDialogComponent implements OnInit, AfterViewInit {
	message = '';
	btnName = '확인';
	mode = 0;
	constructor(
		public dialogRef: MatDialogRef<AlertDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: { message: string; btnName?: string; key?: string }
	) {
		this.message = data.message;
		if (!!data.btnName) {
			this.btnName = data.btnName;
		}
		if (!!data.key) {
			if (data.key === '1') {
				this.mode = 1;
			} else {
				this.mode = Number(data.key);
			}
		}
	}
	ngOnInit() {}
	ngAfterViewInit() {}
	close(value?: boolean) {
		this.dialogRef.close(value);
	}
}
