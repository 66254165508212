import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import {
	ChartComponent,
	ApexAxisChartSeries,
	ApexChart,
	ApexTitleSubtitle,
	ApexXAxis,
	ApexNonAxisChartSeries,
	ApexPlotOptions,
	ApexAnnotations,
	ApexStroke,
	ApexFill,
	ApexTooltip,
	ApexResponsive,
	ApexYAxis,
	ApexGrid,
	ApexStates,
	ApexTheme,
	ApexDataLabels,
	ApexLegend,
} from 'ng-apexcharts';

export type ChartOptions = {
	series: ApexAxisChartSeries | ApexNonAxisChartSeries;
	chart: ApexChart;
	xaxis: ApexXAxis;
	yaxis: ApexYAxis | ApexYAxis[];
	title: ApexTitleSubtitle;
	colors: string[];
	dataLabels: ApexDataLabels;
	plotOptions: ApexPlotOptions;
	annotations: ApexAnnotations;
	stroke: ApexStroke;
	labels: string[];
	fill: ApexFill;
	tooltip: ApexTooltip;
	responsive: ApexResponsive[];
	grid: ApexGrid;
	states: ApexStates;
	subtitle: ApexTitleSubtitle;
	theme: ApexTheme;
	legend: ApexLegend;
};

@Component({
	selector: 'app-heatmap-charts',
	templateUrl: './apx-heatmap-charts.component.html',
	styleUrls: ['./apx-heatmap-charts.component.scss'],
})
export class ApxHeatmapChartComponent implements OnInit, AfterViewInit {
	@ViewChild('charts') charts: ChartComponent;
	public chartOptions: Partial<ChartOptions>;

	@Input() chart: ApexChart;
	@Input() annotations: ApexAnnotations;
	@Input() colors: string[];
	@Input() dataLabels: ApexDataLabels;
	@Input() series: ApexAxisChartSeries | ApexNonAxisChartSeries;
	@Input() stroke: ApexStroke;
	@Input() labels: string[];
	@Input() legend: ApexLegend;
	@Input() fill: ApexFill;
	@Input() tooltip: ApexTooltip;
	@Input() plotOptions: ApexPlotOptions;
	@Input() responsive: ApexResponsive[];
	@Input() xaxis: ApexXAxis;
	@Input() yaxis: ApexYAxis | ApexYAxis[];
	@Input() grid: ApexGrid;
	@Input() states: ApexStates;
	@Input() title: ApexTitleSubtitle;
	@Input() subtitle: ApexTitleSubtitle;
	@Input() theme: ApexTheme;

	@Input() driverHeatmapData: any;

	constructor() {}

	ngOnInit() {
		this.createChart();
	}

	createChart() {
		const formatLabels = this.driverHeatmapData.map((item: any, key: number) => {
			if (item.name.length === 1) {
				return {
					...item,
					name: item.name.padEnd(3, '\u00A0'),
				};
			} else {
				return item;
			}
		});

		this.chartOptions = {
			series: [...formatLabels],
			chart: {
				width: 200,
				height: 400,
				type: 'heatmap',
				toolbar: {
					show: false,
				},

				animations: {
					enabled: false,
				},
			},
			states: {
				hover: {
					filter: {
						type: 'none',
					},
				},
			},
			tooltip: {
				enabled: false,
			},

			dataLabels: {
				enabled: false,
			},
			legend: {
				show: false,
			},
			grid: {
				show: false,
				xaxis: {
					lines: {
						show: false,
					},
				},
				yaxis: {
					lines: {
						show: false,
					},
				},
				padding: {
					top: 0,
					right: 0,
					bottom: 40,
					left: 0,
				},
			},
			xaxis: {
				labels: {
					// 하단 라벨
					show: false,
				},
				axisTicks: {
					show: false,
				},
				axisBorder: {
					show: false,
				},
			},
			yaxis: {
				// 일별 라벨
				show: false,
				tooltip: {
					enabled: true,
				},
				labels: {
					show: false,
					style: {
						fontSize: '22px',
						fontWeight: '500',
						colors: '#000',
					},
				},
				axisBorder: {
					show: false,
				},
			},
			stroke: {
				// show: true,
				colors: ['transparent'],
				width: 0,
				dashArray: 0,
				lineCap: 'square',
			},

			colors: [''],
			plotOptions: {
				heatmap: {
					shadeIntensity: 0.5,
					radius: 0,
					reverseNegativeShade: true,
					enableShades: true,
					useFillColorAsStroke: false,

					colorScale: {
						// min: -5,
						// max: 5,

						ranges: [
							{
								from: 0,
								to: 2,
								color: '#ffffff',
							},
							{
								from: 2,
								to: 4,
								color: '#DBE9F5',
							},
							{
								from: 4,
								to: 6,
								color: '#BDD7EE',
							},
							{
								from: 6,
								to: 8,
								color: '#9BC2E6',
							},
							{
								from: 8,
								to: 10,
								color: '#81B2DF',
							},
							{
								from: 10,
								to: 15,
								color: '#498FCF',
							},
							{
								from: 15,
								to: 30,
								color: '#2F75B5',
							},
							{
								from: 30,
								to: 40,
								color: '#225380',
							},
							{
								from: 40,
								to: 100,
								color: '#1C456A',
							},
						],
					},

					distributed: false,
				},
			},
		};
	}

	ngAfterViewInit() {}
}
