import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';
import { AuthService } from './auth.service';
import { MapService } from './map.service';
import { Apollo, QueryRef } from 'apollo-angular';
import { Trip } from '../types/data-types';
import {
	GET_DRIVERS_TRIPS_DETAILS,
	GET_ROUTES_LIST,
	GET_DRIVERS_ROUTE_TRIPS_LIST,
	GET_DRIVERS_SINGLE_TRIPS_DATA,
} from '../queries/queries';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, delay, timestamp } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
@Injectable({
	providedIn: 'root',
})
export class TripsService {
	tripsQuery: QueryRef<any>;
	routeListQuery: QueryRef<any>;
	routeTripListQuery: QueryRef<any>;
	singleTripQuery: QueryRef<any>;
	routesLists = new Subject();
	routesTripLists = new Subject();
	singleTripData = new Subject();
	tripsLogs = new Subject();
	alertTripLogs = new Subject();
	tripDriverObjWithDate = new Subject();
	loadingTrigger$ = new BehaviorSubject<boolean>(null);
	tripvideoSw$ = new Subject<any>();
	tripvideoSw2$ = new BehaviorSubject<any>(null);
	tripvideoSw3$ = new Subject<any>();
	indexTrigger$ = new BehaviorSubject<{ data1: string; data2: boolean }>(null);
	indexTrigger2$ = new BehaviorSubject<string>(null);
	tripvidoeListTrigger$ = new BehaviorSubject<Object[]>(null);
	tripEventTrigger$ = new BehaviorSubject<{ data1: any; data2: string }>(null);
	driverHistroybtnTrigger$ = new BehaviorSubject<boolean>(null);

	listbuttonStatus$ = new Subject<{ status: number; time_stamp: string }>();

	refrashLIst$ = new Subject<object[]>();
	constructor(
		private authService: AuthService,
		private http: HttpClient,
		private apollo: Apollo,
		private spinnerService: Ng4LoadingSpinnerService,
		private mapService: MapService
	) {}

	setRefrashLIst(value: object[]) {
		this.refrashLIst$.next(value);
	}
	getRefrashLIst() {
		return this.refrashLIst$.asObservable();
	}
	setListbuttonStatus(value: { status: number; time_stamp: string }) {
		this.listbuttonStatus$.next(value);
	}
	getListbuttonStatus() {
		return this.listbuttonStatus$.asObservable();
	}

	setLoadingTrigger(value: boolean) {
		this.loadingTrigger$.next(value);
	}
	getLoadingTrigger() {
		return this.loadingTrigger$;
	}
	setIndexTrigger(data1: string, data2?: boolean) {
		this.indexTrigger$.next({ data1, data2 });
	}
	getIndexTrigger() {
		return this.indexTrigger$;
	}
	setIndexTrigger1(value: string) {
		this.indexTrigger2$.next(value);
	}
	getIndexTrigger2() {
		return this.indexTrigger2$;
	}
	setDriverHistroybtnTrigger(value: boolean) {
		this.driverHistroybtnTrigger$.next(value);
	}
	getDriverHistroybtnTrigger() {
		return this.driverHistroybtnTrigger$;
	}
	setTripEventTrigger(data1: any, data2: string) {
		this.tripEventTrigger$.next({ data1, data2 });
	}
	getTripEventTrigger() {
		return this.tripEventTrigger$;
	}
	setTripVideoTrigger(event: any) {
		this.tripvideoSw$.next(event);
	}

	getTripVideoTrigger() {
		return this.tripvideoSw$.asObservable();
	}

	setTripVideoTrigger2(event: any) {
		this.tripvideoSw2$.next(event);
	}

	getTripVideoTrigger2() {
		return this.tripvideoSw2$.asObservable();
	}
	setTripVideoTrigger3(event: any) {
		this.tripvideoSw3$.next(event);
	}

	getTripVideoTrigger3() {
		return this.tripvideoSw3$.asObservable();
	}

	setTripVideoListTrigger(list: Object[]) {
		// 231010 list 중복 처리
		// const init_videos = list;
		// const unique_videos = init_videos.filter((video: any, key: number) => {
		// 	return (
		// 		init_videos.findIndex((ele: any) => {
		// 			return (
		// 				video['device_id'] === ele['device_id'] && video['time_stamp'] === ele['time_stamp']
		// 			);
		// 		}) === key
		// 	);
		// });

		return this.tripvidoeListTrigger$.next(list);
	}
	getTripVideoListTrigger() {
		return this.tripvidoeListTrigger$;
	}

	public getTripLatLon(tripquery) {
		return this.http.post(
			'https://hv00gpwach.execute-api.us-west-2.amazonaws.com/staging/latlon',
			tripquery
		);
	}

	public getTripNew(drivertriplogquery) {
		return this.http.post(`${environment.getTripsDetailsListNew}` + '/trips', drivertriplogquery);
	}

	public getAlertEventDetails(alertQuery: any) {
		return this.http.post(`${environment.apiAlertEventList}`, alertQuery);
	}
	public getAllVideo(camera_id) {
		return this.http.get(`${environment.getAllVideoApiUrl}/${camera_id}`);
	}

	public getTripsDetails(camera_id: string, start_date: string, tripPage: number) {
		const getStartDate = moment(start_date).format('YYYY-MM-DD 00:00:00');
		this.tripsQuery = this.apollo.watchQuery<any>({
			query: GET_DRIVERS_TRIPS_DETAILS,
			variables: {
				camera_id: camera_id,
				start_date: getStartDate,
				trips_page: tripPage,
			},
			// forceFetch: true,
		});
		return this.tripsQuery.valueChanges.pipe(delay(100));
	}

	public getTripslist(drivertriplogquery) {
		// const timestamp='1548975325000';
		// {
		//     "query": "query getList {\n listTrips(camera_id:861107036111616,trip_start_ts:1548975325000)\n {\n items {\n camera_id\n trip_details\n }\n nextToken\n }\n}",
		//     "variables": "{}",
		//     "operationName": "getList"
		//     }
		return this.http.post(`${environment.evnetQueryAPI}`, drivertriplogquery);
		// return this.http.post(`${environment.getTripsDetailsListNew}` + '/trips', drivertriplogquery)
	}

	fetchgTripsMore(tripPage: number) {
		return this.tripsQuery.fetchMore({
			// query: ... (you can specify a different query. feedQuery is used by default)
			variables: {
				trips_page: tripPage,
			},
			// We are able to figure out which offset to use because it matches
			// the feed length, but we could also use state, or the previous
			// variables to calculate this (see the cursor example below)
			updateQuery: (previousResult, { fetchMoreResult }) => {
				// console.log(previousResult.getDriverById.trips, 'previousResult');
				// console.log(fetchMoreResult.getDriverById.trips, 'fetchMoreResult');
				// if (!fetchMoreResult) { return previousResult; }
				return {
					getDriverById: {
						camera_id: previousResult.getDriverById.camera_id,
						name: previousResult.getDriverById.name,
						__typename: previousResult.getDriverById.__typename,
						trips: [...previousResult.getDriverById.trips, ...fetchMoreResult.getDriverById.trips],
					},
				};
			},
		});
	}

	public getRouteTripsList(camera_id: string, start_date: string) {
		const getStartDate = moment(start_date).format('YYYY-MM-DD 00:00:00');
		this.routeTripListQuery = this.apollo.watchQuery<Trip>({
			query: GET_DRIVERS_ROUTE_TRIPS_LIST,
			variables: {
				camera_id: camera_id,
				start_date: getStartDate,
			},
		});
		return this.routeTripListQuery.valueChanges.pipe(delay(100));
	}

	public getRoutesList(camera_id: string, start_date: string, tripPage: number) {
		const getStartDate = moment(start_date).format('YYYY-MM-DD 00:00:00');
		this.routeListQuery = this.apollo.watchQuery<Trip>({
			query: GET_ROUTES_LIST,
			variables: {
				camera_id: camera_id,
				start_date: getStartDate,
				trips_page: tripPage,
			},
		});
		return this.routeListQuery.valueChanges.pipe(delay(100));
	}

	fetchgRouteListMore(routePage: number) {
		console.log('rotuedsss', this.routeListQuery);
		return this.routeListQuery.fetchMore({
			// query: ... (you can specify a different query. feedQuery is used by default)
			variables: {
				trips_page: routePage,
			},
			// We are able to figure out which offset to use because it matches
			// the feed length, but we could also use state, or the previous
			// variables to calculate this (see the cursor example below)
			updateQuery: (previousResult, { fetchMoreResult }) => {
				console.log('prveerwerewrewr', previousResult, 'fecth more', fetchMoreResult);
				// console.log(previousResult.getDriverById.trips, 'previousResult');
				// console.log(fetchMoreResult.getDriverById.trips, 'fetchMoreResult');
				// if (!fetchMoreResult) { return previousResult; }
				return {
					// getDriverById: [...previousResult.getDriverById, ...fetchMoreResult.getDriverById]
					getDriverById: {
						camera_id: previousResult.getDriverById.camera_id,
						name: previousResult.getDriverById.name,
						__typename: previousResult.getDriverById.__typename,
						trips: [...previousResult.getDriverById.trips, ...fetchMoreResult.getDriverById.trips],
					},
				};
			},
		});
	}

	public getTripData(camera_id: string, trip_start: string) {
		const getStartDate = moment(trip_start).format('YYYY-MM-DD HH:mm:ss.SSS');
		console.log(getStartDate, 'getStartDate');
		this.singleTripQuery = this.apollo.watchQuery<Trip>({
			query: GET_DRIVERS_SINGLE_TRIPS_DATA,
			variables: {
				camera_id: camera_id,
				trip_start: getStartDate,
			},
		});
		return this.singleTripQuery.valueChanges.pipe(delay(100));
	}

	getVodlist(camera_id: string) {
		return this.http.get(`${environment.dynamoDBUrl}` + '/vod/filelist/' + `${camera_id}`);
	}
	// public getTripsDetailsss(camera_id: string) {
	//     return this.apollo.watchQuery<Trip>({
	//         query: GET_TRIPS_BY_ID,
	//         variables: {
	//             camera_id: camera_id,
	//             trip_start: '2018-01-07 13:48:10'
	//         }
	//     }).valueChanges.pipe(map((r: any) => {
	//         console.log(r, 'rrrr');
	//         if (r.data.getTripById.trip_logs.length > 0) {
	//             const startLat = r.data.getTripById.trip_logs[0].location.lat;
	//             const startLon = r.data.getTripById.trip_logs[0].location.lng;
	//             const endLat = r.data.getTripById.trip_logs[r.data.getTripById.trip_logs.length - 1].location.lat;
	//             const endLon = r.data.getTripById.trip_logs[r.data.getTripById.trip_logs.length - 1].location.lng;
	//             const dir = {
	//                 origin: {
	//                     lat: parseFloat(startLat),
	//                     lng: parseFloat(startLon)
	//                 },
	//                 destination: {
	//                     lat: parseFloat(endLat),
	//                     lng: parseFloat(endLon)
	//                 }
	//             };
	//             const trip = {
	//                 direction: dir,
	//                 stats: r.data.getTripById.stats,
	//                 trip_start: r.data.getTripById.trip_start,
	//                 trip_logs: r.data.getTripById.trip_logs,
	//                 waypoints: r.data.getTripById.trip_logs.map((i) => {
	//                     return {
	//                         location: {
	//                             lat: parseFloat(i.location.lat),
	//                             lng: parseFloat(i.location.lon),
	//                         },
	//                         stopover: true,
	//                     };
	//                 }),
	//             };
	//             console.log(trip, 'trip');
	//             return trip;
	//         }
	// trip.cords = trip.cords.filter((c) => c.lat !== 0 && c.lng !== 0);
	// trip.distance = this.mapService.getRouteDistance(trip.cords);
	// trip.direction = dire;
	//     }));
	// }

	public tripData(trips: any[]) {
		trips.map((r) => {
			console.log(r, 'aaaaa');
			// const startLat = r.trip_logs[0].location.lat;
			// const startLon = r.trip_logs[0].location.lon;
			// const endLat = r.trip_logs[r.trip_logs.length - 1].location.lat;
			// const endLon = r.trip_logs[r.trip_logs.length - 1].location.lon;
			// const dir = {
			//     origin: {
			//         lat: parseFloat(startLat),
			//         lng: parseFloat(startLon)
			//     },

			//     destination: {
			//         lat: parseFloat(endLat),
			//         lng: parseFloat(endLon)
			//     }
			// };
			// const trip = {
			//     direction: dir,
			//     cords: r.trip_logs.map((i) => {

			//         return {
			//             lat: parseFloat(location['lat']),
			//             lng: parseFloat(location['lon']),
			//         };
			//     }),
			// };
			// trip.cords = trip.cords.filter((c) => c.lat !== 0 && c.lng !== 0);
			// // trip.distance = this.mapService.getRouteDistance(trip.cords);
			// // trip.direction = dire;
			// console.log(trip, 'trip');
		});
	}
	// getVedioBycameraId() {

	//      this.http.get(`https://lidithtnd7.execute-api.ap-northeast-2.amazonaws.com/dev/v1/vod/alert/861107036630490/20180919_042455_I1`).subscribe((res: any) => {

	//         console.log(res)
	//     });
	// }
	public getTrips(driverId: number) {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				Authorization: this.authService.getToken(),
			}),
		};
		const data = {
			camera_id: '861107033352742',
			trip_start_limit: 5,
			item_limit: 20,
		};
		this.http
			.post('https://s31ta59qa7.execute-api.us-west-2.amazonaws.com/dev/carvi', data, httpOptions)
			.subscribe((res: any) => {
				res.results.map((r, ix) => {
					const startLatLng = r.items[0].location.split(',');
					const endLatLng = r.items[r.items.length - 1].location.split(',');
					const dir = {
						origin: {
							lat: parseFloat(startLatLng[0]),
							lng: parseFloat(startLatLng[1]),
						},

						destination: {
							lat: parseFloat(endLatLng[0]),
							lng: parseFloat(endLatLng[1]),
						},
					};
					const trip = {
						tripStart: r.trip_start,
						direction: dir,
						startAddress: null,
						endAddress: null,
						distance: 0,
						cords: r.items.map((i) => {
							const latLng = i.location.split(',');
							return {
								lat: parseFloat(latLng[0]),
								lng: parseFloat(latLng[1]),
							};
						}),
					};
					trip.cords = trip.cords.filter((c) => c.lat !== 0 && c.lng !== 0);
					// trip.distance = this.mapService.getRouteDistance(trip.cords);
					// trip.direction = dire;
					console.log(trip, 'trip');
				});
				return res.trip;
			});
	}

	public getRoutes() {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				Authorization: this.authService.getToken(),
			}),
		};
		const data = {
			camera_id: '861107033352742',
			only_trip_start: false,
			trip_start_limit: 5,
			item_limit: 20,
		};

		console.log('get trips');
		return new Promise((resolve, reject) => {
			this.http
				.post('https://s31ta59qa7.execute-api.us-west-2.amazonaws.com/dev/carvi', data, httpOptions)
				.subscribe(
					(res: any) => {
						console.log('res', res);
						let counter = 0;
						let counter2 = 0;
						const routes = res.results.map((r, ix) => {
							const startLatLng = r.items[0].location.split(',');
							const endLatLng = r.items[r.items.length - 1].location.split(',');
							const dir = {
								origin: {
									lat: parseFloat(startLatLng[0]),
									lng: parseFloat(startLatLng[1]),
								},
								// originAddress: this.mapService.getCodeLatLng(startLatLng[0], startLatLng[1]),
								destination: {
									lat: parseFloat(endLatLng[0]),
									lng: parseFloat(endLatLng[1]),
								},
								// destinationAddress: this.mapService.getCodeLatLng(endLatLng[0], endLatLng[1]),
							};
							const trip = {
								tripStart: r.trip_start,
								direction: dir,
								startAddress: null,
								endAddress: null,
								distance: 0,
								waypoints: r.items.map((i) => {
									const latLngW = i.location.split(',');
									return {
										location: {
											lat: parseFloat(latLngW[0]),
											lng: parseFloat(latLngW[1]),
										},
										stopover: true,
									};
								}),
								cords: r.items.map((i) => {
									const latLng = i.location.split(',');
									return {
										lat: parseFloat(latLng[0]),
										lng: parseFloat(latLng[1]),
									};
								}),
							};
							trip.cords = trip.cords.filter((c) => c.lat !== 0 && c.lng !== 0);
							// trip.distance = this.mapService.getRouteDistance(trip.cords);
							setTimeout(() => {
								this.mapService
									.codeLatLng(parseFloat(startLatLng[0]), parseFloat(startLatLng[1]))
									.then((address) => {
										// console.log(address);
										trip.startAddress = address;
										counter++;
										// if (counter === res.results.length && counter2 === res.results.length) {
										//     setTimeout(() => {
										//         resolve(routes);
										//     }, 100);
										// }
									});
							}, ix * 1000);
							setTimeout(() => {
								this.mapService
									.codeLatLng(parseFloat(endLatLng[0]), parseFloat(endLatLng[1]))
									.then((endAddress) => {
										// console.log(endAddress);
										trip.endAddress = endAddress;
										counter2++;
										// console.log(counter, res.results.length);
										// if (counter === res.results.length && counter2 === res.results.length) {
										// setTimeout(() => {
										// }, 100);
										// }
									});
							}, 1000 * ix + 1000 * res.results.length + 2000);
							// console.log('trip', trip);
							return trip;
						});
						resolve(routes);
					},
					(error) => {
						console.log('error', error);
					}
				);
		});
	}

	getAddress(lat, lng) {
		return this.http.get(`${environment.getAddressUrl}/location/${lat}/${lng}`);
	}

	tripFilterArr(trips) {
		return trips.map((r) => {
			const trip = {
				camera_id: r.camera_id,
				location: r.location,
				sourceAddress: null,
				destinationAddress: null,
				stats: r.stats,
				trip_end: r.trip_end,
				trip_start: r.trip_start,
				trip_logs: r.trip_logs
					.filter((elm) => elm.location.lat !== 0 && elm.location.lng !== 0)
					.map((t) => {
						const tripLogs = {
							camera_id: t.camera_id,
							direction: t.direction,
							event: t.event,
							location: t.location,
							locationAddress: null,
							reaction: t.reaction,
							situation: t.situation,
							time_stamp: t.time_stamp,
							trip_start: t.trip_start,
						};
						this.getAddress(t.location.lat, t.location.lng).subscribe((res) => {
							tripLogs.locationAddress = res['address'];
						});
						console.log('trips log api data===========>', tripLogs);
						return tripLogs;
					}),
			};
			this.getAddress(r.location.source.lat, r.location.source.lng).subscribe((res) => {
				trip.sourceAddress = res['address'];
			});
			this.getAddress(r.location.destination.lat, r.location.destination.lng).subscribe((res) => {
				trip.destinationAddress = res['address'];
			});
			return trip;
		});
	}

	getroutinglinkjson(xmlfile) {
		this.spinnerService.show();
		console.log('in service spinner strat');
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/xml',
			}),
		};
		return this.http.post(`${environment.heremapxmlapi}`, xmlfile, httpOptions);
	}

	tripAlertFilterArr(trips) {
		return trips
			.map((t) => {
				const tripLogs = {
					camera_id: t.camera_id,
					direction: t.direction,
					event: t.event,
					location: t.location,
					locationAddress: null,
					reaction: t.reaction,
					situation: t.situation,
					time_stamp: t.time_stamp,
					trip_start: t.trip_start,
				};
				this.getAddress(t.location.lat, t.location.lng).subscribe((res) => {
					tripLogs.locationAddress = res['address'];
				});
				return tripLogs;
			})
			.filter((elm) => elm.location.lat !== 0 && elm.location.lng !== 0);
	}

	getlastRealtimedata(camera_id) {
		return this.http.get(`${environment.REAL_TIME_APPSYNC_ENDPOINT_DATA}` + '/' + `${camera_id}`);
	}
}
