import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';

import * as Chart from 'chart.js';
import chartDataLabels from 'chartjs-plugin-datalabels';

@Component({
	selector: 'app-departure-pie-charts',
	templateUrl: './departure-pie-charts.component.html',
	styleUrls: ['./departure-pie-charts.component.scss'],
})

// 📌221223 리뉴얼 운전자 리포트 - 무단차선이탈 행동개선, 전방충돌 행동개선, 충돌발생 위험지속시간
export class DeparturePieChartComponent implements OnInit, AfterViewInit {
	public chart: any;

	@Input() departurePieChartData: any;
	@Input() departureTotal: any;

	constructor() {}

	ngOnInit() {
		// setTimeout(() => {
		// }, 1000);
		this.departurePieChart();
	}

	departurePieChart() {
		if (!this.departureTotal) {
			const data = [
				this.departurePieChartData.departureIgnore,
				this.departurePieChartData.departureRecover,
			];

			const chart = new Chart('departurePie', {
				plugins: [chartDataLabels],
				type: 'pie',
				data: {
					// labels: ['경보무시', '행동개선'],
					datasets: [
						{
							data: data,
							borderWidth: 2,
							borderColor: 'rgb(255,255,255)',
							backgroundColor: ['rgb(235,99,112)', 'rgb(242,242,242)'],
							hoverBorderColor: 'rgb(255,255,255)',
							hoverBackgroundColor: ['rgb(235,99,112)', 'rgb(242,242,242)'],
							datalabels: {
								display: data.map((item: any, key: number) => {
									if (item < 10) {
										return false;
									} else {
										return true;
									}
								}),
								color: ['#fff', '#7f7f7f'],
							},
						},
					],
				},

				options: {
					animation: {
						duration: 0,
					},
					tooltips: {
						enabled: false,
					},
					plugins: {
						datalabels: {
							anchor: 'center',
							align: 'center',
							// anchor: data[0] < 20 || data[1] < 20 ? 'end' : 'center',
							// align: data[0] < 20 || data[1] < 20 ? 'start' : 'center',

							font: {
								size: 20,
								weight: 700,
							},
							formatter: function (value, ctx) {
								return `${value}%`;
							},
						},
					},

					layout: {
						padding: 20,
					},
					responsive: false,
					cutoutPercentage: 0,

					legend: {
						display: true,
						align: 'center',
						position: 'bottom',
						labels: {
							boxWidth: 20,
							usePointStyle: true,
							fontSize: 14,
							fontColor: '#000',
							fontStyle: 'normal',
							padding: 30,
						},
					},
					elements: {
						line: {
							fill: false,
						},
					},
				},
			});
		}
	}

	ngAfterViewInit() {}
}
