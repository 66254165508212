import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

export interface DropdownOption {
  title: string;
}

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})

export class DropdownComponent implements OnInit {

  @Input() options: Array<DropdownOption> = [];
  @Output() onSelect = new EventEmitter<DropdownOption>();
  @Input() activeTitle: string;

  activeIndex: number;

  constructor() { }

  ngOnInit() {
  }

  selectItem(selectedItemIndex: number, emitRcSignal = true) {
    this.activeIndex = selectedItemIndex;
    this.onSelect.emit(this.options[selectedItemIndex]);
  }

}
