import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { filter, map } from 'rxjs/operators';

import * as AWS from 'aws-sdk';
import { BehaviorSubject } from 'rxjs';
import { SiteController } from '../types/data-types';
@Injectable({
    providedIn: 'root'
})
export class SiteControllerService {

    S3:AWS.S3=new AWS.S3();
    readonlyTrigger$=new BehaviorSubject<boolean>(null);
    controllTrigger$=new BehaviorSubject<SiteController[]>(null);
    constructor(
        private http: HttpClient,
        private dialog: MatDialog
    ) {
        this.S3.config.update({
            region: environment.REAL_TIME_APPSYNC_REGION,
            credentials: new AWS.Credentials({
              accessKeyId: environment.REAL_TIME_APPSYNC_AWS_ACCESS_KEY_ID,
              secretAccessKey: environment.REAL_TIME_APPSYNC_AWS_SECRET_ACCESS_KEY
            })
          });
     }
    setReadonlyTrigger(value:boolean){
        this.readonlyTrigger$.next(value);
    }
    getReadonlyTrigger(){
        return this.readonlyTrigger$
    }

    setControllTrigger(value:SiteController[]){
        this.controllTrigger$.next(value);
    }
    getControllTrigger(){
        return this.controllTrigger$
    }
    public getSiteController() {
        let params = {
            Bucket: "carvi-dash-management", 
            Key: "json/site_controller.json", 
        };
        return this.S3.getObject(params).promise()

    }

}

