import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { nameof } from '../../pages/lib/typeutils';
import { randomInt } from '../../pages/lib/utils';
import { TableViewStyle, DateFormats } from '../../uicommon';
import { MenuItem } from '../../shared/menu-strip/menu-strip.component';
import { Driver2, Skor } from '../../types/data-types';
import { driver2TempName, driver2TempPhoto, getGrade, driver2Skor } from '../../types/common';

import { DialogService } from 'primeng/api';
import { AppContextService } from '../../app-context.service';

//import { DummySkor } from '../../../shared/constants';

export interface DriverTableItem {
    driver?: Driver2;
    id?: string;
    index?: number;
    photo?: string;
    name?: string;
    totalMileage?: number;
    skor?: Skor;
    registeredDay?: Date;
}

export const DummyDriverTableItem: DriverTableItem = {
    skor: {
        rank: 1,
        title: 'TITLE',
        grade: 4,
        overall: 85.55,
        focus: 64.44,
        guard: 84.33,
        speed: 92.22,
        photo: '/assets/images/cow.jpg',
        registered: new Date(2020, 2, 18, 16, 30, 11),
        mileage: 55.55,
        location: 'Somewhere',
    },
};

enum DriverListFilterType {
    All = 0,
    Active = 1,
}

@Component({
    selector: 'app-driver-list-table',
    templateUrl: './driver-list.component.html',
    styleUrls: ['./driver-list.component.scss']
})
export class DriverListTableComponent implements OnInit {

    TableViewStyle = TableViewStyle;
    DateFormats = DateFormats;

    viewStyle: TableViewStyle = TableViewStyle.Table;

    @Input('drivers')
    set setDrivers(drivers: Driver2[]) {
        if(drivers.length >0 ){
            this.tableData = drivers.map<DriverTableItem>((dr, i) => ({
                driver: dr,
                id: dr.camera_id,
                index: i + 1,
                grade: getGrade(dr.stats.score),
                name: driver2TempName(dr.camera_id),
                photo: driver2TempPhoto(dr.camera_id),
                totalMileage: dr.stats && +dr.stats.mileage,
                skor: driver2Skor(dr),
                registeredDay: new Date(randomInt(new Date(2015, 0, 1).getTime(),
                new Date(2020, 1, 20).getTime())),
            }));
            this.tableDataFiltered = this.tableData;
            console.log(this.tableData);
            console.log(this.tableDataFiltered);
        }
       
    
        // this.tripService.getlastRealtimedata(element['camera_id']).subscribe(data => {
        //     res['data']['current'][i]['realTimeNullData'] = data['realtime_data'].length ? false : true;
        //     console.log('data realtime_data',data['realtime_data'])
        // })

        
    }

    tableData: DriverTableItem[] = [];
    tableDataFiltered: DriverTableItem[] = [];
    cols = [
        { field: nameof<DriverTableItem>('name'), header: 'Name' },
        { field: nameof<DriverTableItem>('totalMileage'), header: 'Total Mileage' },
        { field: nameof<DriverTableItem>('skor'), header: 'SKOR' },
        { field: nameof<DriverTableItem>('registeredDay'), header: 'Registration Date' },
    ];
    selected: DriverTableItem;
    driverFilterMenus: MenuItem[] = [
        {
            label: 'All',
            action: this.applyFilter.bind(this, 0),
        },
        {
            label: 'Currently Active',
            action: this.applyFilter.bind(this, 1),
        },
    ];
    sorted: any = {};

    @Output()
    selectionChanged = new EventEmitter<string>();

    constructor(
        public appContext: AppContextService,
        private dialogService: DialogService,
    ) {

    }

    ngOnInit() {
    }

    changeViewStyle(style: TableViewStyle) {
        this.viewStyle = style;
    }

    handleItemClick(item: DriverTableItem) {
        this.selected = item;
        this.selectionChanged.emit(item.id);
    }

    sortColumn(col: string, order: number) {
        this.sorted[col] = order;
        this.tableDataFiltered.sort((lhs, rhs) => {
            return (lhs[col] - rhs[col]) * order;
        });
    }

    applyFilter(filterType: DriverListFilterType) {
        this.tableDataFiltered = this.tableData.filter(t => {
            if (filterType == DriverListFilterType.Active) {
                return !!t.driver.realTime;
            }
            return true;
        });
    }

}
