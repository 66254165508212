import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';

import * as Chart from 'chart.js';

@Component({
	selector: 'app-lane-bar-charts',
	templateUrl: './lane-bar-charts.component.html',
	styleUrls: ['./lane-bar-charts.component.scss'],
})

// 📌230104 차로치우침 차트
export class LaneBarChartComponent implements OnInit, AfterViewInit {
	public chart: any;

	constructor() {}

	ngOnInit() {
		this.laneBarChart();
	}

	laneBarChart() {
		let initData = [
			-1, -3, -2, -2, 3, 2, -2, 0, 0, -2, -4, -2, 0, 1, 3, 4, 2, 3, 1, 0, -1, -3, -2, -5, -3, -1, 0,
			1, 3, 2, 4,
		];

		const formatData = initData.map((item: any, key: number) => {
			return item < 0 ? item - 2 : item > 0 ? item + 2 : item;
		});

		const chart = new Chart('laneBar', {
			type: 'horizontalBar',
			data: {
				labels: [
					'1',
					'2',
					'3',
					'4',
					'5',
					'6',
					'7',
					'8',
					'9',
					'10',
					'11',
					'12',
					'13',
					'14',
					'15',
					'16',
					'17',
					'18',
					'19',
					'20',
					'21',
					'22',
					'23',
					'24',
					'25',
					'26',
					'27',
					'28',
					'29',
					'30',
					'31',
				],
				datasets: [
					{
						data: formatData,
						backgroundColor: 'rgb(247,213,105)',
						borderColor: 'rgb(247,213,105)',
						barThickness: 20,
					},
				],
			},

			options: {
				responsive: false,
				animation: {
					duration: 0,
				},

				layout: {
					padding: 0,
				},

				legend: {
					display: false,
				},

				scales: {
					yAxes: [
						{
							gridLines: {
								display: false,
							},
							ticks: {
								stepSize: 1,
								padding: 10,
								// autoSkip: false,
								// autoSkipPadding: 10,
							},
						},
					],
					xAxes: [
						{
							position: 'top',
							ticks: {
								min: -6,
								max: 6,
								beginAtZero: true,
								stepSize: 1,
								callback: function (value: any, index: number, values: any) {
									if (value == -1 || value == 1) {
										value = '';
									} else if (value < -1) {
										value = value + 1;
									} else if (value > 1) {
										value = value - 1;
									}
									return value;
								},
							},
							gridLines: {
								display: false,
							},
						},
					],
				},
			},
		});
	}

	ngAfterViewInit() {}
}
