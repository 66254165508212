import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';

import * as Chart from 'chart.js';
import chartDataLabels from 'chartjs-plugin-datalabels';

@Component({
	selector: 'app-occurrence-idle-group-pie-charts',
	templateUrl: './occurrence-idle-group-pie-charts.component.html',
	styleUrls: ['./occurrence-idle-group-pie-charts.component.scss'],
})

// 📌230224 발생 공회전 분류
export class OccIdleGroupPieChartComponent implements OnInit {
	public chart: any;

	constructor() {}

	ngOnInit() {
		this.createChart();
	}

	createChart() {
		const data = [24, 67, 6, 3];

		const chart = new Chart('occIdleGroupPie', {
			plugins: [chartDataLabels],
			type: 'pie',
			data: {
				datasets: [
					{
						data: data,
						borderWidth: 1,
						borderColor: 'rgb(255, 255, 255)',
						backgroundColor: [
							'rgb(38,197,254)',
							'rgb(191,191,191)',
							'rgb(247,156,156)',
							'rgb(255,192,0)',
						],
						datalabels: {
							display: true,
							anchor: 'end',
							align: data.map((item: any, key: number) => (item < 5 ? 'end' : 'start')),
							font: {
								size: 18,
								weight: 500,
							},
							color: '#000',
							formatter(value, context) {
								return value + '%';
							},
						},
					},
				],
			},

			options: {
				plugins: {
					datalabels: {
						display: false,
					},
				},
				hover: {
					mode: null,
				},
				responsive: false,
				animation: {
					duration: 0,
				},
				tooltips: {
					enabled: false,
				},
				layout: {
					padding: 30,
				},
				legend: {
					display: false,
				},
			},
		});
	}
}
