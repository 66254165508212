import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';

import * as Chart from 'chart.js';

@Component({
	selector: 'app-mileage-line-charts',
	templateUrl: './mileage-line-charts.component.html',
	styleUrls: ['./mileage-line-charts.component.scss'],
})

// 📌221223 리뉴얼 운전자 리포트 - 일별 주행거리 비교 라인 차트
export class MileageLineChartComponent implements OnInit, AfterViewInit {
	public chart: any;

	@Input() driverDailyMileageData: any;
	@Input() companyDailyMileageData: any;

	constructor() {}

	best_green_lables = [23, 24, 25, 26, 27, 28, 29, 30, 31, 1, 2, 3, 4, 5];

	ngOnInit() {
		setTimeout(() => {
			this.mileageLineChart();
		}, 1000);
	}

	mileageLineChart() {
		const labels = Array.from(
			{ length: this.driverDailyMileageData.length },
			(_: any, key: number) => {
				return key + 1;
			}
		);

		const chart = new Chart('mileageLine', {
			type: 'line',
			data: {
				labels: labels,
				datasets: [
					{
						type: 'line',
						data: this.driverDailyMileageData,
						borderColor: 'transparent',
						backgroundColor: 'rgba(86,192,230, 0.3)',
						fill: true,
						borderWidth: 2,
						lineTension: 0,
						pointRadius: 0,
						pointBorderWidth: 0,
					},
					{
						type: 'line',
						data: this.companyDailyMileageData,
						borderColor: 'transparent',
						backgroundColor: 'rgba(210,210,210, 0.3)',
						fill: true,
						borderWidth: 2,
						lineTension: 0,
						pointRadius: 0,
						pointBorderWidth: 0,
					},
				],
			},
			options: {
				layout: {
					padding: 0,
				},
				responsive: true,
				animation: {
					duration: 0,
				},

				legend: {
					display: false,
				},
				elements: {
					line: {
						fill: true,
						tension: 0,
						borderWidth: 2,
					},
					point: {
						radius: 0,
						hoverRadius: 6,
					},
				},
				scales: {
					yAxes: [
						{
							gridLines: {
								display: false,
							},
							ticks: {
								beginAtZero: true,
								padding: 0,
								// max: 100,
								stepSize: 40,
								fontSize: 20,
								fontStyle: '600',
								fontColor: '#000',
							},
						},
					],
					xAxes: [
						{
							gridLines: {
								display: false,
							},
							ticks: {
								beginAtZero: true,
								padding: 0,
								fontSize: 16,
								// fontSize: 10, // 10~11
								fontColor: '#000',
								maxRotation: 0,
								minRotation: 0,
							},
						},
					],
				},
			},
		});
	}

	ngAfterViewInit() {}
}
