import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment'
import gql from 'graphql-tag';
import { Apollo, QueryRef } from 'apollo-angular';
import { AuthService } from './auth.service';
import { map, delay } from 'rxjs/operators';
import * as moment from 'moment';

/**
* This shows how to use standard Apollo client on Node.js
*/





// Require exports file with endpoint and auth info


// Require AppSync module
import {AUTH_TYPE} from 'aws-appsync/lib/link/auth-link';
import {AWSAppSyncClient,} from 'aws-appsync';
import * as AWS from 'aws-sdk';


// import {
//     GET_REPORTLIST_QUERY
// } from '../queries/queries';


@Injectable({
    providedIn: 'root'
})
export class RealtimeAppsyncService {
  client:AWSAppSyncClient<any> 
  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private apollo: Apollo
  ) {

    const url = environment.REAL_TIME_APPSYNC_ENDPOINT;
    const region = environment.REAL_TIME_APPSYNC_REGION;
    const type = AUTH_TYPE.AWS_IAM;

    // If you want to use AWS...
    
    AWS.config.update({
      region: environment.REAL_TIME_APPSYNC_REGION,
      credentials: new AWS.Credentials({
        accessKeyId: environment.REAL_TIME_APPSYNC_AWS_ACCESS_KEY_ID,
        secretAccessKey: environment.REAL_TIME_APPSYNC_AWS_SECRET_ACCESS_KEY
      })
    });
    const credentials = AWS.config.credentials;

    // Set up Apollo client
    if(!this.client){
      this.client = new AWSAppSyncClient({
        url: url,
        region: region,
        auth: {
          type: type,
          credentials: credentials,
        }
        //disableOffline: true      //Uncomment for AWS Lambda
      });
    }
  }

  disconnectAppSync(){
    console.log("disconnectAppSync",this.client)
    
    // this.client = null;
  }
}
