import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './layout/admin/admin.component';
import { AuthComponent } from './layout/auth/auth.component';
import { AuthGuard } from './service/auth.guard';
import { LoginGuard } from './service/login.guard';
import { PreloadAllModules } from '@angular/router';
const routes: Routes = [
	{
		path: '',
		component: AuthComponent,

		children: [
			{
				path: '',
				redirectTo: 'auth/sign-in',
				pathMatch: 'full',
				canActivate: [LoginGuard],
			},
			{
				path: 'auth',
				canActivate: [LoginGuard],
				loadChildren: './pages/auth/auth.module#AuthModule',
			},
		],
	},

	{
		path: '',
		component: AdminComponent,
		children: [
			{
				path: 'trips',
				loadChildren: './pages/trips/trips.module#TripsModule',
				data: { state: 'third' },
				canActivate: [AuthGuard],
			},

			{
				path: 'overview',
				loadChildren: './pages/overview/overview.module#OverviewModule',
				data: { state: 'first' },
				canActivate: [AuthGuard],
			},

			{
				path: 'drivers',
				loadChildren: './pages/driver/driver.module#DriverModule',
				data: { state: 'second' },
				canActivate: [AuthGuard],
			},

			{
				path: 'driversold',
				loadChildren: './pages/drivers/drivers.module#DriversModule',
				data: { state: 'second' },
				canActivate: [AuthGuard],
			},
			{
				path: 'vehicle',
				loadChildren: './pages/vehicle/vehicle.module#VehicleModule',
				data: { state: 'fifth' },
				canActivate: [AuthGuard],
			},
			// {
			//   path: 'routes',
			//   loadChildren: './pages/routes/routes.module#RoutesModule',
			//   data: { state: 'fourth' }
			// },
			{
				path: 'event/:id',
				loadChildren: './pages/event/event.module#EventModule',
				data: { state: 'fifth' },
				canActivate: [AuthGuard],
			},
			// {
			//   path: 'video',
			//   loadChildren: './pages/video/video.module#VideoModule',
			//   data: { state: 'sixth' }
			// },
			{
				path: 'reports',
				loadChildren: './pages/reports/reports.module#ReportsModule',
				data: { state: 'seventh' },
				canActivate: [AuthGuard],
			},
			{
				path: 'vehicles',
				loadChildren: './pages/vehicle/vehicle.module#VehicleModule',
				data: { state: 'eight' },
				canActivate: [AuthGuard],
			},

			{
				path: 'mypage/myaccount',
				loadChildren: './pages/mypage/myaccount/myaccount.module#MyaccountModule',
				data: { state: 'first' },
				canActivate: [AuthGuard],
			},

			{
				path: 'driversDetail/:driver',
				loadChildren: './pages/driversDetail/driversDetail.module#DriversDetailModule',
				canActivate: [AuthGuard],
			},
			{
				path: 'tripsDetail/:id',
				loadChildren: './pages/tripsDetail/trips.module#TripsModule',
				data: { state: 'third' },
				canActivate: [AuthGuard],
			},
			{
				path: 'vehiclesDetail/:id',
				loadChildren: './pages/vehicleDetail/vehicleDetail.module#VehicleDetailModule',
				data: { state: 'third' },
				canActivate: [AuthGuard],
			},
			{
				path: 'fei',
				loadChildren: './pages/fei/fei.module#FeiModule',
				canActivate: [AuthGuard],
			},
			{
				path: 'idling',
				loadChildren: './pages/idling/idling.module#IdlingModule',
				canActivate: [AuthGuard],
			},
			// 📌221206 운행관리 관련 작업
			{
				path: 'manage',
				// path: 'manage/:id',
				loadChildren: './pages/drivingManage/drivingManage.module#DrivingManageModule',
				canActivate: [AuthGuard],
			},
			{
				path: 'rest',
				loadChildren: './pages/drivingRest/drivingRest.module#DrivingRestModule',
				canActivate: [AuthGuard],
			},
			{
				path: 'report/:id',
				loadChildren: './pages/renewalReports/renewal-reports.module#RenewalReportsModule',
				canActivate: [AuthGuard],
			},
			{
				path: 'roadDamage',
				loadChildren: './pages/roadDamage/road-damage.module#RoadDamageModule',
				canActivate: [AuthGuard],
			},
			{
				path: 'roadManage',
				loadChildren: './pages/roadManage/road-manage.module#RoadManageModule',
				canActivate: [AuthGuard],
			},
			{
				path: 'complaint',
				loadChildren:
					'./pages/complaintManagement/complaint-management.module#ComplaintManagementModule',
				canActivate: [AuthGuard],
			},
			{
				path: 'roadRisk',
				loadChildren: './pages/roadRisk/road-risk.module#RoadRiskModule',
				canActivate: [AuthGuard],
			},
			{
				path: 'basicRoad',
				loadChildren: './pages/basicRoad/basic-road.module#BasicRoadModule',
				canActivate: [AuthGuard],
			},
			{
				path: 'dispatch',
				loadChildren: './pages/dispatch/dispatch-file-manage.module#DispatchFileManageModule',
				canActivate: [AuthGuard],
			},
			// {
			// 	path: 'register',
			// 	loadChildren:
			// 		'./pages/driverRegistration/driver-registration.module#DriverRegistrationModule',
			// 	canActivate: [AuthGuard],
			// },
			{
				path: 'andong/map',
				loadChildren: './pages/andongMap/andong-map.module#AndongMapModule',
				canActivate: [AuthGuard],
			},
			{
				path: 'qr',
				loadChildren: './pages/qr/qr-manage.module#QRManageModule',
				canActivate: [AuthGuard],
			},
			// {
			// 	path: 'vehicleHistory',
			// 	loadChildren:
			// 		'./pages/vehicle-history-page/vehicle-history-management.module#VehicleHistoryManagementModule',
			// 	canActivate: [AuthGuard],
			// },
			{
				path: 'history/:id',
				loadChildren: './pages/historyManagement/history-management.module#HistoryManagementModule',
				canActivate: [AuthGuard],
			},
			{
				path: 'operation/:id',
				loadChildren:
					'./pages/operationManagement/operation-management.module#OperationManagementModule',
				canActivate: [AuthGuard],
			},
			{
				path: 'road/download',
				loadChildren:
					'./pages/roadDamage/road-monitoring-download/road-monitoring-download.module#RoadMonitoringDownloadModule',
				canActivate: [AuthGuard],
			},
		],
	},
	// {
	// 	path: '',
	// 	component: SubComponent,
	// 	children: [],
	// },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: true })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
