import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	Input,
	OnInit,
	ViewChild,
} from '@angular/core';
import {
	ChartComponent,
	ApexAxisChartSeries,
	ApexChart,
	ApexTitleSubtitle,
	ApexXAxis,
	ApexNonAxisChartSeries,
	ApexPlotOptions,
	ApexAnnotations,
	ApexStroke,
	ApexFill,
	ApexTooltip,
	ApexResponsive,
	ApexYAxis,
	ApexGrid,
	ApexStates,
	ApexTheme,
	ApexDataLabels,
	ApexLegend,
} from 'ng-apexcharts';
import { DataService } from 'src/app/service/data.service';

export type ChartOptions = {
	series: ApexAxisChartSeries | ApexNonAxisChartSeries;
	chart: ApexChart;
	xaxis: ApexXAxis;
	yaxis: ApexYAxis | ApexYAxis[];
	title: ApexTitleSubtitle;
	colors: string[];
	dataLabels: ApexDataLabels;
	plotOptions: ApexPlotOptions;
	annotations: ApexAnnotations;
	stroke: ApexStroke;
	labels: string[];
	fill: ApexFill;
	tooltip: ApexTooltip;
	responsive: ApexResponsive[];
	grid: ApexGrid;
	states: ApexStates;
	subtitle: ApexTitleSubtitle;
	theme: ApexTheme;
	legend: ApexLegend;
};

@Component({
	selector: 'app-overall-charts',
	templateUrl: './apx-overall-charts.component.html',
	styleUrls: ['./apx-overall-charts.component.scss'],
})

// 📌221222 리뉴얼 운전자 리포트 - 차로관리, 안전거리, 속도관리 차트
export class ApxChartComponent implements OnInit {
	@ViewChild('charts') charts: ChartComponent;
	public chartOptions: Partial<ChartOptions>;

	@Input() chart: ApexChart;
	@Input() annotations: ApexAnnotations;
	@Input() colors: string[];
	@Input() dataLabels: ApexDataLabels;
	@Input() series: ApexAxisChartSeries | ApexNonAxisChartSeries;
	@Input() stroke: ApexStroke;
	@Input() labels: string[];
	@Input() legend: ApexLegend;
	@Input() fill: ApexFill;
	@Input() tooltip: ApexTooltip;
	@Input() plotOptions: ApexPlotOptions;
	@Input() responsive: ApexResponsive[];
	@Input() xaxis: ApexXAxis;
	@Input() yaxis: ApexYAxis | ApexYAxis[];
	@Input() grid: ApexGrid;
	@Input() states: ApexStates;
	@Input() title: ApexTitleSubtitle;
	@Input() subtitle: ApexTitleSubtitle;
	@Input() theme: ApexTheme;

	@Input() chartTitle: string;
	@Input() overallBarChartData: any;
	@Input() driverOverallBarChartData: any;

	constructor() {}

	ngOnInit() {
		this.createChart();
	}

	createChart() {
		this.chartOptions = {
			series: [
				{
					data:
						this.chartTitle === '차로관리'
							? [this.driverOverallBarChartData.driverFocus, this.overallBarChartData.focusSkor]
							: this.chartTitle === '안전거리'
							? [this.driverOverallBarChartData.driverGuard, this.overallBarChartData.guardSkor]
							: this.chartTitle === '속도관리'
							? [this.driverOverallBarChartData.driverSpeed, this.overallBarChartData.speedSkor]
							: [0, 0],
				},
			],
			states: {
				hover: {
					filter: {
						type: 'none',
					},
				},
			},
			tooltip: {
				enabled: false,
			},
			chart: {
				height: 120,
				type: 'bar',
				offsetY: 0,
				offsetX: 0,
				toolbar: {
					show: false,
				},
				animations: {
					enabled: false,
				},
			},
			annotations: {},
			plotOptions: {
				bar: {
					horizontal: true,
					borderRadius: 12,
					barHeight: '70%',
					distributed: true,
					dataLabels: {
						position: 'bottom',
					},
					colors: {
						backgroundBarColors: ['#d9d9d9'],
						backgroundBarOpacity: 1,
						backgroundBarRadius: 12,
					},
				},
			},
			legend: {
				show: false,
			},
			colors: ['#019ad2', '#aeaeae'],

			dataLabels: {
				enabled: true,
				textAnchor: 'start',
				offsetX: 16,
				style: {
					fontSize: '18px',
					fontWeight: '400',
					colors: ['#fff'],
				},
			},
			grid: {
				show: false,
				xaxis: {
					lines: {
						show: false,
					},
				},
				yaxis: {
					lines: {
						show: false,
					},
				},
			},
			xaxis: {
				max: 100,
				categories: [this.chartTitle],
				labels: {
					show: false,
				},
				axisTicks: {
					show: false,
				},
			},
			yaxis: {
				show: true,

				max: 100,
				labels: {
					show: true,
					style: {
						fontSize: '20px',
						fontWeight: 'bold',
					},
				},
			},
		};
	}
}
