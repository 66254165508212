import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthService} from './auth.service';
import {environment} from '../../environments/environment';
import * as moment from 'moment';
@Injectable({
    providedIn: 'root'
})
export class FetchapiService {
    constructor(
        private http: HttpClient,
        private auth: AuthService
    ) {}
    
    public postCustomDashboard(email:string,label:string,endDate){
        let filter_name
        if(label=="Last Month") {
            let monthMinusOneName =  moment().subtract(1, "month").startOf("month").format('MMM').toUpperCase();
            let year = moment().subtract(1, "month").startOf("month").format('YYYY');
            filter_name=`${monthMinusOneName}-${year}`
        } else {
            filter_name=label.toUpperCase().replace(/ /g, '_');
        }
        
        console.log('filet name',filter_name)
        
        // const queryOri ={
        //     "query": `query getByFilter {\n getReportsByFilter(filter:{company_id:{eq:\"${email}\"},filter_name:{eq:\"${filter_name}\"}}){\n items{\n report_title\n report_month_year\n created_date\n company_id\n file\n result\n filter_type\n filter_name\n }\n }\n}`,
        //     "variables": "{}",
        //     "operationName": "getByFilter"
        // }
        const queryOri={"query":`query getReport {\n getReports(company_id:\"${email}\", filter_name:\"${filter_name}\"){\n report_title\n report_month_year\n created_date\n company_id\n file\n result\n filter_type\n filter_name\n }\n}`,"variables":"{}","operationName":"getReport"}
        return this.http.post(environment.apiCustomDateGraphqlURL,queryOri);
    }

    public get(path: string, options?: object) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': this.auth.getToken(),
            })
        };
        return this.http.get(`${environment.apiEndPointURl}/${path}`, httpOptions);
    }

    public getRealTimeData(path: string, options?: object) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': this.auth.getToken(),
            })
        };
        return this.http.get(`${environment.dynamoDBUrl}/${path}`, httpOptions);
    }

    public getJson(path: string, options?: object) {
        return this.http.get(`${path}`);
    }

    public post(path: string, body: object, options?: object) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': this.auth.getToken(),
            })
        };
        return this.http.post(`${environment.apiEndPointURl}/${path}`, body, httpOptions);
    }

    public put(path: string, body?: object, options?: object) {
        return this.http.put(`${environment.apiEndPointURl}/${path}`, body, options);
    }

    public delete(path: string, options?: object) {
        if (typeof options === 'undefined') {
            options = {};
        }
        options['headers'] = new HttpHeaders();
        options['headers'].set('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, DELETE');
        return this.http.delete(`${environment.apiEndPointURl}/${path}`, options);
    }
 }
