import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'volumeFormat'
})
export class VolumeFormatPipe implements PipeTransform {
    transform(value: string | number): string {
        let text = '';
        if (!!value) {

            text = String(value);
            value = text.replace(/,/g, '');
            let trigger='';
            let max=0;
            const numberToString = Number(value);
            text='';
            if(numberToString<1000){
                text = String(value);
            }else{
                if (numberToString >= 1000) { 
                    max = value.length - 3;
                    trigger='K';
                }else if(numberToString >= 1000000000){
                    max = value.length - 9;
                    trigger='M';
                }else if(numberToString >= 1000000000000){
                    max = value.length - 12;
                    trigger='G';
                }else if(numberToString >= 1000000000000000){
                    max = value.length - 15;
                    trigger='T';
                }
                if (max > 0 && max <=6) {
                    for (let index = 0; index < max; index++) {
                        text+=value[index];
    
                    }
                }
                text+=trigger;
            }

        }
        return text;
    }
}
//   1024	요타 (yotta)	Y	일자	1 000 000 000 000 000 000 000 000
// 1021	제타 (zetta)	Z	십해	1 000 000 000 000 000 000 000
// 1018	엑사 (exa)	E	백경	1 000 000 000 000 000 000
// 1015	페타 (peta)	P	천조	1 000 000 000 000 000
// 1012	테라 (tera)	T	일조	1 000 000 000 000
// 109	기가 (giga)	G	십억	1 000 000 000
// 106	메가 (mega)	M	백만	1 000 000
//	킬로 (kilo)	k	천	1 000