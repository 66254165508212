import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';

import * as Chart from 'chart.js';

@Component({
	selector: 'app-mileage-trip-charts',
	templateUrl: './mileage-trip-charts.component.html',
	styleUrls: ['./mileage-trip-charts.component.scss'],
})

// 📌230103 리뉴얼 운전자 리포트 - 주행시간 비교 라인 차트
export class MileageTripChartComponent implements OnInit, AfterViewInit {
	public chart: any;

	@Input() driverDailyTripTimeData: any;
	@Input() companyDailyTripTimeData: any;

	constructor() {}

	best_green_lables = [23, 24, 25, 26, 27, 28, 29, 30, 31, 1, 2, 3, 4, 5];

	ngOnInit() {
		setTimeout(() => {
			this.mileageTripChart();
		}, 1000);
	}

	mileageTripChart() {
		const labels = Array.from(
			{ length: this.driverDailyTripTimeData.length },
			(_: any, key: number) => {
				return key + 1;
			}
		);

		const chart = new Chart('mileageTrip', {
			type: 'line',
			data: {
				labels: labels,
				datasets: [
					{
						type: 'line',
						data: this.driverDailyTripTimeData,
						borderColor: 'transparent',
						backgroundColor: 'rgba(86,192,230, 0.3)',
						fill: true,
						borderWidth: 2,
						lineTension: 0,
						pointRadius: 0,
						pointBorderWidth: 0,
					},
					{
						type: 'line',
						data: this.companyDailyTripTimeData,
						borderColor: 'transparent',
						backgroundColor: 'rgba(210,210,210, 0.3)',
						fill: true,
						borderWidth: 2,
						lineTension: 0,
						pointRadius: 0,
						pointBorderWidth: 0,
					},
				],
			},
			options: {
				animation: {
					duration: 0,
				},
				layout: {
					padding: 0,
				},
				responsive: true,
				legend: {
					display: false,
				},
				elements: {
					line: {
						fill: true,
						tension: 0,
						borderWidth: 2,
					},
					point: {
						radius: 0,
						hoverRadius: 6,
					},
				},
				scales: {
					yAxes: [
						{
							gridLines: {
								display: false,
							},
							ticks: {
								beginAtZero: true,
								padding: 0,
								// max: 100,
								stepSize: 100,
								fontSize: 20,
								fontStyle: '600',
								fontColor: '#000',
							},
						},
					],
					xAxes: [
						{
							gridLines: {
								display: false,
							},
							ticks: {
								beginAtZero: true,
								padding: 0,
								fontSize: 16,
								fontColor: '#000',
								maxRotation: 0,
								minRotation: 0,
							},
						},
					],
				},
			},
		});
	}

	ngAfterViewInit() {}
}
